<template>
    <a-entity ref="arcEntity">
    </a-entity>
</template>

<script>
import * as THREE from 'three';
import { gsap } from 'gsap';

export default {
    props: {
        diameter: Number,
        degrees: Number,
        angle: Number,
        position: Array,
        rotation: Array,
        color: String,
        thickness: {
            type: Number,
            default: 0.05,
        },
        breakAngle: {
            type: Number,
            default: 0,
        },
        segments: {
            type: Number,
            default: 1,
        },
        segmentsto: {
            type: Number,
            default: 1,
        },
    },
    data() {
        return {
            animatedSegments: this.segments,
            arcs: [],
        };
    },
    watch: {
        animatedSegments() {
            this.updateArcVisibility();
        },
    },
    mounted() {
        this.createArcs();
        this.updateArcVisibility();

        gsap.to(this, {
            animatedSegments: this.segmentsto,
            duration: 2,
            ease: "power1.inOut",
            yoyo: true,
            repeat: -1,
            onUpdate: () => {
                this.updateArcVisibility();
            },
        });
    },
    methods: {
        createArcs() {
            const el = this.$refs.arcEntity;
            const scene = el.object3D;
            const radius = this.diameter / 2;
            const thetaStart = THREE.MathUtils.degToRad(this.angle);
            const segmentDegrees = this.degrees / this.segments;
            const thetaLength = THREE.MathUtils.degToRad(segmentDegrees - this.breakAngle);
            const breakTheta = THREE.MathUtils.degToRad(this.breakAngle);

            for (let i = 0; i < this.segments; i++) {
                const geometry = new THREE.BufferGeometry();
                const vertices = [];

                const segmentStart = thetaStart + i * (thetaLength + breakTheta);
                const segments = Math.max(3, Math.floor(100 * (thetaLength / (2 * Math.PI)))); // Dynamic number of segments based on arc length
                for (let j = 0; j <= segments; j++) {
                    const theta = segmentStart + j * (thetaLength / segments);
                    const x = Math.cos(theta) * radius;
                    const y = Math.sin(theta) * radius;
                    vertices.push(x, y, 0);
                }

                geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));

                const material = new THREE.LineBasicMaterial({ color: this.color });
                const arc = new THREE.Line(geometry, material);
                arc.position.set(...this.position);
                arc.rotation.set(...this.rotation.map(THREE.MathUtils.degToRad));

                scene.add(arc);
                this.arcs.push(arc);
            }
        },
        updateArcVisibility() {
            for (let i = 0; i < this.segments; i++) {
                this.arcs[i].visible = i < this.animatedSegments;
            }
        },
    },
};
</script>

<style scoped>
/* Add styles here if needed */
</style>
