<template>
  <div class="apparea">
      <div v-if="video" class="mainUI" @click="video = !video" style="cursor:pointer;z-index:1000;" id="uiDiv" oncontextmenu="return false;">
          <div class="xbutton" style="top:20px;left:20px;position:fixed;z-index:1000;">X</div>
          <div style="width:100vw;height:100vh;position:fixed;"><iframe src="https://player.vimeo.com/video/867526170?h=5ac694b416&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:fixed;top:0;left:0;width:100%;height:100%;"
                  title="NOCLIP V4 MASTER PRORES"></iframe></div>
          <div class="regionUI">
              <div class="buttonUI">
                  <div @mousedown="video = false" id="buttonEnter">
                      <h1>ENTER</h1>
                  </div>
              </div>
          </div>
      </div>
  
      <div class="demo-desc" v-if="modalActive">
          <div class="infobox">
              <p></p>
              <div class="button" @click="modalActive = !modalActive">I UNDERSTAND</div>
          </div>
      </div>
      <div class="perspective" style="cursor: crosshair!important;">
  
          <a-scene  stats fog="type: linear; color: #000; near: 30;far:80;"  vr-mode-ui="enabled: true; enterVRButton: #enterVR; enterARButton: #enterAR" loading-screen="dotsColor: #e7b648; backgroundColor: black" ref="scene" reflection="directionalLight:a-light#dirlight;"
              :cursor=" { rayOrigin: cursor ? 'xrselect' : 'mouse'}" renderer="powerPreference: high-performance; antialias: true; shadowMap.enabled: true; shadowMap.type: PCFSoftShadowMap; colorManagement: true; highRefreshRate: false; toneMapping: cineon; sortObjects: true; exposure: 1; precision: high;  physicallyCorrectLights: true;  maxCanvasWidth: 4096; maxCanvasHeight: 4096;">
  
              <a-assets>
                  <a-asset-item id="optimerBoldFont" src="Line_UltraLight.json"></a-asset-item>
              </a-assets>
              <a-sky color="#111"></a-sky>
  
  
              <a-entity v-if="intro >= 1" :position="cameraPosition">
  
                  <a-camera :cursor="{rayOrigin: 'mouse', fuse: 'true', fuseTimeout: '100'}" active="true" smooth-camera="easing: 0.5" smooth-follow-mouse="easing: 0.1; maxAngle: 20" rotation-reader rotation="45 0 0" id="camera" :fov="this.fov" reverseMouseDrag="true"
                      look-controls="false" wasd-controls-enabled="false" ref="camera">
                      <!--smooth-camera="easing: 0.5" smooth-follow-mouse="easing: 0.1; maxAngle: 20" -->
  
  
                      <a-entity v-if="intro >= 1 && isDesktop()">
  
                          <a-light light="type: ambient;" intensity="2" position="0 0 200" color="#ffffff">
                          </a-light>
  
                          <a-entity v-if="intro == 1" id="nocliptext" rotation="0 0 0" material="color: #e7b648;" position="-1.2 0 -3">
                              <a-entity v-if="intro == 1" text-geometry="value: NOCLIP; bevelThickness: 5; font: #optimerBoldFont; height: 0.01; size: 0.45;" rotation="0 0 0" material="color: #e7b648;" position="-0.02 0.6 0"> </a-entity>
                              <a-entity v-if="intro == 1" text-geometry="value: OVERVIEW EFFECT FOR EVERYONE; bevelThickness: 5; font: #optimerBoldFont; height: 0.01; size: 0.09;" rotation="0 0 0" material="color: #ffffff;" position="0 0.4 0"> </a-entity>
                              <a-entity>
                                  <a-entity line="start: 0 0.25 0; end: 2 0.25 0; color: #ffffff" position="0 0 0" rotation="0 0 0"></a-entity>
                                  <a-entity line="start: 0 -0.25 0; end: 2 -0.25 0; color: #ffffff" position="0 0 0" rotation="0 0 0"></a-entity>
                              </a-entity>
  
                              <a-entity position="0.8 0.05 -1" animation="property: rotation; to: 0 0 360 ; easing: linear; loop: true; dir: forward; dur:9000; ">
                                  <a-entity rotation="0 0 0" animation="property: rotation; to: 0 0 720 ; easing: easeInQuad; loop: true; dir: alternate; dur:6000; ">
                                      <arc-entity :thickness="0.01" :diameter="5" :degrees="45" :angle="22.5" :rotation="[0, 0, 0]" :position="[0, 0, 0]" color="#ffffff"></arc-entity>
                                      <arc-entity :thickness="0.01" :diameter="5" :degrees="45" :angle="202.5" :rotation="[0, 0, 0]" :position="[0, 0, 0]" color="#ffffff"></arc-entity>
                                  </a-entity>
  
                                  <a-entity position="0 0 -0.5" rotation="0 0 0" animation="property: rotation; to: 0 0 360 ; easing: linear; loop: true; dir: forward; dur:20000; ">
                                      <arc-entity :thickness="0.01" :diameter="4.5" :degrees="90" :angle="45" :rotation="[0, 0, 0]" :position="[0, 0, 0]" color="#e7b648"></arc-entity>
                                  </a-entity>
  
                                  <a-entity position="0 0 -0.5" rotation="0 0 0" animation="property: rotation; to: 0 0 -720 ; easing: linear; loop: true; dir: forward; dur:10000; ">
                                      <arc-entity-deg :thickness="0.05" animation="property: degrees; to: 10 ; easing: easeInQuad; loop: true; dir: alternate; dur:10000;" :diameter="6" :degrees="300" :angle="270" :rotation="[0, 0, 0]" :position="[0, 0, 0]" color="white"></arc-entity-deg>
                                  </a-entity>
  
                                  <a-entity position="0 0 -0.5" rotation="0 0 0">
                                      <arc-entity :segments="30" :breakAngle="5" :thickness="0.01" animation="property: segments; to: 10 ; easing: easeInQuad; loop: true; dir: alternate; dur:10000;" :diameter="5.25" :degrees="300" :angle="270" :rotation="[0, 0, 0]" :position="[0, 0, 0]" color="#e7b648"></arc-entity>
                                  </a-entity>
  
                                  <a-entity position="0 0 -0.5" rotation="0 0 0" animation="property: rotation; to: 0 0 360 ; easing: linear; loop: true; dir: forward; dur:5000; ">
                                      <a-sphere radius="0.01" position="0 1.5 0">
                                      </a-sphere>
                                  </a-entity>
                                  <a-entity position="0 0 -0.5" rotation="0 0 0" animation="property: rotation; to: 0 0 -360 ; easing: linear; loop: true; dir: forward; dur:8000; ">
                                      <a-sphere radius="0.01" position="0 2 0">
                                      </a-sphere>
                                  </a-entity>
                              </a-entity>
  
  
                              <a-entity position="1.2 0.2 0" animation="property: rotation; to: 0 5 0 ; easing: linear; loop: true; dir: forward; dur:2000; ">
                                  <a-light light="type: directional; angle: 120; castShadow:true;" intensity="3" target="#nocliptext" position="1 0 2" color="#ffffff">
                                  </a-light>
                              </a-entity>
  
  
                              <a-entity backgroundColor="transparent" borderColor="transparent" image="" title="" href="https://noclip.org" @click="goToURL('https://noclip.org')" @mouseenter="playChirp(4000, 0.0, 0.1)" @mouseup="playChirp(2000, 0.1, 0.2)" position="0.6 0.01 0.5">
  
                                  <a-rounded color="#ffffff" radius="0.05" position=" -0.4 -0.12" width="0.8" height="0.2" opacity="0.5">
                                  </a-rounded>
  
                                  <a-text font="exo2semibold" negate="false" color="black" align="center" width="3" position=" 0 0 0 " value="GET NOCLIP">
                                  </a-text>
                              </a-entity>
  
  
                              <a-entity backgroundColor="transparent" borderColor="transparent" image="" title="" href="" @click="goToURL('https://demo.noclip.org')" @mouseenter="playChirp(4000, 0.0, 0.1)" position="1.47 0.01 0.5">
  
                                  <a-rounded color="#e7b648" radius="0.05" position=" -0.4 -0.12 " width="0.8" height="0.2" opacity="0.5">
                                  </a-rounded>
  
                                  <a-text font="exo2semibold" negate="false" color="black" align="center" width="3" position="0 0 0 " value="OLD DEMO">
                                  </a-text>
                              </a-entity>
  
                              <a-entity @mouseenter="playChirp(4000, 0.1, 0.1)" @click="enableLookControls()" @mousedown="this.intro = 0, this.efficient = false,  this.timescale = 1 " @mouseup="playChirp(2000, 0.1, 0.2)" position="0.6 -0.4 0.5">
  
                                  <a-rounded color="#ffffff" radius="0.05" position=" -0.4 -0.12" width="0.8" height="0.2" opacity="0.5">
                                  </a-rounded>
  
                                  <a-text font="exo2semibold" negate="false" color="black" align="center" width="3" position=" 0 0 0 " value="CONNECT">
                                  </a-text>
                              </a-entity>
                              <a-entity @mouseenter="playChirp(4000, 0.1, 0.1)" @mousedown="this.intro = 2, this.efficient = true,  this.timescale = 1, this.startOverviewEffect({x: 20, y: -5, z: 5}, {x: -30, y: 20, z: -90}, 2000); " @mouseup="playChirp(2000, 0.1, 0.2)" position="1.47 -0.4 0.5">
  
                                  <a-rounded color="#e7b648" radius="0.05" position=" -0.4 -0.12 " width="0.8" height="0.2" opacity="0.5">
                                  </a-rounded>
  
                                  <a-text font="exo2semibold" negate="false" color="black" align="center" width="3" position="0 0 0 " value="MANUAL">
                                  </a-text>
                              </a-entity>
  
  
                          </a-entity>
  
  
                          <a-entity v-if="intro == 2" id="nocliptext" animation="property: rotation; to: 0 -10 0; easing: easeInQuad; loop: true; dir: alternate; dur: 10000;" rotation="0 -15 0" material="color: #ffffff;" text-geometry="value: EVERYONE; bevelThickness: 5; font: #optimerBoldFont; height: 0.01;"
                              position="0.25 2 -5">
  
                              <a-entity id="nocliptext" rotation="0 0 0" material="color: #ffffff;" text-geometry="value: EVERYWHERE; bevelThickness: 5; size: 0.39; font: #optimerBoldFont;  height: 0.01;" position="0 -1 0">
                              </a-entity>
                              <a-entity id="nocliptext" rotation="0 0 0" material="color: #ffffff;" text-geometry="value: GETS ACCESS; bevelThickness: 5; size: 0.39; font: #optimerBoldFont;  height: 0.01;" position="0 -0.5 0">
                              </a-entity>
  
                              <a-entity position="1.2 0.2 0" animation="property: rotation; to: 0 5 0 ; easing: linear; loop: true; dir: alternate; dur:2000; ">
                                  <a-light light="type: directional; angle: 120; castShadow:true;" intensity="3" target="#nocliptext" position="1 0 2" color="#ffffff">
                                  </a-light>
                              </a-entity>
  
                              <a-entity position="1.2 0.2 0" rotation="0 0 0" animation="property: rotation; to: 0 0 360 ; easing: linear; loop: true; dir: forward; dur:5000; ">
                                  <a-sphere radius="0.01" position="0 1.5 0">
                                  </a-sphere>
                              </a-entity>
  
                              <a-entity @mouseenter="playChirp(4000, 0.1, 0.1)" @mousedown="this.intro = 3, this.timescale = 1, this.efficient = false, this.startOverviewEffect({x: 0, y: 1.6, z: 10}, {x: 0, y: 0, z: 360}, 2000);" @mouseup="playChirp(2000, 0.1, 0.2)" position="1.05 -1.5 0.1">
  
                                  <a-rounded radius="0.1" position=" -1 -0.22 " width="2" height="0.4" opacity="1" color="eaff96">
                                  </a-rounded>
                                  <a-text font="exo2semibold" negate="false" color="black" align="center" width="4" value="THE OVERLAY GRID">
                                  </a-text>
                              </a-entity>
                          </a-entity>
  
                          <a-entity v-if="intro == 3" id="nocliptext" rotation="0 0 0" material="color: #ffffff;" position="0 0 -2">
                              <a-entity geometry="primitive: ring; radiusInner: 2; radiusOuter: 2.01;segmentsTheta: 64;" material="color: #e7b648; shader: flat" position=" 0 0 -1">
  
                                  <a-entity rotation="0 0 0" animation="property: rotation; to: 0 0 360 ; easing: linear; loop: true; dir: forward; dur:10000; ">
                                      <arc-entity :segments="3" :segmentsto="3" :breakAngle="60" :thickness="0.1" :diameter="3.5" :degrees="360" :angle="0" :rotation="[0, 0, 0]" :position="[0, 0, 0]" color="#e7b648"></arc-entity>
                                  </a-entity>
                              </a-entity>
                              <a-entity @mouseenter="playChirp(4000, 0.1, 0.1)" @mousedown=" this.timescale = 1, enableLookControls(),  this.efficient = false, this.startOverviewEffect({x: 0, y: 0, z: 0}, {x: 0, y: 0, z: 0}, 2000)" @mouseup="playChirp(2000, 0.1, 0.2), this.intro = 0"
                                  position="0 0 0.1">
  
                                  <a-circle segments="64" color="#ffffff" opacity="0.5" radius="1"></a-circle>
  
                                  <a-text font="exo2semibold" negate="false" color="black" align="center" width="4" value="ENTER STREAM">
                                  </a-text>
                              </a-entity>
                          </a-entity>
  
  
                      </a-entity>
  
                      <a-entity v-if="intro >= 1 && isMobile()">
  
  
  
                          <a-light light="type: ambient;" intensity="5" position="0 0 200" color="#ffffff">
                          </a-light>
  
                          <a-entity v-if="intro == 1" id="nocliptext" rotation="0 0 0" material="color: #e7b648;" position="-1.05 0 -3.5">
                              <a-entity v-if="intro == 1" text-geometry="value: NOCLIP; bevelThickness: 5; font: #optimerBoldFont; height: 0.01; size: 0.45;" rotation="0 0 0" material="color: #e7b648;" position="-0.02 0.6 0"> </a-entity>
                              <a-entity v-if="intro == 1" text-geometry="value: OVERVIEW EFFECT FOR EVERYONE; bevelThickness: 5; font: #optimerBoldFont; height: 0.01; size: 0.09;" rotation="0 0 0" material="color: #ffffff;" position="0 0.4 0"> </a-entity>
                              <a-entity>
                                  <a-entity line="start: 0 0.25 0; end: 2 0.25 0; color: #ffffff" position="0 0 0" rotation="0 0 0"></a-entity>
                                  <a-entity line="start: 0 -0.25 0; end: 2 -0.25 0; color: #ffffff" position="0 0 0" rotation="0 0 0"></a-entity>
                              </a-entity>
  
                              <a-entity position="1 0.05 -1" animation="property: rotation; to: 0 0 360 ; easing: linear; loop: true; dir: forward; dur:9000; ">
                                  <a-entity rotation="0 0 0" animation="property: rotation; to: 0 0 720 ; easing: easeInQuad; loop: true; dir: alternate; dur:6000; ">
                                      <arc-entity :thickness="0.01" :diameter="5" :degrees="45" :angle="22.5" :rotation="[0, 0, 0]" :position="[0, 0, 0]" color="#ffffff"></arc-entity>
                                      <arc-entity :thickness="0.01" :diameter="5" :degrees="45" :angle="202.5" :rotation="[0, 0, 0]" :position="[0, 0, 0]" color="#ffffff"></arc-entity>
                                  </a-entity>
  
                                  <a-entity position="0 0 -0.5" rotation="0 0 0" animation="property: rotation; to: 0 0 360 ; easing: linear; loop: true; dir: forward; dur:20000; ">
                                      <arc-entity :thickness="0.01" :diameter="4.5" :degrees="90" :angle="45" :rotation="[0, 0, 0]" :position="[0, 0, 0]" color="#e7b648"></arc-entity>
                                  </a-entity>
  
                                  <a-entity position="0 0 -0.5" rotation="0 0 0" animation="property: rotation; to: 0 0 -720 ; easing: linear; loop: true; dir: forward; dur:10000; ">
                                      <arc-entity-deg :thickness="0.05" animation="property: degrees; to: 10 ; easing: easeInQuad; loop: true; dir: alternate; dur:10000;" :diameter="6" :degrees="300" :angle="270" :rotation="[0, 0, 0]" :position="[0, 0, 0]" color="white"></arc-entity-deg>
                                  </a-entity>
  
                                  <a-entity position="0 0 -0.5" rotation="0 0 0">
                                      <arc-entity :segments="30" :breakAngle="5" :thickness="0.01" animation="property: segments; to: 10 ; easing: easeInQuad; loop: true; dir: alternate; dur:10000;" :diameter="5.25" :degrees="300" :angle="270" :rotation="[0, 0, 0]" :position="[0, 0, 0]" color="#e7b648"></arc-entity>
                                  </a-entity>
  
                                  <a-entity position="0 0 -0.5" rotation="0 0 0" animation="property: rotation; to: 0 0 360 ; easing: linear; loop: true; dir: forward; dur:5000; ">
                                      <a-sphere radius="0.01" position="0 1.5 0">
                                      </a-sphere>
                                  </a-entity>
                                  <a-entity position="0 0 -0.5" rotation="0 0 0" animation="property: rotation; to: 0 0 -360 ; easing: linear; loop: true; dir: forward; dur:8000; ">
                                      <a-sphere radius="0.01" position="0 2 0">
                                      </a-sphere>
                                  </a-entity>
                              </a-entity>
  
  
                              <a-entity position="1.2 0.2 0" animation="property: rotation; to: 0 5 0 ; easing: linear; loop: true; dir: forward; dur:2000; ">
                                  <a-light light="type: directional; angle: 120; castShadow:true;" intensity="3" target="#nocliptext" position="1 0 2" color="#ffffff">
                                  </a-light>
                              </a-entity>
  
                              <a-entity position="-0.04 0 0.12">
  
  
                                  <a-entity backgroundColor="transparent" borderColor="transparent" image="" title="" href="https://noclip.org" @click="goToURL('https://noclip.org')" @mouseenter="playChirp(4000, 0.0, 0.1)" @mouseup="playChirp(2000, 0.1, 0.2)" position="0.6 0.01 0.5">
  
                                      <a-rounded color="#ffffff" radius="0.05" position=" -0.4 -0.12" width="0.8" height="0.2" opacity="0.5">
                                      </a-rounded>
  
                                      <a-text font="exo2semibold" negate="false" color="black" align="center" width="3" position=" 0 0 0 " value="GET NOCLIP">
                                      </a-text>
                                  </a-entity>
  
  
                                  <a-entity backgroundColor="transparent" borderColor="transparent" image="" title="" href="" @click="goToURL('https://demo.noclip.org')" @mouseenter="playChirp(4000, 0.0, 0.1)" position="1.47 0.01 0.5">
  
                                      <a-rounded color="#e7b648" radius="0.05" position=" -0.4 -0.12 " width="0.8" height="0.2" opacity="0.5">
                                      </a-rounded>
  
                                      <a-text font="exo2semibold" negate="false" color="black" align="center" width="3" position="0 0 0 " value="OLD DEMO">
                                      </a-text>
                                  </a-entity>
  
                                  <a-entity @mouseenter="playChirp(4000, 0.1, 0.1)" @click="enableLookControls()" @mousedown="this.intro = 0, this.efficient = false,  this.timescale = 1 " @mouseup="playChirp(2000, 0.1, 0.2)" position="0.6 -0.4 0.5">
  
                                      <a-rounded color="#ffffff" radius="0.05" position=" -0.4 -0.12" width="0.8" height="0.2" opacity="0.5">
                                      </a-rounded>
  
                                      <a-text font="exo2semibold" negate="false" color="black" align="center" width="3" position=" 0 0 0 " value="CONNECT">
                                      </a-text>
                                  </a-entity>
                                  <a-entity @mouseenter="playChirp(4000, 0.1, 0.1)" @mousedown="this.intro = 2, this.efficient = true,  this.timescale = 1, this.startOverviewEffect({x: 20, y: -5, z: 5}, {x: -30, y: 20, z: -90}, 2000); " @mouseup="playChirp(2000, 0.1, 0.2)" position="1.47 -0.4 0.5">
  
                                      <a-rounded color="#e7b648" radius="0.05" position=" -0.4 -0.12 " width="0.8" height="0.2" opacity="0.5">
                                      </a-rounded>
  
                                      <a-text font="exo2semibold" negate="false" color="black" align="center" width="3" position="0 0 0 " value="MANUAL">
                                      </a-text>
                                  </a-entity>
                              </a-entity>
  
  
  
                          </a-entity>
                          <a-entity v-if="intro == 2" id="nocliptext" animation="property: rotation; to: 10 0 0; easing: easeInQuad; loop: true; dir: alternate; dur: 10000;" rotation="-10 0 0" material="color: #ffffff;" text-geometry="value: EVERYONE; bevelThickness: 5; font: #optimerBoldFont; height: 0.01;"
                              position="-1.75 2.5 -5">
                              <a-entity id="nocliptext" rotation="0 0 0" material="color: #ffffff;" text-geometry="value: EVERYWHERE; bevelThickness: 5; size: 0.39; font: #optimerBoldFont;  height: 0.01;" position="0 -0.6 0">
                              </a-entity>
  
                              <a-entity position="1.2 0.2 0" animation="property: rotation; to: 0 5 0 ; easing: linear; loop: true; dir: alternate; dur:2000; ">
                                  <a-light light="type: directional; angle: 120; castShadow:true;" intensity="3" target="#nocliptext" position="1 0 2" color="#ffffff">
                                  </a-light>
                              </a-entity>
  
                              <a-entity position="1.2 0.2 0" rotation="0 0 0" animation="property: rotation; to: 0 0 360 ; easing: linear; loop: true; dir: forward; dur:5000; ">
                                  <a-sphere radius="0.01" position="0 1.5 0">
                                  </a-sphere>
                              </a-entity>
  
                              <a-entity @mouseenter="playChirp(4000, 0.1, 0.1)" @mousedown=" this.timescale = 1, this.efficient = false, this.startOverviewEffect({x: 0, y: 0, z: 20}, {x: 0, y: 0, z: 0}, 2000);" @mouseup="playChirp(2000, 0.1, 0.2), this.intro = 3" position="1.1 -1.2 0.2">
  
                                  <a-rounded radius="0.1" position=" -1 -0.25 " width="2" height="0.5" opacity="1">
                                  </a-rounded>
  
                                  <a-text font="exo2semibold" color="black" align="center" width="4" value="THE OVERLAY GRID">
                                  </a-text>
                              </a-entity>
                          </a-entity>
                          <a-entity v-if="intro == 3" id="nocliptext" rotation="0 0 0" material="color: #ffffff;" position="0 0 -2">
                              <a-entity geometry="primitive: ring; radiusInner: 2; radiusOuter: 2.01;segmentsTheta: 64;" material="color: #e7b648; shader: flat" position=" 0 0 -2">
  
                                  <a-entity rotation="0 0 0" animation="property: rotation; to: 0 0 360 ; easing: linear; loop: true; dir: forward; dur:10000; ">
                                      <arc-entity :segments="3" :segmentsto="3" :breakAngle="60" :thickness="0.1" :diameter="3.5" :degrees="360" :angle="0" :rotation="[0, 0, 0]" :position="[0, 0, 0]" color="#e7b648"></arc-entity>
                                  </a-entity>
                              </a-entity>
                              <a-entity @mouseenter="playChirp(4000, 0.1, 0.1)" @mousedown=" this.timescale = 1, enableLookControls(),  this.efficient = false, this.startOverviewEffect({x: 0, y: 0, z: 0}, {x: 0, y: 0, z: 0}, 2000)" @mouseup="playChirp(2000, 0.1, 0.2), this.intro = 0"
                                  position="0 0 0">
  
                                  <a-rounded radius="0.1" position=" -0.75 -0.25 " width="1.5" height="0.5" color="eaff96" opacity="1">
                                  </a-rounded>
  
                                  <a-text font="exo2semibold" color="black" align="center" width="4" position="0 0.025 0" value="ENTER STREAM">
                                  </a-text>
                              </a-entity>
                          </a-entity>
  
                      </a-entity>
                  </a-camera>
              </a-entity>
  
  
              <a-entity v-if="intro == 0" :position="cameraPosition">
  
                  <a-camera :cursor="{rayOrigin: 'mouse', fuse: 'true', fuseTimeout: '100'}" smooth-camera="easing: 0.5" smooth-follow-mouse="easing: 0.1; maxAngle: 20" active="true" rotation-reader rotation="0 0 0" id="camera" :fov="this.fov" reverseMouseDrag="true" look-controls
                      wasd-controls-enabled="true" ref="camera">
  
                      <a-entity :position="`0 0 ${this.zPosition}`">
  
                       
  
                          <a-entity v-if="cursor" ref="cursor" id="cursor" raycaster-thresholds="line: 0.1; points: 0.1" :cursor="{
                                                                                                                                                                                                                              rayOrigin: 'mouse', 
                                                                                                                                                                                                                              fuse: 'true', 
                                                                                                                                                                                                                              fuseTimeout: '100'
                                                                                                                                                                                                                              }" geometry="primitive: ring; radiusInner: 0.02; radiusOuter: 0.028"
                              material="color: #e7b648; shader: flat" :rotation="this.cursorRotation" position=" 0 0 -3">
  
                              <a-entity geometry="primitive: ring; radiusInner: 0.2; radiusOuter: 0.21" material="color: #e7b648; shader: flat" :rotation="this.cursorRotation" position=" 0 0 -1">
                                  <a-entity line="start: 0.03 0 0; end: 0.08 0 0.1; color: #ffffff" position="0 0 2" rotation="0 0 -30"></a-entity>
                                  <a-entity line="start: -0.03 0 0; end: -0.08 0 0.1; color: #ffffff" position="0 0 2" rotation="0 0 30"></a-entity>
                                  <a-entity line="start: 0 0.03 0 ; end:  0 0.08 0.1; color: #ffffff" position="0 0 2"></a-entity>
                                  <a-entity :rotation="topDialRotationInv">
                                      <!-- <arc-entity :segments="3" :segmentsto="3" :breakAngle="60" :thickness="0.005" :diameter="0.5" :degrees="360" :angle="0" :rotation="[0, 0, 0]" :position="[0, 0, 0]" color="#e7b648"></arc-entity>-->
                                  </a-entity>
                              </a-entity>
                          </a-entity>
  
                          <a-entity width="0.5" position="0 0.125 0">
                              <a-entity rotation="0 0 0" position="0 4.2 -2">
                                  <!--<a-circle segments="32" color="#000000" opacity="0.2" radius="3.9"></a-circle>-->
  
                                  <arc-entity :segments="1" :segmentsto="1" :breakAngle="0" :thickness="0.002" :diameter="6" :degrees="360" :angle="0" :rotation="[0, 0, 0]" :position="[0, 0, 0]" opacity="0.5" color="#ffffff"></arc-entity>
  
                                  <a-entity id="dial" :rotation="topDialRotation">
                                      <a-circle id="dialknob" segments="10" color="#e7b648" opacity="1" radius="0.02" :rotation="topDialRotationInv" position="0 -3.007 0.01" @mousedown="topDialOnMouseDown" @mousemove="topDialOnMouseMove" @mouseup="topDialOnMouseUp">
                                          <!--  <a-entity line="start: 0 0 0; end: 0 -0.1 0; color: #ffffff" position="0 0 0">
                                                                                      <a-circle id="dialknob" segments="3" color="#ffffff" opacity="1" radius="0.001" position="0 -0.1 0" :rotation="topDialRotation">
                                                                                          <a-entity line="start: 0 0 0; end: 0 -0.1 0; color: #ffffff" position="0 0 0">
                                                                                              <a-circle id="dialknob" segments="3" color="#ffffff" opacity="1" radius="0.001" position="0 -0.1 0" :rotation="topDialRotationInv">
                                                                                                  <a-text font="exo2semibold" height="0.04" position="0 -0.05 0" align="center" width="1" color="#e7b648" :value="topDialRotationInv + ' POS ' + topDialRotation"> </a-text>
                                                                                              </a-circle>
                                          
                                          
                                                                                          </a-entity>
                                                                                      </a-circle>
                                          
                                          
                                                                                  </a-entity>
                                                                          -->
                                      </a-circle>
                                  </a-entity>
                              </a-entity>
  
                              <a-text font="exo2semibold" position="0 0.65 -1" align="center" width="0.5" color="#e7b648" :value="'TIME MULTIPLIER: ' + timescale"> </a-text>
  
  
                              <a-text font="exo2semibold" position="0 0.70 -1" align="center" width="0.5" color="#e7b648" :value="'LAT ' + parseFloat(this.latReceived).toFixed(2) + '  LON ' + parseFloat(this.lonReceived).toFixed(2)">
  
  
                              </a-text>
                              <a-text font="exo2semibold" height="0.04" position="-0.252 0.7 -1" align="center" width="0.5" color="#e7b648" :value="'NODE ' + selectedIndex + ' SELECTED'">
  
                              </a-text>
  
                              <a-entity @mouseenter="playChirp(9000, 0.01, 0.01)" @mousedown="playChirp(1000, 0.1, 0.02)" @click="visitLink(linkReceived)" position="-0.252 0.75 -1">
                                  <a-rounded radius="0.015" color="#222222" position="-0.125 -0.025 0" width="0.25" height="0.05" opacity="1">
  
                                  </a-rounded>
  
  
                                  <a-text font="exo2semibold" color="white" align="center" width="0.5" :value="linkReceived"></a-text>
  
                              </a-entity>
  
                              <a-entity @mouseenter="playChirp(9000, 0.01, 0.01)" @mousedown="playChirp(1000, 0.1, 0.02)" position="0.252 0.75 -1">
                                  <a-rounded color="#222222" radius="0.015" position="-0.125 -0.025 0" width="0.25" height="0.05" opacity="1">
  
                                  </a-rounded>
  
  
                                  <a-text font="exo2semibold" color="white" align="center" width="0.5" :value="this.titleReceived"></a-text>
                              </a-entity>
  
  
                              <a-text height="0.04" position="0.252 0.70 -1" align="center" width="0.5" color="#e7b648" :value="descReceived">
  
                              </a-text>
  
                              <a-entity v-if="selectedIndex == null" @mouseenter="playChirp(9000, 0.01, 0.01)" @mousedown="playChirp(1000, 0.1, 0.02)" @click="addMarker" position="0 0.75 -1">
                                  <a-rounded color="#222222" radius="0.015" position="-0.125 -0.025 0" width="0.25" height="0.05" opacity="1">
  
                                  </a-rounded>
  
  
                                  <a-text font="exo2semibold" color="white" align="center" width="0.5" value="Add Node"></a-text>
  
  
                              </a-entity>
  
                              <a-entity v-if="selectedIndex !== null" @mouseenter="playChirp(9000, 0.01, 0.01)" @mousedown="playChirp(1000, 0.1, 0.02)" @click="handleMarkerRemoval(selectedIndex)" position="0 0.75 -1">
                                  <a-rounded color="#222222" radius="0.015" position="-0.125 -0.025 0" width="0.25" height="0.05" opacity="1">
  
                                  </a-rounded>
  
  
                                  <a-text font="exo2semibold" color="white" align="center" width="0.5" value="Remove Node"></a-text>
  
                              </a-entity>
  
                          </a-entity>
  
  
  
                          <a-entity width="0.5" position="0 -0.125 0">
                              <!--   <a-entity rotation="0 0  0" position="0 -4.2 -2">
                                                                            
                                                                                                                <a-entity animation="property: rotation; to: 0 0 160 ; easing: easeInQuad; loop: true; dir: alternate; dur:1000;" rotation="0 0 200" position="0 0 0">
                                                                              
                                                                                                                  <a-entity rotation="0 0 0" position="0 -3.1 0">
                                                                                                                      <a-entity line="start: 0 0 0 ; end:  0 0.1 0; color: #e7b648" position="0 0 0"></a-entity>
                                                                              
                                                                                                                  </a-entity>
                                                                              
                                                                                                              </a-entity>
                                                                              
                                                                                                             <a-circle segments="32" color="#000000" opacity="0.2" radius="3.9"></a-circle>
                                                                              
                                                                                                              <arc-entity :segments="160" :segmentsto="160" :breakAngle="1.5" :thickness="0.002" :diameter="6" :degrees="360" :angle="45" :rotation="[0, 0, 0]" :position="[0, 0, 0]" color="#ffffff"></arc-entity>
                                                                                                          </a-entity>
                                                                                                      -->
                              <a-entity>
                                  <a-text font="exo2semibold" position="0 -0.7 -1" align="center" width="0.5" color="#e7b648" :value="currentTime"></a-text>
                              </a-entity>
  
                              <a-entity v-if="efficient" @mouseenter="playChirp(9000, 0.01, 0.01)" @mousedown="this.efficient = false, playChirp(1000, 0.1, 0.02)" position="-0.252 -0.75 -1">
  
  
                                  <a-rounded color="#222222" radius="0.015" position="-0.125 -0.025 0" width="0.25" height="0.05" opacity="1">
  
                                  </a-rounded>
  
  
                                  <a-text font="exo2semibold" color="white" align="center" width="0.5" value="Simulated"></a-text>
                              </a-entity>
  
                              <a-entity v-if="!efficient" @mouseenter="playChirp(9000, 0.01, 0.01)" @mousedown="this.efficient = true, playChirp(1000, 0.1, 0.02)" position="-0.252 -0.75 -1">
                                  <a-rounded color="#222222" radius="0.015" position="-0.125 -0.025 0" width="0.25" height="0.05" opacity="1">
  
                                  </a-rounded>
  
  
                                  <a-text font="exo2semibold" color="white" align="center" width="0.5" value="Land Grid"></a-text>
                              </a-entity>
  
                              <a-entity @mousedown="cursor = false, playChirp(1000, 0.1, 0.02)" @mouseenter="playChirp(9000, 0.01, 0.01)" position="0 -0.75 -1">
                                  <a-rounded color="#222222" radius="0.015" position="-0.125 -0.025 0" width="0.25" height="0.05" opacity="1">
  
                                  </a-rounded>
  
  
                                  <a-text font="exo2semibold" :color="cursor ? 'white' : '#e7b648'" align="center" width="0.5" :value="cursor ? 'Open Keyboard' : 'Down Arrow Closes'"></a-text>
                              </a-entity>
  
                              <a-entity @mousedown="progs(), playChirp(1000, 0.1, 0.02)" @mouseenter="playChirp(9000, 0.01, 0.01)" position="0.252 -0.75 -1">
                                  <a-rounded color="#222222" radius="0.015" position="-0.125 -0.025 0" width="0.25" height="0.05" opacity="1">
  
                                  </a-rounded>
  
  
                                  <a-text font="exo2semibold" v-if="prog == 1" color="white" align="center" width="0.5" :value="'Title: ' + this.keyboardOutputTitle"></a-text>
                                  <a-text font="exo2semibold" v-if="prog == 2" color="#e7b648" align="center" width="0.5" :value="'Desc: ' + this.keyboardOutputDesc"></a-text>
                                  <a-text font="exo2semibold" v-if="prog == 3" color="#ff6600" align="center" width="0.5" :value="'Link: ' + this.keyboardOutputLink"></a-text>
                              </a-entity>
  
  
  
  
                              <a-entity @mouseenter="playChirp(9000, 0.01, 0.01)" @mousedown="this.efficient = true, playChirp(1000, 0.1, 0.02)" position="-0.252 -0.802 -1">
                                  <a-rounded color="#222222" radius="0.015" position="-0.125 -0.025 0" width="0.25" height="0.05" opacity="1">
  
                                  </a-rounded>
  
  
                                  <a-text font="exo2semibold" color="white" align="center" width="0.5" value="Overlay Select"></a-text>
                              </a-entity>
  
  
  
                              <a-entity @mouseenter="playChirp(9000, 0.01, 0.01)" @mousedown="this.efficient = true, playChirp(1000, 0.1, 0.02)" position="0 -0.802 -1">
                                  <a-rounded color="#222222" radius="0.015" position="-0.125 -0.025 0" width="0.25" height="0.05" opacity="1">
  
                                  </a-rounded>
  
  
                                  <a-text font="exo2semibold" color="white" align="center" width="0.5" value="Connect WEB3 Wallet"></a-text>
                              </a-entity>
  
  
  
                              <a-entity @mouseenter="playChirp(9000, 0.01, 0.01)" @mousedown="this.intro = 1, playChirp(1000, 0.1, 0.02)" position="0.252 -0.802 -1">
                                  <a-rounded color="#222222" radius="0.015" position="-0.125 -0.025 0" width="0.25" height="0.05" opacity="1">
  
                                  </a-rounded>
  
  
                                  <a-text font="exo2semibold" color="white" align="center" width="0.5" value="Read Manual"></a-text>
                              </a-entity>
  
                          </a-entity>
  
  
  
                          <a-entity @mousedown="playChirp(2000, 0.1, 0.02)" id="leftstack" width="1" line-stack :position="calculateLeftComplication()">
                              <a-entity class="ignored" connecting-line__2="start:#leftstack;startOffset: 0 0 0 ;end:#leftcomp;endOffset: 0 0 0 ; color:#e7b648">
                              </a-entity>
                              <a-entity id="leftcomp" line="start: 0 0 0; end: 0.15 0 0; color: #e7b648" :position="this.boxPositionLeft">
                                  <a-text font="exo2semibold" position="0.2 0  0" width="1" color="#e7b648" :value="parseFloat(this.rotationAngleX).toFixed(2)"></a-text>
                              </a-entity>
                              <a-entity position="-0.05 0 0.2" rotation="0 30 0" particle-grid="gridWidth: 5; gridHeight:20; distance: 0.05; scale: 0.001; color: #ffffff"></a-entity>
  
                          </a-entity>
  
  
                          <a-entity @mousedown="playChirp(2000, 0.1, 0.02)" id="rightstack" width="1" line-stack :position="calculateRightComplication()">
                              <a-entity class="ignored" connecting-line__1="start:#rightstack;startOffset: 0 0 0 ;end:#rightcomp;endOffset:  0 0 0; color:#e7b648">
                              </a-entity>
                              <a-entity id="rightcomp" line="start: 0 0 0; end: -0.15 0 0; color: #e7b648" :position="this.boxPositionRight">
                                  <a-text font="exo2semibold" align="right" position="-0.2 0  0" width="1" color="#e7b648" :value="parseFloat(this.rotationAngleY).toFixed(2)"></a-text>
                              </a-entity>
                              <a-entity position="0.05 0 0.2" rotation="0 -30 0" particle-grid="gridWidth: 5; gridHeight:20; distance: 0.05; scale: 0.001; color: #ffffff">
  
  
  
                              </a-entity>
  
                          </a-entity>
  
  
                          <a-entity id="hand" laser-controls="hand: right"></a-entity>
  
                          <!-- Change hand to `hand` for VR. -->
                          <a-entity v-if="!cursor" id="keyboard" :super-keyboard="{
                                    hand: '#cursor',
                                    imagePath: 'dist/',
                                    show: !cursor
                                    }"
                              :position="isDesktop() ? '0 -0.1 -0.5' : '0 -0.1 -1'" rotation="0 0 0">
  
  
                          </a-entity>
                      </a-entity>
                  </a-camera>
              </a-entity>
  
  
  
  
              <!-- <a-entity position="2 0 64">
                  <a-sphere position="-1.5 0 0" color="yellow" radius="1"></a-sphere>
                  <a-text position="0 0 0" :value="markerPositions[2].title" font="https://cdn.aframe.io/fonts/Exo2Bold.fnt"></a-text>
                  </a-entity>-->

    
            <a-sphere animation="property: rotation; to: 0 360 0; easing: linear; loop: true; dur: 90000000" segments-width="100" segments-height="100" style="pointer-events:none;" blending="additive" :radius="1.001 * this.earthRadius"
                opacity="1" detail="100" rotation="0 180 0" material="tone-mapped: false; reflectivity: 0.9; shininess: 30;" src="8k_earth_daymap.jpg">
    
            </a-sphere>
    
            <a-sphere  material="shader: atmosphere2; color: #ddeeff; coeficient: 0.9; power: 1; glowSize: 0.9; glowIntensity: 2; lightDirection: 0 0 -1 "  @click="handleRaycasterEnter" data-no-raycast animation="property: rotation; to: 0 360 0; easing: linear; loop: true; dur: 90000000" segments-width="100" segments-height="100" style="pointer-events:none;" blending="additive" :radius="1.002 * this.earthRadius"
                opacity="0.5"  detail="100" rotation="0 180 0" src="8k_earth_clouds.jpg">
            </a-sphere>
         


      <div class="marker-tooltip" v-if="markerTooltip.visible">
        <h2>{{ markerTooltip.title }}</h2>
        <p>{{ markerTooltip.description }}</p>
        <a :href="markerTooltip.link">{{ markerTooltip.link }}</a>
      </div>


    </a-scene>




  <!-- HTML SHADER INJECTION -->




    <div class="segmentbutton" :style="[streamVisible ? {'filter' : 'invert(1)'} : {'filter' : 'invert(0)'}]" @click="streamVisible = !streamVisible"></div>
    <div class="screenshot" @click="screenshot()"></div>
    
    


    <div class="debug-controls" id="my-interface" :class="{ menuOpen: menuActive }">
       
      <div @click="menuActive = !menuActive" class="close" :class="{ menuClose: !menuActive }"  @mouseover="playChirp(2000, 0.01, 0.05)"></div>
      <div class="debug-wrapper" :class="{ menuHidden: !menuActive }">
        <div class="buttonrow">
            <div class="circlebutton" @mousedown="playChirp(1000, 0.1, 0.02)" style=""  @mouseenter="playChirp(9000, 0.01, 0.01)"  @click="muted = !muted"><img src="~/@/assets/unmute.svg"  v-if="!muted"  style="width:16px;" alt=""><img src="~/@/assets/mute.svg" v-if="muted" style="width:16px;" alt=""></div>
            <div id="enterAR" class="circlebutton" @mousedown="playChirp(2000, 0.1, 0.02)" @mouseenter="playChirp(9000, 0.01, 0.01)"  style=""><img src="~/@/assets/ar-button.svg" style="width:16px;" alt=""></div>
            <div id="enterVR" class="circlebutton" @mousedown="playChirp(2000, 0.1, 0.02)" @mouseenter="playChirp(9000, 0.01, 0.01)"  style=""><img src="~/@/assets/vr-button.svg" style="width:16px;" alt=""></div>
            <div id="eyeCursor" class="circlebutton" @mousedown="playChirp(2000, 0.1, 0.02)" @mouseenter="playChirp(9000, 0.01, 0.01)" style="" @click="cursor = !cursor"><img src="~/@/assets/eye-cursor.svg" style="width:16px;" alt=""></div>
            <div :style="efficient ? 'background-color: #e7b648;' : 'background-color: white!important;'" id="effciency" class="circlebutton" @mousedown="playChirp(2000, 0.1, 0.02)" @mouseenter="playChirp(9000, 0.01, 0.01)" style="" @click="efficient = !efficient"><img src="~/@/assets/highres.svg" style="width:16px;" alt=""></div>
            <div id="equi" class="circlebutton" @mousedown="playChirp(2000, 0.1, 0.02)" @mouseenter="playChirp(9000, 0.01, 0.01)" style="" @click="getCanvas()"><img src="~/@/assets/equ.svg" style="width:16px;" alt=""></div>
            <div id="screen" class="circlebutton" @mousedown="playChirp(2000, 0.1, 0.02)" @mouseenter="playChirp(9000, 0.01, 0.01)" style="" @click="capture()"><img src="~/@/assets/screen.svg" style="width:16px;" alt=""></div>
        </div>

        <p>          Sphere: <span id="sphere-output">Calculating...</span>
</p>        <div class="button" style="color:black!important;" @click="enableLookControls()">OVERLAY</div>

        <p  style="display: flex;justify-content:space-between;position:relative;z-index:1000;width:auto;font-size:0.7rem;width:230px;">LATITUDE: <b style="color:#e7b648;">{{ latReceived }}</b> LONGITUDE: <b style="color:#e7b648;">{{ lonReceived }}</b></p>
        <p  style="line-height:1;display: flex;position:relative;z-index:1000;width:auto;font-size:0.7rem;margin-bottom:0.3rem;width:230px;">{{currentTime}} <b style="margin-left:auto;color:#e7b648;"><img src="~/@/assets/globe.svg" style="margin-top:-2px;height:16px;" alt=""></b></p>
          <div style="position:relative;z-index:1000;width:230px;">
            <p style="user-select:none;font-weight:100;margin:unset;margin-bottom:0.5rem;">orbit km {{ ((cameraPosition.z - 63.71) * 100).toFixed(2) }} <b @mouseenter="playChirp(9000, 0.01, 0.01)" class="smallButton" @click="cameraPosition.z = 0, playChirp(cameraPosition.z * 10, 0.1, 0.01)">RESET</b></p>
            <vue3-slider  @click="playChirp(cameraPosition.z * 10, 0.1, 0.01)"  v-model="cameraPosition.z" color="#e7b648" track-color="#FEFEFE" />        
          </div>

          <div style="position:relative;z-index:1000;width:230px;margin-top:0.4rem;">
            <p style="user-select:none;font-weight:100;margin:unset;margin-bottom:0.5rem;">second/s {{ (1 / timescale).toFixed(2) }}<b @mouseenter="playChirp(9000, 0.01, 0.01)" class="smallButton"  @click="timescale = 1, playChirp(timescale * 500, 0.1, 0.01)">REALTIME</b></p>
            <vue3-slider @click="playChirp(timescale * 500, 0.1, 0.01)" v-model="timescale" color="#e7b648" track-color="#FEFEFE"/>
          </div>

          <div style="position:relative;z-index:1000;width:230px;margin-top:0.4rem;">
            <p style="user-select:none;font-weight:100;margin:unset;margin-bottom:0.5rem;">ARC ANGLE {{arcang}}<b @mouseenter="playChirp(9000, 0.01, 0.01)" class="smallButton"  @click="timescale = 1, playChirp(timescale * 500, 0.1, 0.01)">REALTIME</b></p>
            <vue3-slider @click="playChirp(timescale * 500, 0.1, 0.01)" v-model="arcang" color="#e7b648" track-color="#FEFEFE"/>
          </div>

          <div style="position:relative;z-index:1000;width:230px;margin-top:0.4rem;">
            <p style="user-select:none;font-weight:100;margin:unset;margin-bottom:0.5rem;">ARC DEGREES {{arcdeg}}<b @mouseenter="playChirp(9000, 0.01, 0.01)" class="smallButton"  @click="timescale = 1, playChirp(timescale * 500, 0.1, 0.01)">REALTIME</b></p>
            <vue3-slider @click="playChirp(timescale * 500, 0.1, 0.01)" v-model="arcdeg" color="#e7b648" track-color="#FEFEFE"/>
          </div>

          <div style="position:relative;z-index:1000;width:230px;margin-top:0.4rem;">
            <p style="user-select:none;font-weight:100;margin:unset;margin-bottom:0.5rem;">field 0f view {{ fov }}<b @mouseenter="playChirp(9000, 0.01, 0.01)" class="smallButton"  @click="fov = 90, playChirp(timescale * 500, 0.1, 0.01)">DEFAULT</b></p>
            <vue3-slider @click="playChirp(fov * 500, 0.1, 0.01)" v-model="fov" max="170" color="#e7b648" track-color="#FEFEFE"/>
          </div>


    <div class="input-box"  style="border:1px solid #e7b648;border-radius:4px;position:relative;z-index:1000;margin-top:1rem;display: block;width:auto;background:#0004; backdrop-filter:blur(10px);padding:0.5rem 1rem;">
      <div class="row"><p>LAT</p> <input style="margin-top:unset;margin-bottom:unset;color:#e7b648;font-family: 'Space Mono', monospace; font-size:1rem;" v-model="latReceived" type="number" placeholder="0.00">
      <input style="margin-top:unset;margin-bottom:unset;border-top:unset;color:#e7b648;font-family: 'Space Mono', monospace; font-size:1rem;" v-model="lonReceived" type="number" placeholder="0.00">
      <p>LON</p></div><input style="margin:unset;margin-top:0.5rem;border-top:unset;color:#e7b648;font-family: 'Space Mono', monospace; font-size:1rem;" v-model="titleReceived" type="text" placeholder="Title">
      <input style="margin:unset;border-top:unset;color:#e7b648;font-family: 'Space Mono', monospace; font-size:1rem;" v-model="descReceived" type="text" placeholder="Desc">
      <input style="margin:unset;margin-bottom:1rem;border-top:unset;color:#e7b648;font-family: 'Space Mono', monospace; font-size:1rem;" v-model="linkReceived" type="text" placeholder="Link">
      <button style="cursor:pointer;padding:0.5rem;margin:unset;width:100%;border:unset;color:black;margin-bottom:0.5rem;font-family: 'Space Mono', monospace; font-size:1rem;" @mouseenter="playChirp(9000, 0.01, 0.01)"  @mousedown="playChirp(1000, 0.1, 0.02)" @click="addMarker" v-if="selectedIndex == null">Add Node</button>
      <button  style="cursor:pointer;padding:0.5rem;margin:unset;width:100%;border:unset;color:black;margin-bottom:0.5rem;font-family: 'Space Mono', monospace; font-size:1rem;" @mouseenter="playChirp(9000, 0.01, 0.01)" @mousedown="playChirp(1000, 0.1, 0.02)"  @click="handleMarkerRemoval(selectedIndex)" v-if="selectedIndex !== null">
Remove Node {{ selectedIndex }}
</button>

    </div></div>
  </div>
  </div>
</div>
</template>

<script>
import 'aframe'
import _ from 'lodash'; // Importing lodash
import 'aframe-extras'
import 'aframe-web-portals'
import "aframe-rounded-box-component"
import 'aframe-effects' // Import aframe-effects
import { mapState, mapActions } from 'vuex';
import { ethers } from 'ethers';
import ArcEntity from '../components/ArcEntity.vue';
import ArcEntityDeg from '../components/ArcEntityDeg.vue';
import slider from "vue3-slider";
/*import WebGPU from '../assets/threejs/examples/jsm/capabilities/WebGPU.js';
import WebGPURenderer from '../assets/threejs/examples/jsm/renderers/webgpu/WebGPURenderer.js';*/
import {
  EffectComposer,
  RenderPass,
  NoiseEffect,
  DepthOfFieldEffect,
  FXAAEffect,
  BlendFunction,
  BloomEffect,
} from 'postprocessing';
import * as THREE from 'three';
import 'aframe-globe-component' // Import aframe-effects
import * as d3 from 'd3';
export default {
  name: "app",
  data() {
      return {
          audioContext: new(window.AudioContext || window.webkitAudioContext)(),
          markerPositions: [
              { lat: -15, lon: -47, title: "Marker 1", description: "Brazil", link: "https://example.com" },
              { lat: -26, lon: 133, title: "Marker 1", description: "Australia", link: "https://example.com" },
              { lat: 9, lon: 18, title: "Marker 1", description: "Africa", link: "https://example.com" },
              { lat: 20, lon: 77, title: "Marker 1", description: "India", link: "https://example.com" },
              // Add more positions as needed
          ],
          selectedMarkerIndex: -1, // Initialize with an invalid index
          markerTooltip: {
              title: "",
              description: "",
              link: "",
              visible: false,
          },
          depthOfFieldEffect: {
              focusDistance: 0.5,
              focalLength: 0.1,
              bokehScale: 2,
          },

          intro: 0,
          windowHeightOpen: window.outerHeight - 420,
          windowHeightClosed: window.outerHeight - 72,
          muted: false,
          cursor: true,
          enteravail: false,
          cursorscale: 1,
          cursorrotationAngleY: 0,
          fov: 90,
          arcdeg: 90,
          arcang: 45,
          cursorOuterRotation: '0, 0, 0',
          rotationGlobal: '0 0 0',
          efficient: true,
          selectedIndex: null,
          latInput: null,
          lonInput: null,
          currentTime: null,
          latReceived: 0.00,
          lonReceived: 0.00,
          prog: 1,
          material: '',
          video: false,
          planet: true,
          lastLat: 0.00,
          lastLon: 0.00,
          titleReceived: 'Title',
          descReceived: 'Desc',
          linkReceived: 'Link',
          cameraOrbitMult: 69.47,
          cameraPosition: { x: 0, y: -1.6, z: 69.47 },
          mouseX: 0,
          mouseY: 0,
          keyboard: false,
          look: false,
          keyboardOutput: null,
          zPosition: 0,
          keyboardOutputTitle: null,
          keyboardOutputDesc: null,
          keyboardOutputLink: null,
          menuActive: false,
          earthRadius: 63.71,
          timescale: 1,
          sceneEl: null,
          renderer: null,
          camera: null,
          composer: null,
          stars: [],
          rotationAngleX: 0, // Initialize with the default angle
          rotationAngleY: 0, // Initialize with the default angle
          boxPositionLeft: '1.5, 0, -1',
          boxPositionRight: '-1.5, 0, -1',
          cursorRotation: '0, 0, 45',
          streamOpacity: 50,
          classificationOpacity: 0,
          streamSizeMult: 1,
          modalActive: false,
          streamVisible: true,
          markerHovered: false, // Added markerHovered property
          currentURL: "https://i.imgur.com/EQlNW3K.jpeg",
          currentURL2: "https://i.imgur.com/Ih1YFSy.jpeg",
          topDialRotation: '0 0 0',
          topDialRotationInv: '0 0 0',
      };
  },
  mounted() {

      this.created();

      this.adjustSceneSizeBound = this.adjustSceneSize.bind(this);
      this.dismissKeyboardBound = this.dismissKeyboard.bind(this);
      this.rotateCursorBound = this.rotateCursor.bind(this);
      this.moveBoxLeftBound = this.moveBoxLeft.bind(this);
      this.moveBoxRightBound = this.moveBoxRight.bind(this);
      this.updateRtcBound = this.updateRtc.bind(this);

      this.$el.addEventListener('superkeyboarddismiss', this.dismissKeyboardBound);
      window.addEventListener('resize', this.adjustSceneSizeBound);

      AFRAME.registerComponent('smooth-move', {
          schema: {
              speed: { type: 'number', default: 1 }
          },

          init: function() {
              this.mouseX = 0;
              this.mouseY = 0;
              this.origin = this.el.object3D.position.clone();
              this.position = this.el.object3D.position;
              this.tempVector = new THREE.Vector3();

              this.onMouseMove = this.onMouseMove.bind(this);
              this.onTick = this.onTick.bind(this);

              this.el.addEventListener('mousemove', this.onMouseMove);
              this.el.sceneEl.addEventListener('tick', this.onTick);
          },

          onMouseMove: function(event) {
              var rect = this.el.getBoundingClientRect();
              this.mouseX = event.clientX - rect.left;
              this.mouseY = event.clientY - rect.top;
          },

          onTick: function() {
              var rect = this.el.getBoundingClientRect();
              var xPercentage = (this.mouseX / rect.width) * 2 - 1;
              var yPercentage = (this.mouseY / rect.height) * 2 - 1;

              var xOffset = xPercentage * this.data.speed;
              var yOffset = yPercentage * this.data.speed;

              this.tempVector.set(
                  this.origin.x + xOffset,
                  this.origin.y + yOffset,
                  this.origin.z
              );

              this.position.lerp(this.tempVector, 0.1); // Adjust the lerp factor as needed
          },

          remove: function() {
              this.el.removeEventListener('mousemove', this.onMouseMove);
              this.el.sceneEl.removeEventListener('tick', this.onTick);
          }
      });

      AFRAME.registerComponent("camera-fade-in", {

          schema: {
              active: { type: 'boolean', default: true },
              duration: { type: 'float', default: 1.0 },
              color: { type: 'color', default: "black" },
          },

          init: function() {
              this.clock = new THREE.Clock();
              this.camera = document.querySelector("a-camera");

              if (this.data.active)
                  this.start();
          },

          start: function() {
              this.data.active = true;
              this.opacity = 1.0;

              // add sphere around camera
              this.cameraSphere = document.createElement("a-entity");
              this.cameraSphere.setAttribute("geometry", { primitive: "sphere", radius: 0.5 });
              this.cameraSphere.setAttribute("material", {
                  shader: "flat",
                  color: this.data.color,
                  transparent: true,
                  opacity: 1.0,
                  side: "back"
              });
              this.camera.appendChild(this.cameraSphere);

              this.finished = false;
          },

          tick: function() {
              let deltaTime = this.clock.getDelta();
              if (this.data.active && this.cameraSphere) {
                  this.opacity -= deltaTime / this.data.duration;
                  this.cameraSphere.setAttribute("material", "opacity", this.opacity);
                  if (this.opacity <= 0) {
                      this.data.active = false;
                      this.finished = true;
                      // must remove sphere, otherwise causes transparency flickering issues
                      this.camera.removeChild(this.cameraSphere);
                      this.cameraSphere = null;
                  }
              }
          }
      });

      AFRAME.registerComponent('particle-grid', {
          schema: {
              gridWidth: { type: 'number', default: 10 },
              gridHeight: { type: 'number', default: 10 },
              distance: { type: 'number', default: 1 },
              scale: { type: 'number', default: 0.1 },
              color: { type: 'color', default: '#FF0000' } // Added color property here
          },

          init: function() {
              const data = this.data;
              const el = this.el;

              for (let i = 0; i < data.gridWidth; i++) {
                  for (let j = 0; j < data.gridHeight; j++) {
                      const particle = document.createElement('a-circle');
                      particle.setAttribute('radius', data.scale);
                      particle.setAttribute('segments', 3);
                      particle.setAttribute('position', {
                          x: i * data.distance - (data.gridWidth * data.distance) / 2 + data.distance / 2,
                          y: j * data.distance - (data.gridHeight * data.distance) / 2 + data.distance / 2,
                          z: 0
                      });
                      particle.setAttribute('material', 'color', data.color); // Used color property here
                      el.appendChild(particle);
                  }
              }
          }
      });

      AFRAME.registerComponent("camera-fade-out", {

          schema: {
              active: { type: 'boolean', default: false },
              duration: { type: 'float', default: 1.0 },
              color: { type: 'color', default: "black" },
          },

          init: function() {
              this.clock = new THREE.Clock();
              this.camera = document.querySelector("a-camera");

              if (this.data.active)
                  this.start();
          },

          start: function() {
              this.data.active = true;
              this.opacity = 0.0;

              // add sphere around camera
              this.cameraSphere = document.createElement("a-entity");
              this.cameraSphere.setAttribute("geometry", { primitive: "sphere", radius: 0.5 });
              this.cameraSphere.setAttribute("material", {
                  shader: "flat",
                  color: this.data.color,
                  transparent: true,
                  opacity: 0.0,
                  side: "back"
              });
              this.camera.appendChild(this.cameraSphere);

              this.finished = false;
          },

          tick: function() {
              let deltaTime = this.clock.getDelta();
              if (this.data.active) {
                  this.opacity += deltaTime / this.data.duration;
                  this.cameraSphere.setAttribute("material", "opacity", this.opacity);
                  if (this.opacity >= 1) {
                      this.data.active = false;
                      this.finished = true;
                      // no need to remove sphere, because nothing is visible anyway
                      // this.camera.removeChild( this.cameraSphere );
                      // this.cameraSphere = null;
                  }
              }
          }
      });

      AFRAME.registerComponent('smooth-follow-mouse', {
          schema: {
              easing: { type: 'number', default: 0.1 },
              maxAngle: { type: 'number', default: 20 } // Maximum rotation angle in degrees
          },

          init: function() {
              this.targetRotation = new THREE.Euler();
              this.mouse = new THREE.Vector2();
              this.updateRotation = this.updateRotation.bind(this); // Bind this context
              this.lastUpdate = 0; // Add a timestamp of the last update
              this.updateInterval = 100; // Minimum interval between updates in milliseconds

              window.addEventListener('mousemove', this.updateRotation);
          },

          updateRotation: function(event) {
              const now = Date.now();

              // Check if the update interval has passed
              if (now - this.lastUpdate > this.updateInterval) {
                  this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
                  this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
                  this.lastUpdate = now; // Update the timestamp
              }
          },

          tick: function() {
              const easing = this.data.easing;
              const maxAngle = this.data.maxAngle;
              const camera = this.el.getObject3D('camera');
              const children = this.el.children;

              if (!camera) return;

              // Calculate the target rotation angles based on mouse position and the specified maximum angle
              const targetX = THREE.MathUtils.clamp(this.mouse.y * maxAngle * Math.PI / 180, -maxAngle * Math.PI / 180, maxAngle * Math.PI / 180);
              const targetY = THREE.MathUtils.clamp(-this.mouse.x * maxAngle * Math.PI / 180, -maxAngle * Math.PI / 180, maxAngle * Math.PI / 180);

              this.targetRotation.x = targetX;
              this.targetRotation.y = targetY;

              // Smoothly update the camera rotation
              camera.rotation.x += (this.targetRotation.x - camera.rotation.x) * easing;
              camera.rotation.y += (this.targetRotation.y - camera.rotation.y) * easing;

              // Smoothly update the children's rotation at half the speed
              const childEasing = easing / 2;
              for (let child of children) {
                  const childObj = child.object3D;
                  if (childObj) {
                      childObj.rotation.x += (this.targetRotation.x - childObj.rotation.x) * childEasing;
                      childObj.rotation.y += (this.targetRotation.y - childObj.rotation.y) * childEasing;
                  }
              }
          },

          remove: function() {
              window.removeEventListener('mousemove', this.updateRotation); // Use bound function to remove event listener
          }
      });


      AFRAME.registerComponent('smooth-camera', {
          schema: {
              easing: { type: 'number', default: 0.1 }
          },

          init: function() {
              this.targetPosition = new THREE.Vector3();
              this.targetQuaternion = new THREE.Quaternion();
          },

          tick: function() {
              const easing = this.data.easing;
              const camera = this.el.getObject3D('camera');
              const children = this.el.children;

              if (!camera) return;

              // Get the target position and rotation from the camera's parent entity
              this.targetPosition.copy(this.el.object3D.position);
              this.targetQuaternion.copy(this.el.object3D.quaternion);

              // Calculate the eased position and rotation for the camera
              camera.position.lerp(this.targetPosition, easing);
              camera.quaternion.slerp(this.targetQuaternion, easing);

              // Ease the children’s position and rotation at half the speed of the camera
              const childEasing = easing / 2;
              for (let child of children) {
                  const childObj = child.object3D;
                  if (childObj) {
                      childObj.position.lerp(this.targetPosition, childEasing);
                      childObj.quaternion.slerp(this.targetQuaternion, childEasing);
                  }
              }
          }
      });

      AFRAME.registerComponent('start', {

          init: function() {
              // block pointer events until buttonEnter is clicked
              let uiDiv = document.getElementById("uiDiv");
              uiDiv.style["pointer-events"] = "auto";

              // set up background blocker
              uiDiv.style["background-color"] = "rgba(0, 0, 0, 1)";

              // indicate clickable with hand cursor (desktop)
              let buttonEnter = document.getElementById("buttonEnter");
              buttonEnter.style.cursor = "pointer";

              // fade-in functionality
              let fadeIn = function() {
                  // allow point events again
                  uiDiv.style["pointer-events"] = "none";

                  // sounds can only be triggered after a mouse interaction
                  let soundPlayer = document.querySelector("#ambientSound");
                  soundPlayer.components.sound.playSound();

                  // remove startButton
                  buttonEnter.parentNode.remove(buttonEnter);

                  // fade out uiDiv background
                  uiDiv.style["background-color"] = "rgba(0, 0, 0, 0.0)";
                  uiDiv.style["transition"] = "background-color 1000ms linear";
              }

              // activate for desktop/touchscreen
              buttonEnter.addEventListener('touchstart', fadeIn);
              buttonEnter.addEventListener('mousedown', fadeIn);
          }
      });

      AFRAME.registerComponent('edgeGlow', {
          schema: {
              color: { type: 'color', default: '#aaeeff' },
              fresnelExponent: { type: 'number', default: 1.0 },
              power: { type: 'number', default: 2.0 },
              outlineThickness: { type: 'number', default: 0.005 }, // Added
              outlineColor: { type: 'color', default: '#000000' } // Added
          },

          init: function() {
              const data = this.data;

              this.material = new THREE.ShaderMaterial({
                  uniforms: {
                      color: { type: 'c', value: new THREE.Color(data.color) },
                      fresnelExponent: { value: data.fresnelExponent },
                      power: { value: data.power },
                      outlineThickness: { value: data.outlineThickness }, // Added
                      outlineColor: { type: 'c', value: new THREE.Color(data.outlineColor) } // Added
                  },
                  vertexShader: `
              varying vec3 vViewPosition;
              varying vec3 vNormal;
              void main() {
                  vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
                  gl_Position = projectionMatrix * mvPosition;
                  vViewPosition = -mvPosition.xyz;
                  vNormal = normalize(normalMatrix * normal); // Added
              }
          `,
                  fragmentShader: `
              uniform vec3 color;
              uniform float fresnelExponent;
              uniform float power;
              uniform float outlineThickness; // Added
              uniform vec3 outlineColor; // Added
              varying vec3 vViewPosition;
              varying vec3 vNormal; // Added
              void main() {
                  // Outline
                  float outline = 1.0 - smoothstep(1.0 - outlineThickness, 1.0, dot(vNormal, vec3(0.0, 0.0, 1.0)));
                  
                  // Fresnel Glow
                  vec3 viewDirectionW = normalize(vViewPosition);
                  float fresnelTerm = pow(1.0 - dot(viewDirectionW, vec3(0.0, 0.0, 1.0)), fresnelExponent);
                  vec3 finalColor = color * fresnelTerm * power;
                  
                  // Combine
                  vec3 mixedColor = mix(outlineColor * outline, finalColor, fresnelTerm);
                  gl_FragColor = vec4(mixedColor, max(outline, fresnelTerm));
              }
          `,
                  transparent: true,
              });

              this.el.addEventListener('model-loaded', () => {
                  this.applyMaterial();
              });
          },

          applyMaterial: function() {
              const mesh = this.el.getObject3D('mesh');
              if (mesh) {
                  mesh.material = this.material;
              }
          },
      });


      AFRAME.registerComponent('hover-opacity', {
          schema: {
              hoverOpacity: { type: 'number', default: 0.5 }
          },

          init: function() {
              const el = this.el;
              const data = this.data;

              // Function to set the opacity when hovered
              this.onMouseEnter = function() {
                  // Store the original opacity
                  el.setAttribute('data-original-opacity', el.getAttribute('material').opacity);
                  el.setAttribute('material', 'opacity', data.hoverOpacity);
              };

              // Function to reset the opacity when mouse leaves
              this.onMouseLeave = function() {
                  // Retrieve the original opacity and reset it
                  const originalOpacity = el.getAttribute('data-original-opacity');
                  el.setAttribute('material', 'opacity', originalOpacity);
              };

              el.addEventListener('mouseenter', this.onMouseEnter);
              el.addEventListener('mouseleave', this.onMouseLeave);
          },

          // Remove event listeners when the component is removed
          remove: function() {
              this.el.removeEventListener('mouseenter', this.onMouseEnter);
              this.el.removeEventListener('mouseleave', this.onMouseLeave);
          }
      });


      AFRAME.registerComponent('line-stack', {
          schema: {
              lineCount: { type: 'int', default: 11 }, // Number of lines
              lineSpacing: { type: 'number', default: 0.1 }, // Spacing between lines
              lineWidth: { type: 'number', default: 0.02 }, // Width of lines
              lineColor: { type: 'color', default: '#ffffff' }, // Line color
              rotationAngleY: { type: 'number', default: 0 }, // Rotation angle for motion
              fadeDuration: { type: 'number', default: 10 }, // Duration for fade in/out (in milliseconds)
              updateRotationY: { type: 'number', default: 0 }, // New property to track rotation based on external variable
          },

          init: function() {
              this.lines = []; // Array to store line entities
              // Create the stack of lines
              for (let i = 0; i < this.data.lineCount; i++) {
                  const yPos = (i - (this.data.lineCount - 1) / 2) * this.data.lineSpacing;
                  const line = document.createElement('a-entity');
                  line.setAttribute('line', {
                      start: `-${this.data.lineWidth / 2} ${yPos} 0`,
                      end: `${this.data.lineWidth / 2} ${yPos} 0`,
                      color: this.data.lineColor,
                      opacity: i === 0 ? 1 : 0, // Set opacity for the first line to 1, others to 0
                  });
                  this.lines.push(line);
                  this.el.appendChild(line);
              }

              // Set up an interval for cycling lines
              this.cycleInterval = setInterval(this.cycleLines.bind(this), this.data.fadeDuration);
          },

          update: function(oldData) {
              // Check if the updateRotationY property has changed
              if (this.data.updateRotationX !== oldData.updateRotationX) {
                  // Update the rotation angle based on the new value
                  this.data.rotationAngleX = this.data.updateRotationX;

                  // Call the method to update line positions
                  this.updateLinePositions();
              }
          },

          updateLinePositions: function() {
              console.log('rotation Triggered');
              // Calculate the yOffset based on the rotationAngleY
              const yOffset = this.rotationAngleX * this.data.lineSpacing * 0.5;

              // Move each line up and down based on the yOffset
              for (let i = 0; i < this.lines.length; i++) {
                  const line = this.lines[i];
                  const yPos = (i - (this.data.lineCount - 1) / 2) * this.data.lineSpacing + yOffset;
                  line.setAttribute('line', {
                      start: `-${this.data.lineWidth / 2} ${yPos} 0`,
                      end: `${this.data.lineWidth / 2} ${yPos} 0`,
                  });
              }
          },

          cycleLines: function() {
              // Fade out the bottom line and fade in the top line
              const bottomLine = this.lines[0];
              const topLine = this.lines[this.data.lineCount - 1];

              // Fade out the bottom line
              bottomLine.setAttribute('animation__fadeout', {
                  property: 'components.line.material.opacity',
                  to: 1,
                  dur: this.data.fadeDuration,
                  easing: 'linear',
              });

              // Fade in the top line
              topLine.setAttribute('animation__fadein', {
                  property: 'components.line.material.opacity',
                  to: 1,
                  dur: this.data.fadeDuration,
                  easing: 'linear',
              });

              // Move the bottom line to the top and reset its opacity
              this.lines.shift();
              this.lines.push(bottomLine);
              bottomLine.setAttribute('line', { opacity: 1 });
          },

          remove: function() {
              // Clear the cycleInterval when the component is removed
              clearInterval(this.cycleInterval);
          },
      });


      AFRAME.registerComponent('rotation-reader', {
          tick: function() {
              // `this.el` is the element.
              // `object3D` is the three.js object.

              // Get the rotation as a three.js Euler using radians.
              const rotation = this.el.object3D.rotation;

              // Calculate and log the angle in degrees based on the y-axis rotation.
              const angleInDegreesY = THREE.MathUtils.radToDeg(rotation.y);
              this.rotationAngleY = angleInDegreesY;
              window.globalRotationAngleY = angleInDegreesY;

              //console.log('Angle in degrees Y:', this.rotationAngleY);

              const angleInDegreesX = THREE.MathUtils.radToDeg(rotation.x);
              this.rotationAngleX = angleInDegreesX;
              this.el.emit('rotation-updated', { rotationAngleX: this.rotationAngleX });
              //console.log('Angle in degrees X:', this.rotationAngleX);

              // `position` is a three.js Vector3.
              //console.log('Position:', this.el.object3D.position);
              this.el.emit('rotation-updated', {
                  rotationAngleY: this.rotationAngleY,
                  rotationAngleX: this.rotationAngleX,
              });

          }
      });
      const animate = () => {
          this.rotateCursorBound();
          this.moveBoxLeftBound();
          this.moveBoxRightBound();
          this.updateRtcBound();
          requestAnimationFrame(animate);
      };
      animate();
      const sceneEl = document.querySelector('a-scene');


      setInterval(this.rotateCursor, 50); // Update rotation every 10 milliseconds   
      setInterval(this.moveBoxLeft, 50); // Update rotation every 10 milliseconds   
      setInterval(this.moveBoxRight, 50); // Update rotation every 10 milliseconds   

      sceneEl.addEventListener('rotation-updated', (event) => {
          // Update the rotationAngleY and rotationAngleX properties when the event is received
          this.rotationAngleY = event.detail.rotationAngleY;
          this.rotationAngleX = event.detail.rotationAngleX;
      });

      // Get the raycaster component
      const raycaster = this.$el.querySelector('[raycaster]');

      // Set the ignore property to select elements with the "ignored" class
      raycaster.setAttribute('raycaster', 'ignore', '.ignored');

      let labelScript = document.createElement('script')
      labelScript.setAttribute('src', 'https://cdn.jsdelivr.net/npm/aframe-label@0.1.0/index.min.js')
      document.head.appendChild(labelScript)



      setInterval(this.updateRtc, 1000); // Update rotation every 10 milliseconds

  },
  beforeUnmount() {
      // Remove the resize event listener when the component is unmounted
      window.removeEventListener('resize', this.adjustSceneSize);
      window.removeEventListener('resize', this.adjustSceneSizeBound);
      this.$el.removeEventListener('superkeyboarddismiss', this.dismissKeyboardBound);


  },
  methods: {

      goToURL(url) {
          window.location.href = url;
      },
      ...mapActions(['login', 'purchase']),
      prettyBalance(bal) {
          return parseFloat(ethers.utils.formatEther(bal)).toFixed(4);
      },
      simpleBalance(bal2) {
          return parseFloat(ethers.utils.formatEther(bal2)).toFixed(1);
      },
      condenseBalance(bal3) {
          return parseFloat(bal3).toFixed(0);
      },
      async dispatchPurchase() {
          const value = ethers.utils.parseEther(this.value).div(this.pricePerEth);
          this.purchase({ value });
      },
      topDialOnMouseDown() {
          const cameraEl = document.getElementById('camera');
          cameraEl.setAttribute('look-controls', 'enabled', false);

          window.addEventListener('mousemove', this.topDialOnMouseMove);
          window.addEventListener('mouseup', this.topDialOnMouseUp);
      },

      topDialOnMouseUp() {
          const cameraEl = document.getElementById('camera');
          cameraEl.setAttribute('look-controls', 'enabled', true);

          window.removeEventListener('mousemove', this.topDialOnMouseMove);
          window.removeEventListener('mouseup', this.topDialOnMouseUp);
      },

      topDialOnMouseMove(e) {
          const centerX = window.innerWidth / 2;

          const dx = e.clientX - centerX;
          let angle = Math.abs((dx / (window.innerWidth / 2)) * 60);
          angle = Math.max(0, Math.min(45, angle)) * -1;

          if (e.clientX > centerX) {
              angle = -angle;
          }

          if (!this.chirpInProgress) {
              this.playChirp(8000, 0.1, 0.005);
              this.chirpInProgress = true;
              setTimeout(() => this.chirpInProgress = false, 5);
          }

          this.timescale = Math.abs(angle) + 1;

          this.topDialRotation = `0 0 ${angle.toFixed(2)}`;
          this.topDialRotationInv = `0 0 ${-angle.toFixed(2)}`; // Assuming you want the inverse rotation, you can adjust it accordingly
      },



      created() {
          // Use lodash to create a throttled version of the mouse move handler
          this.topDialOnMouseDown = this.topDialOnMouseDown.bind(this);
          this.topDialOnMouseUp = this.topDialOnMouseUp.bind(this);
          this.topDialOnMouseMove = _.throttle(this.topDialOnMouseMove.bind(this), 16);
      },

      enableLookControls() {
          const camera = document.getElementById("#camera");
          const camera1 = document.getElementById("#camera1");
          // To enable look controls
          camera1.setAttribute('camera', 'active', false);
          camera.setAttribute('camera', 'active', true);
          console.log('switched cameras');
      },

      startOverviewEffect(endPosition, endRotation, duration) {
          this.playChirp(3000, 0.1, 0.2);

          const camera = this.$refs.camera;
          const startPosition = camera.getAttribute('position');
          const startRotation = camera.getAttribute('rotation');

          duration = parseInt(duration);
          if (isNaN(duration) || duration <= 0) {
              duration = 1000;
          }

          const startTime = performance.now();

          // Ease-in-out cubic function
          const easeInOutCubic = (t) => {
              return t < 0.5 ?
                  4 * t * t * t :
                  (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
          };

          const animateCameraPositionAndRotation = (time) => {
              const elapsed = time - startTime;
              let fraction = Math.min(elapsed / duration, 1);

              // Apply the easing function to the fraction
              fraction = easeInOutCubic(fraction);

              const x = startPosition.x + (endPosition.x - startPosition.x) * fraction;
              const y = startPosition.y + (endPosition.y - startPosition.y) * fraction;
              const z = startPosition.z + (endPosition.z - startPosition.z) * fraction;

              const xr = startRotation.x + (endRotation.x - startRotation.x) * fraction;
              const yr = startRotation.y + (endRotation.y - startRotation.y) * fraction;
              const zr = startRotation.z + (endRotation.z - startRotation.z) * fraction;

              camera.setAttribute('position', { x, y, z });
              camera.setAttribute('rotation', { x: xr, y: yr, z: zr });

              if (fraction < 1) {
                  requestAnimationFrame(animateCameraPositionAndRotation);
              }
          };

          requestAnimationFrame(animateCameraPositionAndRotation);
      },


      loadScript() {
          // Load your script dynamically
          const script1 = document.createElement('script');
          script1.src = '//unpkg.com/satellite.js/dist/satellite.min.js';
          script1.async = true;
          script1.onload = () => {
              // Script loaded successfully
          };

          const script = document.createElement('script');
          script.src = 'satellite-load.js';
          script.async = true;
          script.onload = () => {
              // Script loaded successfully
          };


          // Append the script to the document's body
          document.body.appendChild(script1);
          // Append the script to the document's body
          document.body.appendChild(script);
      },
      randomPaths() { // Gen random paths
          const N_PATHS = 10;
          const MAX_POINTS_PER_LINE = 10000;
          const MAX_STEP_DEG = 1;
          const MAX_STEP_ALT = 0.015;
          const gData = [...Array(N_PATHS).keys()].map(() => {
              let lat = (Math.random() - 0.5) * 90;
              let lng = (Math.random() - 0.5) * 360;
              let alt = 0;

              return [
                  [lat, lng, alt], ...[...Array(Math.round(Math.random() * MAX_POINTS_PER_LINE)).keys()].map(() => {
                      lat += (Math.random() * 2 - 1) * MAX_STEP_DEG;
                      lng += (Math.random() * 2 - 1) * MAX_STEP_DEG;
                      alt += (Math.random() * 2 - 1) * MAX_STEP_ALT;
                      alt = Math.max(0, alt);

                      return [lat, lng, alt];
                  })
              ];
          });

          const globeEntity = document.getElementById('globe2');
          globeEntity.setAttribute('globe2', {
              pathsData: gData,
              pathColor: () => ['rgba(0,0,255,0.6)', 'rgba(255,0,0,0.6)'],
              pathDashLength: 0.01,
              pathDashGap: 0.004,
              pathDashAnimateTime: 100000
          });

          setTimeout(() => {
              globeEntity.setAttribute('globe2', {
                  pathPointAlt: pnt => pnt[2], // set altitude accessor
                  pathTransitionDuration: 4000
              });
          }, 6000);
      },
      calculateLeftComplication() {
          const screenHeight = window.innerHeight;
          const screenWidth = window.innerWidth;
          if (this.isDesktop()) {

              // Calculate the desired x position as a proportion of screen height to screen width
              const desiredXPosition = -0.6 / (screenHeight / screenWidth);

              return { x: desiredXPosition, y: 0, z: -1 };
          } else {
              // Calculate the desired x position as a proportion of screen height to screen width
              const desiredXPosition = -1.6 / (screenHeight / screenWidth);

              return { x: desiredXPosition, y: 0, z: -2 };
          }
      },
      calculateRightComplication() {
          const screenHeight = window.innerHeight;
          const screenWidth = window.innerWidth;
          if (this.isDesktop()) {

              // Calculate the desired x position as a proportion of screen height to screen width
              const desiredYPosition = 0.6 / (screenHeight / screenWidth);

              return { x: desiredYPosition, y: 0, z: -1 };
          } else {
              // Calculate the desired x position as a proportion of screen height to screen width
              const desiredYPosition = 1.6 / (screenHeight / screenWidth);

              return { x: desiredYPosition, y: 0, z: -2 };
          }

      },
      moveBoxLeft() {
          const screenHeight = window.innerHeight;
          const screenWidth = window.innerWidth;

          const rotL = this.rotationAngleX * 0.01;

          if (this.isDesktop()) {

              this.boxPositionLeft = { x: (-0.6 - this.zPosition) / (screenHeight / screenWidth), y: rotL, z: -1 };

          } else {
              this.boxPositionLeft = { x: (-1.6 - this.zPosition) / (screenHeight / screenWidth), y: rotL, z: -2 };

          }



          if (this.rotationAngleX !== this.lastRotationAngleX) {
              if (!this.chirpInProgress) {
                  // Chirp is not in progress, play the chirp sound
                  this.playChirp(
                      16000 + parseFloat(this.rotationAngleX).toFixed(2) * 10,
                      0.1,
                      0.1
                  );

                  // Set the flag to indicate that a chirp is in progress
                  this.chirpInProgress = true;

                  // Set a timeout to reset the chirp status after 50 milliseconds
                  setTimeout(() => {
                      this.chirpInProgress = false;
                  }, 100);
              }
          }

          // Update the lastRotationAngleX to the current value
          this.lastRotationAngleX = this.rotationAngleX;
      },
      moveBoxRight() {
          const screenHeight = window.innerHeight;
          const screenWidth = window.innerWidth;

          if (this.rotationAngleY >= 180) {
              this.rotationAngleY == this.rotationAngleY - this.rotationAngleY;

              return this.rotationAngleY;

          }
          if (this.rotationAngleY <= -180) {
              this.rotationAngleY == this.rotationAngleY + this.rotationAngleY;
              return this.rotationAngleY;
          }
          const rotR = this.rotationAngleY * 0.01;

          if (this.isDesktop()) {

              this.boxPositionRight = { x: (0.6 + this.zPosition) / (screenHeight / screenWidth), y: rotR, z: -1 };

          } else {
              this.boxPositionRight = { x: (1.6 + this.zPosition) / (screenHeight / screenWidth), y: rotR, z: -2 };

          }
          if (this.rotationAngleY !== this.lastRotationAngleY) {
              if (!this.chirpInProgress) {
                  // Chirp is not in progress, play the chirp sound
                  this.playChirp(
                      16000 + parseFloat(this.rotationAngleY).toFixed(2) * 10,
                      0.1,
                      0.1
                  );

                  // Set the flag to indicate that a chirp is in progress
                  this.chirpInProgress = true;

                  // Set a timeout to reset the chirp status after 50 milliseconds
                  setTimeout(() => {
                      this.chirpInProgress = false;
                  }, 100);
              }
          }

          // Update the lastRotationAngleY to the current value
          this.lastRotationAngleY = this.rotationAngleY;
      },
      rotateCursor() {

          const rotC = this.rotationAngleY;


          this.cursorRotation = { x: 0, y: 0, z: rotC };
          if (this.rotationAngleY !== this.lastRotationAngleY) {
              if (!this.chirpInProgress) {
                  // Chirp is not in progress, play the chirp sound
                  this.playChirp(
                      16000 + parseFloat(this.rotationAngleY).toFixed(2) * 10,
                      0.1,
                      0.1
                  );

                  // Set the flag to indicate that a chirp is in progress
                  this.chirpInProgress = true;

                  // Set a timeout to reset the chirp status after 50 milliseconds
                  setTimeout(() => {
                      this.chirpInProgress = false;
                  }, 100);
              }
          }

          // Update the lastRotationAngleY to the current value
          this.lastRotationAngleY = this.rotationAngleY;
      },
      adjustFOVAndZPosition(event) {
          if (event.deltaY < 0) {
              this.fov = Math.max(this.fov - 1, 90);
          } else {
              this.fov = Math.min(this.fov + 1, 90);
          }
          this.zPosition = this.mapRange(this.fov, 90, 90, 0.4, -0.4);
          requestAnimationFrame(() => console.log('Adjusted FOV:', this.fov, 'Z Position:', this.zPosition));
      },


      mapRange(value, inMin, inMax, outMin, outMax) {
          return (value - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
      },
      progs() {
          this.keyboardOutput = null;
          this.prog++;
          console.log(this.prog);
          this.cursor = false;
          if (this.prog == 4) {
              this.prog = 1;
          }
      },

      efficientSwap(status) {
          let isToggled = status;

          isToggled = !isToggled; // Toggle the state
          this.efficient = isToggled;
          console.log('Toggled state is now:', isToggled); // Log the current state
      },
      dismissKeyboard() {
          // Handle the 'superkeyboarddismiss' event
          this.cursor = true; // Set 'keyboard' to false to dismiss the keyboard
          if (this.prog == 1) {
              this.keyboardOutputTitle = this.keyboardOutput;
          }
          if (this.prog == 2) {
              this.keyboardOutputDesc = this.keyboardOutput;
          }
          if (this.prog == 3) {
              this.keyboardOutputLink = this.keyboardOutput;
          }
          console.log(this.cursor);
      },
      visitLink(url) {
          if (typeof url === 'string' && url.length > 0) {
              // Open the URL in a new tab or window
              window.open(url, '_blank');
          } else {
              console.error('Invalid URL provided');
          }
      },

      adjustSceneSize() {
          const aframeScene = this.$refs.scene;

          // Get the current viewport dimensions
          const viewportWidth = window.innerWidth;
          const viewportHeight = window.innerHeight;

          // Set the A-Frame scene's dimensions to match the viewport
          aframeScene.setAttribute('width', viewportWidth);
          aframeScene.setAttribute('height', viewportHeight);
      },

      isDesktop() {
          // Use a media query to check screen width
          return window.matchMedia("(min-width: 1024px)").matches;
      },

      isMobile() {
          // Use a media query to check screen width
          return window.matchMedia("(max-width: 1024px)").matches;
      },


      randomArcs() { // Gen random data
          const N = 20;
          const arcsData = [...Array(N).keys()].map(() => ({
              startLat: (Math.random() - 0.5) * 180,
              startLng: (Math.random() - 0.5) * 360,
              endLat: (Math.random() - 0.5) * 180,
              endLng: (Math.random() - 0.5) * 360,
              color: [
                  ['red', 'white', 'blue', 'green'][Math.round(Math.random() * 3)],
                  ['red', 'white', 'blue', 'green'][Math.round(Math.random() * 3)]
              ]
          }));

          const globeEntity = document.getElementById('globe2');
          globeEntity.setAttribute('globe2', {
              arcsData: arcsData,
              arcColor: 'color',
              arcDashLength: () => Math.random(),
              arcDashGap: () => Math.random(),
              arcDashAnimateTime: () => Math.random() * 4000 + 500
          });
      },
      popMap() {
          const weightColor = d3.scaleSequentialSqrt(d3.interpolateYlOrRd)
              .domain([0, 1e7]);

          const globeEntity = document.getElementById('globe2');
          globeEntity.setAttribute('globe2', {
              hexBinPointWeight: 'pop',
              hexAltitude: d => d.sumWeight * 6e-8,
              hexBinResolution: 3,
              hexTopColor: d => weightColor(d.sumWeight),
              hexSideColor: d => weightColor(d.sumWeight),
              hexBinMerge: true
          });

          fetch('worldpop.csv').then(res => res.text())
              .then(csv => d3.csvParse(csv, ({ lat, lng, pop }) => ({ lat: +lat, lng: +lng, pop: +pop })))
              .then(data => globeEntity.setAttribute('globe', 'hexBinPointsData', data));
      },

      /*  async setupScene() {
            try {
                if (WebGPU.isAvailable() === false) {
                    document.body.appendChild(WebGPU.getErrorMessage());
                    throw new Error('No WebGPU support');
                } else {
                    const sceneEl = this.$refs.scene;

                    // Create a WebGPURenderer instance and set it as the renderer for the scene
                    const webGPURenderer = new WebGPURenderer({ antialias: true });
                    sceneEl.renderer = webGPURenderer;

                    // Initialize other parts of your A-Frame scene as needed
                    this.loadEnvironmentComponent();
                    this.generateStars();

                    sceneEl.addEventListener('loaded', () => {
                        this.sceneEl = sceneEl;

                        // Initialize and configure post-processing effects
                        this.setupPostProcessing();
                    });

                    setInterval(this.updateRtc, 1000); // Update rotation every 10 milliseconds
                }
            } catch (error) {
                console.error(error);
            }
        },*/

      setupPostProcessing() {
          // Wait for the A-Frame scene to load
          this.$refs.scene.addEventListener('loaded', () => {
              const sceneEl = this.$refs.scene;
              const renderer = sceneEl.renderer;
              const camera = this.$refs.camera.getObject3D('camera');

              // Create the composer and configure post-processing passes
              const composer = new EffectComposer(renderer);

              // Add and configure post-processing passes
              const renderPass = new RenderPass(sceneEl.object3D, camera);
              composer.addPass(renderPass);

              // ... rest of your post-processing setup
              // Create and add the BloomEffect
              const bloomEffect = new BloomEffect({
                  blendFunction: BlendFunction.SCREEN,
                  luminanceThreshold: 0.8,
                  luminanceSmoothing: 0.075,
                  intensity: 1.0,
                  distinction: 3.0,
              });
              composer.addPass(bloomEffect);

              // Create and add the DepthOfFieldEffect
              const dofEffect = new DepthOfFieldEffect(camera, {
                  focusDistance: 0.1,
                  focalLength: 0.1,
                  bokehScale: 2.0,
                  height: 480, // Adjust the height as needed
              });
              composer.addPass(dofEffect);

              // Create and add the FXAAEffect
              const fxaaEffect = new FXAAEffect();
              composer.addPass(fxaaEffect);
              // Store the original render function
              const originalRender = renderer.render;

              // Set up a custom render function
              renderer.render = () => {
                  if (composer) {
                      // Call the original render method
                      originalRender.apply(renderer, arguments);
                      // Render the post-processing effects
                      composer.render();
                  } else {
                      // Handle the case where composer is not yet initialized
                      originalRender.apply(renderer, arguments);
                  }
              };

              console.log('Post Processing Setup Complete');
          });
      },
      initEffectComposer() {
          const sceneEl = this.sceneEl;

          if (!sceneEl.hasLoaded) {
              return;
          }

          const scene = sceneEl.object3D;
          const renderer = sceneEl.renderer;
          const camera = sceneEl.camera;

          const composer = new EffectComposer(renderer);
          const pass1 = new RenderPass(scene, camera);
          const pass2 = new BloomEffect(3, 25, 5, 256);
          const pass3 = new NoiseEffect();

          pass3.renderToScreen = true;

          composer.addPass(pass1);
          composer.addPass(pass2);
          composer.addPass(pass3);

          this.composer = composer;
          this.t = 0;
          this.dt = 0;

          this.bind();
      },

      /**
       * Record the timestamp for the current frame.
       * @param {number} t
       * @param {number} dt
       */
      tick: function(t, dt) {
          this.t = t;
          this.dt = dt;
      },
      bind() {
          const renderer = this.sceneEl.renderer;
          const system = this;
          let isDigest = false;

          const originalRender = renderer.render; // Store the original render method

          renderer.render = function() {
              if (system.composer) {
                  if (isDigest) {
                      originalRender.apply(this, arguments); // Call the original render method
                  } else {
                      isDigest = true;
                      system.composer.render(system.dt);
                      isDigest = false;
                  }
              } else {
                  // Handle the case where composer is not yet initialized
                  originalRender.apply(this, arguments);
              }
          };
      },
      getCanvas() {
          document.querySelector('a-scene').components.screenshot.getCanvas('equirectangular');

      },

      capture() {
          document.querySelector('a-scene').components.screenshot.capture('perspective')

      },

      preloadMarkerRemoval(index) {
          this.selectedIndex = index;
          console.log(index + " Selected")
      },
      handleMarkerRemoval() {
          // Create a new array without the marker at the specified index
          const index2 = this.selectedIndex;
          this.markerPositions = this.markerPositions.filter((_, i) => i !== index2);
          this.playChirp(index2 * 5 + 100, 0.1, 0.05);

          // Handle any other necessary updates
          console.log(`Marker at index ${index2} removed.`);
          this.selectedIndex = null;

      },
      onMarkerMouseEnter(title, description, link, index) {
          this.markerHovered = true;
          const raycaster = this.$refs.raycaster;
          const intersection = raycaster.components.raycaster.intersections[0];

          if (intersection) {
              const endPosition = intersection.point;
              const rayLine = this.$refs.rayLine;
              rayLine.setAttribute("line", `start: 0 0 0; end: ${endPosition.x} ${endPosition.y} ${endPosition.z}; color: white`);
              rayLine.setAttribute("visible", true);
          }

          this.markerTooltip.title = title;
          this.markerTooltip.description = description;
          this.markerTooltip.link = link;
          this.markerTooltip.visible = true;
          this.selectedMarkerIndex = index; // Set the selected marker index
      },
      onMarkerMouseLeave() {
          this.markerHovered = false;
          this.selectedMarkerIndex = -1; // Reset the selected marker index

          this.$refs.rayLine.setAttribute("visible", false);
      },

      updateRtc() {
          const currentTimeUTC = new Date().toUTCString().toUpperCase();
          this.currentTime = currentTimeUTC;
      },
      handleLatitudeLongitude({ lat, lon }) {
          console.log(`Received latitude: ${lat.toFixed(2)}, longitude: ${lon.toFixed(2)}`);
          this.latReceived = lat.toFixed(2);
          this.lonReceived = lon.toFixed(2);
          // Do whatever you need to do with the received latitude and longitude values
      },
      handleTitleDescLink({ title, desc, link }) {
          console.log(`Received Title: ${title}, Desc: ${desc}, Link: ${link}`);
          this.titleReceived = title;
          this.descReceived = desc;
          this.linkReceived = link;
          // Do whatever you need to do with the received latitude and longitude values
      },
      playChirp(freq, time, volume = 1) {
          if (this.muted !== true) {
              const duration = time;
              const frequency = freq;

              const oscillator = this.audioContext.createOscillator();
              oscillator.type = 'square';
              oscillator.frequency.setValueAtTime(frequency, this.audioContext.currentTime);

              const gainNode = this.audioContext.createGain();
              gainNode.gain.setValueAtTime(volume, this.audioContext.currentTime);

              oscillator.connect(gainNode);
              gainNode.connect(this.audioContext.destination);

              oscillator.start();
              oscillator.stop(this.audioContext.currentTime + duration);
          }
      },
      addMarker() {
          if (this.latReceived !== null && this.lonReceived !== null) {
              this.titleReceived = this.keyboardOutputTitle;
              this.descReceived = this.keyboardOutputDesc;
              this.linkReceived = this.keyboardOutputLink;
              const newMarker = {
                  lat: parseFloat(this.latReceived),
                  lon: parseFloat(this.lonReceived),
                  title: this.titleReceived, // Set a default title
                  description: this.descReceived,
                  link: this.linkReceived,
              };
              this.playChirp(newMarker.lat * 20 + 400, 0.05, 0.05);
              this.playChirp(newMarker.lon * 20 + 400, 0.05, 0.05);
              this.markerPositions.push(newMarker);

              // Clear the input values
              this.latInput = null;
              this.lonInput = null;
              this.latReceived = null;
              this.lonReceived = null;
              this.titleReceived = null;
              this.descReceived = null;
              this.linkReceived = null;


          }


      },
      handleMouseMove(event) {
          const centerX = window.innerWidth / 2;
          const deviationX = event.clientX - centerX;

          // Scale increases as the mouse moves closer to the center
          this.scale = 1 - Math.abs(deviationX) / centerX;

          // Rotation based on the position of the mouse from the center
          const rotationY = (deviationX / centerX) * 90; // Adjust 90 as per your needs for max rotation

          // Convert rotationY to an array format
          this.cursorrotationAngleY = [0, 0, rotationY];

          this.rotateCursorOuter();
      },
      rotateCursorOuter() {
          const rotC = this.cursorrotationAngleY[2]; // Access the third element for the Y rotation

          this.cursorOuterRotation = { x: 0, y: 0, z: rotC };
      },
      updateCameraPosition(newZ) {
          this.cameraPosition.z = newZ; // Update the cameraPosition data property
          const camera = this.$refs.camera;
          if (camera) {
              camera.setAttribute('position', `0 0 ${newZ}`);
          }
      },
      generateStars() {
          const numStars = 0;

          for (let i = 0; i < numStars; i++) {
              const position = `${this.getRandomPosition()} ${this.getRandomPosition()} ${this.getRandomPosition()}`;
              const radius = Math.random() * 0.005;

              this.stars.push({
                  position,
                  radius,
              });
          }
      },
      updateCoordinates(intersectionData) {
          this.latInput = intersectionData.lat;
          this.lonInput = intersectionData.lon;
      },
      getRandomPosition() {
          return (Math.random() - 0.5) * 50;
      },
      screenshot() {
          document.querySelector('a-scene').components.screenshot.capture('equirectangular');
      },

  },
  computed: {
      ...mapState({
          address: state => state.settings.address,
          tokenContract: state => state.settings.tokenContract,
          balance: state => state.settings.balance,
          tokenBalance: state => state.settings.tokenBalance,
          totalSupply: state => state.settings.totalSupply,
          maxSupply: state => state.settings.maxSupply,
          pricePerEth: state => state.settings.pricePerEth,
      }),
      prettyAddress() {
          if (this.address) return this.address.substr(0, 6) + '...' + this.address.substr(-4);
          return '';
      },
      prettyPricePerEth() {
          return this.pricePerEth.toString();
      },
      prettyPriceSimple() {
          return this.pricePerEth.toInt();
      },
      totalValue() {
          return ethers.utils.parseEther(this.value.toFixed(18)).div(this.pricePerEth)
      },
  },
  watch: {
      look(newVal, oldVal) {
          const cameraEl = this.$refs.camera;

          if (newVal !== oldVal) {
              if (newVal) {
                  // Enable look-controls
                  cameraEl.setAttribute('look-controls', 'enabled', true);
              } else {
                  // Disable look-controls
                  cameraEl.setAttribute('look-controls', 'enabled', false);
              }
          }
      },


      'cameraPosition.z': {
          immediate: true, // Trigger the handler immediately
          handler(newZ) {
              // Update the camera's position
              this.updateCameraPosition(newZ);
          },
      },

  },
  components: { "vue3-slider": slider, ArcEntity, ArcEntityDeg },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Oswald&family=Space+Mono:wght@400;700&display=swap');
@font-face {
  font-family: 'uno';
  src: url(../assets/unoestado.ttf);
}

.debug-wrapper {
  padding: 20px;
  width: 230px;
  user-select: none;
}

.menuHidden {
  pointer-events: none;
  opacity: 0;
  transition: all 0.5s ease;
}

.raycastable {
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

* {
  line-height: 1;
}

.row {
  margin-top: -8px;
  border-bottom: 1px solid #e7b648;
  margin-left: -16px;
  margin-right: -16px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  p {
      font-size: 0.75rem;
  }
  input {
      font-size: 0.75rem !important;
      display: flex;
      justify-content: space-between;
      border-radius: 0px !important;
      margin-left: 8px;
      &:nth-child(3) {
          margin-left: 0px;
          margin-right: 8px;
          border-left: 1px solid #e7b648;
          text-align: right;
      }
  }
}

.a-loader-title {
  display: none;
}

.mainUI {
  border: 0px solid pink;
  position: fixed;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
  /* allow click-through in transparent areas */
  display: flex;
  align-items: center;
  justify-content: center;
}

.regionUI {
  border: 0px solid yellow;
  position: absolute;
  display: flex;
  flex-direction: row;
  pointer-events: none;
}

.buttonUI {
  z-index: 1000;
  border: 0px solid lime;
  display: flex;
  margin-top: 50vh;
  flex-direction: column;
  justify-content: center;
  pointer-events: auto;
  cursor: pointer;
  filter: drop-shadow(0px 0px 20px white);
}

.smallButton {
  font-size: 0.75rem;
  margin: unset;
  border: 1px solid #e7b648;
  padding: 0rem 0.25rem;
  right: 0px;
  position: absolute;
  color: black;
  background: #e7b648;
  border-radius: 2px;
  margin-top: 0.2rem;
  &:hover {
      color: black;
      background: #ee9900;
  }
}

.buttonrow {
  backdrop-filter: blur(10px);
  display: flex;
  flex-flow: row;
  width: 228px;
  overflow: hidden;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border: 1px solid #e7b648;
  div {
      background: #e7b648;
      width: 20px;
      height: 20px;
      display: flex;
      flex-flow: row;
      justify-content: center;
      border-left: 1px solid #00000022;
      transition: all 0-.2s ease;
      &:hover {
          background: #ee9900;
      }
      &:nth-child(1) {
          border: unset;
      }
  }
}

.debug-controls {
  position: fixed;
  right: 20px;
  top: 20px;
  z-index: 5;
  height: 52px;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  width: 52px;
  .debug-wrapper {
      filter: blur(10px);
      transition: all 0.2s ease;
  }
}

.menuOpen {
  width: auto;
  right: 0px;
  top: 10px;
  height: auto;
  .debug-wrapper {
      filter: blur(0px);
      transition: all 0.02s ease;
  }
}

.close {
  z-index: 2000;
  color: rgb(0, 0, 0);
  height: 20px;
  transition: all 0.2s ease;
  width: 20px;
  border-radius: 20px;
  position: relative;
  margin-left: auto;
  margin-right: 18px;
  margin-top: 8px;
  margin-bottom: -42px;
  right: 0px;
  font-family: "Space Mono", monospace;
  text-align: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  bottom: unset;
  background: #e7b648;
  border: 1px solid #e7b648;
  &:after {
      content: '+';
      transform: rotate(45deg);
      font-weight: bold;
      margin-top: -0.5px;
      margin-right: -0.5px;
  }
}

@keyframes pulse {
  0% {
      background: transparent !important;
  }
  50% {
      background: #e7b648 !important;
  }
  0% {
      background: transparent !important;
  }
}

.menuClose {
  border-radius: 30px;
  height: 50px;
  width: 50px;
  position: absolute !important;
  margin: unset !important;
  bottom: 0px !important;
  animation: pulse 2s ease infinite;
  color: rgb(255, 255, 255) !important;
  background: transparent !important;
  backdrop-filter: blur(10px);
  bottom: 0px !important;
  width: 50px;
  &:after {
      content: '+' !important;
      font-weight: bold;
      transform: rotate(720deg) !important;
      margin-top: -2px !important;
      font-size: 2rem;
  }
}

.a-loader-title {
  display: none !important;
}

.demo-desc {
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  position: fixed;
  font-family: 'Space Mono', monospace;
  display: flex;
  flex-flow: column;
  justify-content: center;
  backdrop-filter: blur(10px)brightness(0.5);
  .infobox {
      p {
          user-select: none;
          margin-top: unset;
      }
      .button {
          cursor: pointer;
          user-select: none;
          padding: 1rem;
          background: white;
          color: rgb(109, 112, 126);
          &:hover {
              opacity: 0.5;
          }
      }
      max-width: calc(100vw - 4rem);
      position: relative;
      margin: auto;
      width: 600px;
      z-index: 1000;
      background: black;
      color: white;
      padding: 2rem;
      a {
          color: white;
      }
  }
}

#buttonEnter {
  transition: all 0.5s ease;
  opacity: 0.2;
  &:hover {
      opacity: 1;
  }
}

.marker-tooltip {
  position: fixed;
  top: 10px;
  left: 10px;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  h2 {
      margin: 0 0 5px;
      font-size: 18px;
      color: white;
  }
  p {
      margin: 0;
      color: white;
  }
  a {
      display: block;
      margin-top: 5px;
  }
}

h1 {}

p {
  font-family: 'Space Mono', monospace;
}

.a-dialog-text {
  font-family: 'Space Mono', monospace !important;
  color: black !important;
}

.a-dialog-allow-button {
  font-family: 'Space Mono', monospace !important;
  color: black !important;
  background-color: white !important;
}

.a-dialog-button {
  font-family: 'Space Mono', monospace !important;
  color: black !important;
  border: 1px solid black !important;
}

.a-dialog-deny-button {
  font-family: 'Space Mono', monospace !important;
  color: white !important;
  background-color: black !important;
}

.segmentbutton {
  border: 0;
  bottom: 20px;
  cursor: pointer;
  min-width: 100px;
  background: white;
  display: none;
  flex-flow: column;
  justify-content: center;
  &:after {
      font-family: 'Space Mono', monospace;
      content: 'FILTER';
      color: black;
      position: absolute;
      text-align: center;
      width: 100%;
  }
  min-height: 34px;
  padding-right: 0;
  padding-top: 0;
  position: fixed;
  left: 20px;
  z-index: 9999;
  &:hover {
      filter: invert(1);
  }
}

.screenshot {
  border: 0;
  bottom: 20px;
  cursor: pointer;
  min-width: 120px;
  background: white;
  display: none;
  flex-flow: column;
  justify-content: center;
  &:after {
      font-family: 'Space Mono', monospace;
      content: 'SCREENSHOT';
      color: black;
      position: absolute;
      text-align: center;
      width: 100%;
  }
  min-height: 34px;
  padding-right: 0;
  padding-top: 0;
  position: fixed;
  left: 140px;
  z-index: 9999;
  &:hover {
      filter: invert(1);
  }
}

.perspective {
  perspective: 600px;
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0px;
  top: 0px;
  display: flex;
  flex-flow: column;
  z-index: -1;
  justify-content: center;
  .dot-grid {
      &:nth-child(1) {
          opacity: 0.75;
          .dot {
              transition: all 0.25s ease;
          }
      }
      &:nth-child(2) {
          opacity: 0.5;
          .dot {
              transition: all 0.5s ease;
          }
          transform: translateZ(-25px)
      }
      &:nth-child(3) {
          opacity: 0.25;
          .dot {
              transition: all 0.75s ease;
          }
          transform: translateZ(-50px)
      }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.apparea {
  background: black;
  color: white;
  height: 100vh;
  top: 0px;
  position: fixed;
  width: 100vw;
  display: flex;
  flex-flow: column;
  justify-content: center;
  .centered {
      width: 1024px;
      max-width: calc(100% - 4rem);
      display: flex;
      flex-flow: column;
      margin: auto;
      justify-content: center;
      .typer {
          margin: auto;
          padding-left: 0px;
      }
      h1 {
          margin: auto 0px;
          margin-right: 1rem;
          user-select: none;
          font-size: 2.9rem;
          padding-bottom: 0.4rem;
          width: fit-content;
          font-family: 'uno';
      }
      .line {
          margin-right: 1rem;
          border-radius: 4px;
          height: 2rem;
          margin: auto;
          background: white;
          width: 4rem;
          margin-right: 1rem;
          position: absolute;
          &::before {
              position: absolute;
              content: '';
              margin-right: 1rem;
              border-radius: 4px;
              height: 5rem;
              margin: auto;
              top: -5.5rem;
              background: white;
              width: 4rem;
              margin-right: 1rem;
          }
          &::after {
              position: absolute;
              content: '';
              margin-right: 1rem;
              border-radius: 4px;
              height: 3rem;
              margin: auto;
              bottom: -3.5rem;
              background: white;
              width: 4rem;
              margin-right: 1rem;
          }
      }
      .circ {
          position: absolute;
          width: 512px;
          left: calc(50% - 256px);
          transform: rotate(-90deg);
      }
  }
}

.socials {
  display: flex;
  justify-content: center;
  margin: 0;
  position: fixed;
  top: 20px;
  left: 20px;
  height: auto;
  min-height: 34px;
  gap: 0.5rem;
  padding: 0px 10px;
  background-color: white;
  z-index: 10;
  a {
      display: flex;
      flex-flow: column;
      justify-content: center;
      position: relative;
      transition: all 0.5s ease;
      transform-origin: 50% 50%;
      &:hover {
          transform: scale(1.2);
      }
  }
  img {
      height: 20px;
      transition: all 0.5s ease;
      &:nth-child(2) {
          position: absolute; //filter: blur(2px)brightness(2);
      }
  }
}

.rs-base {
  border: 1px solid #e7b648 !important;
  font-family: 'Space Mono', monospace !important;
  background: unset !important;
  backdrop-filter: blur(10px)brightness(0.5);
  border-radius: 4px !important;
  transform: scale(0.75);
  transform-origin: top left;
}

.rs-counter-base {}

.alarm {
  filter: hue-rotate(70deg)brightness(3.5) !important;
  text-shadow: unset !important;
}

@media screen and (max-width:100vh) {
  .mainUI {
      display: none !important;
  }
  .rs-base {
      transform-origin: top left;
      transform: scale(0.3);
      position: fixed !important;
      left: 20px !important;
      backdrop-filter: blur(10px);
      background: unset !important;
  }
  .debug-controls {
      transform: scale(1);
      transform-origin: top right;
      bottom: unset !important;
      top: 20px;
  }
  .menuOpen {
      bottom: unset !important;
      top: 10px !important;
  }
}
</style>