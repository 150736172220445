<template>
    <router-view/>
</template>

<script>

export default {
    mounted() {
        let EffectComposerBase = document.createElement('script')
        EffectComposerBase.setAttribute('src', 'EffectComposerBase.js')
        document.head.appendChild(EffectComposerBase)
        console.log('loaded EffectComposerBase')


        let DigitalGlitch = document.createElement('script')
        DigitalGlitch.setAttribute('src', 'DigitalGlitch.js')
        document.head.appendChild(DigitalGlitch)
        console.log('loaded DigitalGlitch')


        let DOFMipMapShader = document.createElement('script')
        DOFMipMapShader.setAttribute('src', 'DOFMipMapShader.js')
        document.head.appendChild(DOFMipMapShader)
        console.log('loaded DOFMipMapShader')


        let FilmShader = document.createElement('script')
        FilmShader.setAttribute('src', 'FilmShader.js')
        document.head.appendChild(FilmShader)
        console.log('loaded FilmShader')


        let OutputShader = document.createElement('script')
        OutputShader.setAttribute('src', 'OutputShader.js')
        document.head.appendChild(OutputShader)
        console.log('loaded OutputShader')


        let DepthLimitedBlurShader = document.createElement('script')
        DepthLimitedBlurShader.setAttribute('src', 'DepthLimitedBlurShader.js')
        document.head.appendChild(DepthLimitedBlurShader)
        console.log('loaded DepthLimitedBlurShader')


        let ConvolutionShader = document.createElement('script')
        ConvolutionShader.setAttribute('src', 'ConvolutionShader.js')
        document.head.appendChild(ConvolutionShader)
        console.log('loaded ConvolutionShader')


        let BloomPass = document.createElement('script')
        BloomPass.setAttribute('src', 'BloomPass.js')
        document.head.appendChild(BloomPass)
        console.log('loaded BloomPass')


        let VerticalTiltShiftShader = document.createElement('script')
        VerticalTiltShiftShader.setAttribute('src', 'VerticalTiltShiftShader.js')
        document.head.appendChild(VerticalTiltShiftShader)
        console.log('loaded VerticalTiltShiftShader')


        let LensDistortionShader = document.createElement('script')
        LensDistortionShader.setAttribute('src', 'LensDistortionShader.js')
        document.head.appendChild(LensDistortionShader)
        console.log('loaded LensDistortionShader')


        let FXAAShader = document.createElement('script')
        FXAAShader.setAttribute('src', 'FXAAShader.js')
        document.head.appendChild(FXAAShader)
        console.log('loaded FXAAShader')


        let GlitchPass = document.createElement('script')
        GlitchPass.setAttribute('src', 'GlitchPass.js')
        document.head.appendChild(GlitchPass)
        console.log('loaded GlitchPass')


        let RenderPass = document.createElement('script')
        RenderPass.setAttribute('src', 'RenderPass.js')
        document.head.appendChild(RenderPass)

        console.log('loaded RenderPass')

        let satellite = document.createElement('script')
        satellite.setAttribute('src', 'satellite.min.js')
        document.head.appendChild(satellite)
        console.log('loaded satellite')


        let effect = document.createElement('script')
        effect.setAttribute('src', 'https://unpkg.com/aframe-web-portals@1.0.1/dist/aframe-web-portals.umd.js')
        document.head.appendChild(effect)

if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
    // The browser is Firefox
    console.log("The user is using Firefox.");
} else {
    // The browser is not Firefox
    console.log("The user is not using Firefox.");
        if (this.isDesktop()) {
         //Load your script here
        this.loadScript();
        }
}
    },
    methods: {
        isDesktop() {
            // Use a media query to check screen width
            return window.matchMedia("(min-width: 1024px)").matches;
        },
        isVertical() {
            // Use a media query to check screen width
            return window.matchMedia("(min-width: 100vh)").matches;
        },
        loadScript() {
            // Load your script dynamically
            const script = document.createElement('script');
            script.src = 'effect.js';
            script.async = true;
            script.onload = () => {
                console.log('loaded effects')
            };


            // Append the script to the document's body
            document.body.appendChild(script);
        },
    },

}
</script>

<style>
body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

#app {}

#nav {
    padding: 30px;
}

#nav a {}

#nav a.router-link-exact-active {}
</style>
