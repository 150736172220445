<template>
    <a-scene stats background="color: #001">
        <a-entity :rotation="rotation">
            <a-camera :position="cameraPosition" look-controls="pointerLockEnabled: false" wasd-controls="fly: true; acceleration: 50;"></a-camera>
        </a-entity>
    
        <a-entity @click="handleRaycasterEnter">
            <!--<a-icosahedron class="collidable" :radius="1 * this.earthRadius" detail="100" color="#ffffff" blending="additive" opacity="0.05" wireframe="true"></a-icosahedron>-->
    
            <a-light light="type: directional; angle: 180; castShadow:true;" target="#earth" position="0 0 4000" intensity="2" color="#ffeedd"></a-light>
            <a-light light="type: directional; angle: 180; castShadow:true;" target="#earth" position="0 0 -1000" intensity="2" color="#ddeeff"></a-light>
    
            <a-entity id="globe" scale=".6371 .6372 .6371" globe=" show-graticules: true;
                                        globe-image-url: 8k_earth_nightmap.jpg; atmosphere-altitude: 0.05;
                                        bump-image-url: //unpkg.com/three-globe/example/img/earth-topology.png;
                                      "></a-entity>
        </a-entity>
        <a-entity :rotation="rotation">
    
        </a-entity>
    
    </a-scene>
    
    <h1>{{ rotation }}</h1>
</template>

<script>
import 'aframe'
import 'aframe-extras'
import 'aframe-globe-component' // Import aframe-effects
import * as THREE from "three";


export default {
    name: "app",
    data() {
        return {
            earthRadius: 63.71,
            cameraPosition: { x: 0, y: 0, z: 69.47 },
            rotation: '0 0 0', // Initial rotation
            timeScale: -1,
        };
    },
    mounted() {
        // Gen random data
        const N = 300;
        const gData = [...Array(N).keys()].map(() => ({
            lat: (Math.random() - 0.5) * 180,
            lng: (Math.random() - 0.5) * 360,
            alt: Math.random() * 0.8 + 0.1,
            radius: Math.random() * 0.1,
            color: ['#ffaa55', '#ffff00', 'orange', '#ff5500'][Math.round(Math.random() * 3)]
        }));

        const globeEntity = document.getElementById('globe');
        globeEntity.setAttribute('globe', {
            customLayerData: gData,
            customThreeObject: d => new THREE.Mesh(
                new THREE.SphereGeometry(d.radius),
                new THREE.MeshLambertMaterial({ color: d.color })
            ),
            customThreeObjectUpdate: (obj, d) => {
                Object.assign(obj.position, globeEntity.components.globe.getCoords(d.lat, d.lng, d.alt));
            }
        });

        (function moveSpheres() {
            gData.forEach(d => d.lat += 0.2);
            globeEntity.setAttribute('globe', 'customLayerData', gData);
            requestAnimationFrame(moveSpheres);
        })();
        this.updateRotation(); // Initial update
        setInterval(this.updateRotation, 10); // Update rotation every 10 milliseconds
    },
    methods: {
        updateRotation() {
            this.timeScaleAdapted = this.timeScale;

            if (this.timeScale <= 1) {
                this.timeScaleAdapted = this.timeScale + 1;
            }
            const currentTime = Math.floor(Date.now() / 10); // Current Unix time in milliseconds


            const rotationPeriod = 90 * 60 * 100; // 90 minutes in milliseconds (10000)
            const rotationAngle = (-360 * (currentTime % rotationPeriod)) / rotationPeriod * this.timeScaleAdapted / 10;
            this.rotation = `0 ${rotationAngle} 0`;
        },

    },
    computed: {},
    watch: {

    },
    components: {},
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Oswald&family=Space+Mono:wght@400;700&display=swap');
@font-face {
    font-family: 'uno';
    src: url(../assets/unoestado.ttf);
}

@media screen and (max-width:100vh) {}
</style>