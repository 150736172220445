import { defineStore } from "pinia";
import { ethers } from "ethers";
import { addresses } from "./addresses";

var provider;

export const useWalletStore = defineStore({
  id: "useWalletStore",
  state: () => ({
    address: "",
    addresses: addresses[1],
    balance: ethers.utils.parseEther("0"),
    last_blockheight: 0,
    loading: false,
    chainId: 0,
    txn_nonce: 0,
    logged_in: false,
    unsupported_network: false,
    transactions: {},
    errors: {
      "4001": "User rejected transaction",
      "4100":
        "The requested method and/or account has not been authorized by the user.",
      "4200": "The Provider does not support the requested method.",
      "4900": "	The Provider is disconnected from all chains.",
      "4901": "The Provider is not connected to the requested chain.",
      "-32000": "Insufficent Ethereum for Transaction",
      "-32001": "Requested resource not found",
      "-32002": "Requested resource not available",
      "-32003": "Transaction creation failed",
      "-32004": "Method is not implemented on contract",
      "-32005": "Request exceeds defined limit",
      "-32006": "Version of JSON-RPC protocol is not supported",
      "-32015": "Transaction underpriced",
      "-32016": "Transaction nonce too low",
      "-32600": "Invalid request",
      "-32601": "Method not found",
      "-32602": "Invalid params",
      "-32603": "Internal error",
      "-32700": "Parse error",
    },
  }),
  getters: {
    connectAddress(state) {
      if (state.address == "" || state.address == undefined) return "Connect";

      return state.address.slice(0, 7) + "..." + state.address.slice(-5);
    },
    provider() {
      return provider;
    },
  },
  actions: {

    async connectWallet() {
      const { ethereum } = window;
      if (ethereum && this.address == "" && this.loading === false) {
        try {
          await ethereum.request({ method: "eth_requestAccounts" });
        } catch (error) {
          console.log(error);
        }
        const accounts = await ethereum.request({ method: "eth_accounts" });
        provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const chainId = await signer.getChainId();
        this.address = ethers.utils.getAddress(accounts[0]);
        this.balance = await signer.getBalance();
        this.chainId = chainId;
        this.txn_nonce = await signer.getTransactionCount();
        this.onNetworkChange(chainId.toString());
        ethereum.on("accountsChanged", (accounts) =>
          this.onAccountChange(accounts)
        );
        ethereum.on("chainChanged", (chainId) =>
          this.onNetworkChange(chainId)
        );
        this.logged_in = true;
      }
    },
    // Network Events
    async onAccountChange(accounts) {
      console.log("Account Changed", accounts);
      this.address = accounts[0];
      this.balance = ethers.utils.parseEther("0");
      this.last_blockheight = 0;
      if (accounts.length == 0) {
        await this.connectWallet();
      }
    },

    async onNetworkChange(chainId) {
      console.log("ChainChanged");
      this.addresses = addresses[parseInt(chainId)];
      this.unsupported_network = false;
      if (this.addresses == undefined) this.unsupported_network = true;
    },

    async changeChains(chainIdString) {
      const chainId = ethers.utils.hexValue(parseInt(chainIdString));
      const provider = this.provider;
      const success = provider.send("wallet_switchEthereumChain", [
        { chainId },
      ]);
      return success == null ? true : false;
    },

    async signMessage(message) {
      const provider = this.provider;
      const signer = provider.getSigner();
      return await signer.signMessage(message);
    },

    // Methods
    async updateBalance() {
      const provider = this.provider;
      const signer = provider.getSigner();
      this.balance = await signer.getBalance();
    },

    async resolveENSName(name){
        const provider = this.provider;
        const resolver = await provider.getResolver(name)
        if(!resolver) return;
        const contentHash = await resolver.getContentHash();
        console.log(contentHash)
        return contentHash
    },

    contractMethod(
      address,
      method,
      args = [],
      returns = [],
      signer = false,
      ether = null
    ) {
      function buildInputs(inputs) {
        const input_as_abi = [];
        for (let i = 0; i < inputs.length; i++) {
          let type = "";
          if (inputs[i].startsWith("0x")) {
            type = "address";
          } else {
            switch (typeof inputs[i]) {
              case "string":
                type = "string";
                break;
              case "number":
                type = "uint256";
                break;
              case "bigint":
                type = "uint256";
                break;
              case "boolean":
                type = "bool";
                break;
              default:
                type = "address";
                break;
            }
          }
          input_as_abi.push({
            internalType: "" + type,
            name: "",
            type: "" + type,
          } );
        }
        return input_as_abi;
      }
      //dev must be aware of solidity datatypes.
      function buildOutputs(outputs) {
        const output_as_abi = [];
        for (let i = 0; i < outputs.length; i++) {
          output_as_abi.push({
            internalType: "" + outputs[i],
            name: "",
            type: "" + outputs[i],
          } );
        }
        return output_as_abi;
      }
      const abi = {
        inputs: buildInputs(args),
        name: method,
        outputs: buildOutputs(returns),
        stateMutability: "view",
        type: "function",
      };
      const provider = this.provider;
      let contract;

      if (signer) {
        abi.stateMutability = "nonpayable";
        const signer = provider.getSigner();
        if (ether) abi.stateMutability = "payable";
        contract = new ethers.Contract(address, [abi], signer);
      } else contract = new ethers.Contract(address, [abi], provider);
        //more detailed print to console.
        
        return contract[method](...args, { value: ether })
    },
    contractOn(address, abi, event, callback) {
      const provider = this.provider;
      const contract = new ethers.Contract(address, abi, provider);
      contract.on(event, callback);
    },

    async sendEther(to, amount) {
        const provider = this.provider;
        const signer = provider.getSigner();
        const txn = {
            to: to,
            value: ethers.utils.parseEther(amount),
        }
        const transaction = await signer.sendTransaction(txn)
        return transaction
    }
}
});
