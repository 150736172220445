<template>
    <!-- Icosahedral Geometric Sphere (Globe) -->
    <a-entity :rotation="rotation" collider-check id="globe" ref="globe">
    
    
        <a-sky rotation="0 0 45" color="#ffffff" radius="250" src="#stars"></a-sky>
    
        <a-entity v-if="isDesktop()">
    
            <a-sphere v-if="efficient == true" segments-width="100" segments-height="100" shadow="receive: true" id="globe2" ref="globe2" scale="0.6371 0.6371 0.6371" rotation="0 -90 0" color="#aaaaaa" material="shader: dayNightGlobe; dayTexture: #earthDayMap; nightTexture: #earthNightMap; lightDirection: {{ lightDirection }}"
                globe="show-graticules: false; atmosphere-color: #aaeeff; globe-image-url:8081_earthmap10k.jpg; atmosphere-color: #aaeeff; atmosphere-altitude: 0.0; show-atmosphere: false; bump-image-url:EARTH_DISPLACE_21K_16BITS.jpg;">
            </a-sphere>
    
            <!--<a-sphere v-if="efficient == true"  segments-width="100" segments-height="100" shadow="receive: true" id="globe2" ref="globe2" scale="0.6371 0.6371 0.6371" rotation="0 -90 0" color="#aaaaaa" globe="show-graticules: false; globe-image-url:8081_earthmap10k.jpg; atmosphere-color: #aaeeff; atmosphere-altitude: 0.0; show-atmosphere: false; bump-image-url:EARTH_DISPLACE_21K_16BITS.jpg;"></a-sphere>
    -->
    
    <a-sphere v-if="efficient == true" data-no-raycast animation="property: rotation; to: 0 360 0; easing: linear; loop: true; dur: 90000000" segments-width="100" segments-height="100" style="pointer-events:none;" :radius="1.001 * this.earthRadius" opacity="0.3"
                detail="100" material="toneMapped: false; reflectivity: 0.9; shininess: 30;
                       src: url(8k_earth_clouds.jpg); transparent: false;alphaTest: 0.05;
                       alphaMap: url(8k_earth_clouds_alpha.jpg);">
            </a-sphere>
    
    <a-sphere v-if="efficient == true" data-no-raycast animation="property: rotation; to: 0 360 0; easing: linear; loop: true; dur: 90000000" segments-width="100" segments-height="100" style="pointer-events:none;" :radius="1.001 * this.earthRadius" opacity="0.3"
        detail="100" material="toneMapped: false; reflectivity: 0.9; shininess: 30;
               src: url(8k_earth_clouds.jpg); transparent: false;alphaTest: 0.05;
               alphaMap: url(8k_earth_clouds_alpha.jpg);">
    </a-sphere>
    
    <a-sphere v-if="efficient == true" data-no-raycast animation="property: rotation; to: 0 360 0; easing: linear; loop: true; dur: 90000000" segments-width="100" segments-height="100" style="pointer-events:none;" :radius="1.001 * this.earthRadius" opacity="0.3"
        detail="100" material="toneMapped: false; reflectivity: 0.9; shininess: 30;
               src: url(8k_earth_clouds.jpg); transparent: false;alphaTest: 0.05;
               alphaMap: url(8k_earth_clouds_alpha.jpg);">
    </a-sphere>
    
    <a-sphere v-if="efficient == true" data-no-raycast animation="property: rotation; to: 0 360 0; easing: linear; loop: true; dur: 90000000" segments-width="100" segments-height="100" style="pointer-events:none;" :radius="1.001 * this.earthRadius" opacity="0.3"
        detail="100" material="toneMapped: false; reflectivity: 0.9; shininess: 30;
               src: url(8k_earth_clouds.jpg); transparent: false;alphaTest: 0.05;
               alphaMap: url(8k_earth_clouds_alpha.jpg);">
    </a-sphere>
    
    
            <a-sphere v-if="efficient == true" @click="handleRaycasterEnter" material="shader: atmosphere2; color: #55aaff; coeficient: 0.9; power: 1; glowSize: 0.9; glowIntensity: 1.5; lightDirection: 0 0 -1" segments-width="100" segments-height="100" style="pointer-events:none;"
                blending="additive" :radius="1.002 * this.earthRadius" opacity="0.5" detail="100" rotation="0 180 0"></a-sphere>
    
    
    
    
    
            <a-sphere v-if="efficient == false" segments-width="100" segments-height="100" shadow="receive: true" id="globe2" ref="globe2" scale="0.6371 0.6371 0.6371" rotation="0 -90 0" color="#e7b648" globe=" show-graticules: false; globe-image-url:earth-topo-bump.png; atmosphere-color: #aaeeff; atmosphere-altitude: 0.0; show-atmosphere: false; bump-image-url:earth-topo-bump.png;">
            </a-sphere>
    
            <a-entity v-if="efficient == false" icosahedron-vertices-generator="radius: 64"></a-entity>
            <a-icosahedron @click="handleRaycasterEnter" class="collidable" v-if="efficient == false" :radius="1.002 * this.earthRadius" detail="18" color="#ffffff" opacity="0.05" wireframe="true"></a-icosahedron>
    
            <a-sphere material="shader: atmosphere2; color: #e7b648; coeficient: 0.9; power: 1; glowSize: 0.9; glowIntensity: 2; lightDirection: 0 0 -1 " v-if="efficient == false" data-no-raycast animation="property: rotation; to: 0 360 0; easing: linear; loop: true; dur: 90000000"
                segments-width="100" segments-height="100" style="pointer-events:none;" blending="additive" :radius="1.001 * this.earthRadius" opacity="0.5" detail="100" rotation="0 180 0">
            </a-sphere>
    
    
    
    
        </a-entity>
        <a-entity v-if="isMobile()">
    
            <a-sphere v-if="efficient == true" segments-width="100" segments-height="100" shadow="receive: true" id="globe2" ref="globe2" scale="0.6371 0.6371 0.6371" rotation="0 -90 0" color="#aaeeff" globe=" show-graticules: false; globe-image-url:8k_earth_daymap.jpg; atmosphere-color: #aaeeff; show-atmosphere: false; atmosphere-altitude: 0.08;bump-image-url: earth-topo-bump.png;">
            </a-sphere>
    
            <a-sphere v-if="efficient == true" detail="150" segments-width="100" segments-height="100" :radius="1.0005 * this.earthRadius" blending="additive" material="shader: atmosphere2; color: #55aaff; coeficient: 0.5; power: 2; glowSize: 0.3; glowIntensity: 0.9; ">
            </a-sphere>
    
    
            <a-sphere @click="handleRaycasterEnter" v-if="efficient == true" data-no-raycast animation="property: rotation; to: 0 360 0; easing: linear; loop: true; dur: 90000000" segments-width="100" segments-height="100" style="pointer-events:none;" blending="additive"
                :radius="1.002 * this.earthRadius" opacity="1" detail="150" rotation="0 180 0" material="tone-mapped: false; reflectivity: 0.9; shininess: 30;" src="8k_earth_clouds.jpg">
    
            </a-sphere>
    
    
            <a-sphere data-no-raycast animation="" segments-width="150" segments-height="150" v-if="efficient == false" style="pointer-events:none;" :radius="0.995 * this.earthRadius" opacity="0.5" detail="100" rotation="0 180 0" color="#e7b648" material="tone-mapped: true; "
                wireframe="false" src="8081_earthspec10k.jpg">
            </a-sphere>
    
            <a-sphere @click="handleRaycasterEnter" globe="atmosphere-color: #ffffff;show-atmosphere: false; atmosphere-altitude:  0.0; " v-if="efficient == false" class="collidable" :radius="1.05 * this.earthRadius" detail="20" color="#e7b648" opacity="0.5" segments-width="50"
                segments-height="50" id="globe2" ref="globe2" wireframe="true">
    
            </a-sphere>
        </a-entity>
    
        <a-entity rotation="0 180 0">
        </a-entity>
    
        <a-entity id="lights" day-night-cycle="timeOffset: 12">
            <a-light id="sun" light="type: directional; angle: 160; castShadow:true;" target="#globe2" position="0 0 1000" intensity="3" color="#ffeedd"></a-light>
            <a-light id="moon" light="type: directional; angle: 160; castShadow:true;" target="#globe2" position="0 0 -1000" intensity="0.2" color="#ddeeff"></a-light>
    
        </a-entity>
    
        <a-sphere :position="cursorPos" ref="cursor" :radius="0.003 * this.earthRadius" detail="5" wireframe="true" opacity="0.5" color="white" segments-width="2" animation="property: rotation; to: 720 720 360; easing: easeInOutQuad; loop: true;dir:alternate; dur: 1000; elasticity: 100;"
            segments-height="2" class="interactive raycastable collidable">
            <a-light type="point" intensity="0.05" animation="property: intensity; to: 1.2; easing: easeInQuad; loop: true;dir:alternate; dur: 250; elasticity: 100;" color="#ffffff"></a-light>
    
        </a-sphere>
    
        <!-- Loop through marker positions -->
        <a-entity v-for="(position, index) in markerPositions" :key="index" class="raycastable collidable" data-raycastable>
    
            <a-entity @mouseover="updateActivePosition(index)" @mousedown="updateActivePosition(index)" @click="updateActivePosition(index)" @mouseenter="updateHoveredPosition(index)" @mouseleave="clearHoveredPosition(index)" :position="getPosition(position)" class="raycastable collidable">
    
                <a-entity>
                    <a-entity opacity="0.2" :line="sphereHoveredStates[index] ? {
                                                                                    start: getLineMidpoint(position, 0.03),
                                                                                    end: '0 0 0',
                                                                                    color: '#e7b648'
                                                                                } : {
                                                                                    start: getLineMidpoint(position, 0.03),
                                                                                    end: '0 0 0',
                                                                                    color: 'white'
                                                                                }">
                        <a-entity :position="getLineMidpoint(position, 0.03)" label-anchor="offsetVector: 0 0 0">
    
                            <a-entity face-camera width="2" height="1" position="0 0 0" type="box" look-at="#camera" color="#000000" label>
    
                            </a-entity>
    
                            <a-entity :id="'markerTop' + index" :position="getLineMidpoint(position, 0)" :radius="0.00025 * this.earthRadius" detail="10" wireframe="false" :color="sphereHoveredStates[index] ? 'white' : '#e7b648'" class="interactive raycastable collidable">
    
                            </a-entity>
    
                        </a-entity>
                    </a-entity>
                </a-entity>
            </a-entity>
        </a-entity>
    
    
        <!-- Line from globe center to mouse/VR position -->
        <a-entity v-if="rayStart && rayEnd" :line="lineProps"></a-entity>
    
        <!-- Text to display hover info -->
        <a-text :value="textValue" :position="getTextPosition()" color="white" align="center"></a-text>
    
        <!-- Visual ray representation using a cylinder -->
        <a-cylinder v-if="rayStart && rayEnd" :position="rayStart" :height="rayEnd.y - rayStart.y" radius="0.005" color="yellow"></a-cylinder>
    </a-entity>
    
    
    <!-- VR controller entities -->
    <a-entity ref="vrControllers">
        <!-- Add your VR controller entities here -->
    </a-entity>
    <a-light type="ambient" color="#888888" inensity="0.00125"></a-light>
    <p v-if="rayIntersectPoint">Intersection Point: {{ rayIntersectPoint.x.toFixed(2) }}, {{ rayIntersectPoint.y.toFixed(2) }}, {{ rayIntersectPoint.z.toFixed(2) }}</p>
</template>

<script>
import { Vector3, Vector2, Quaternion, Euler } from 'three';
import * as THREE from 'three';
import { throttle } from 'lodash'; // Importing throttle from lodash in ES6 style
export default {
    emits: ['globeIntersection', 'latitudeLongitude', 'titleDescLink', 'requestMarkerRemoval'], // Declare the custom event here
    props: {
        markerPositions: Array,
        timeScale: Number,
        muteStatus: Boolean,
        efficient: Boolean,
    },
    data() {
        return {

            muted: false,
            hovMult: 1,
            selectedLat: null,
            selectedLon: null,
            selectedTitle: null,
            selectedDesc: null,
            selectedLink: null,
            cursorPos: '0 0 0',
            rotation: '0 0 0', // Initial rotation
            hoveredPosition: -1, // Newly added to track hovered sphere index
            earthRadius: 63.71,
            rayIntersectPoint: null,
            rayStart: null, // Start point of visual ray
            rayEnd: null, // End point of visual ray
            sphereHoveredStates: [], // Array to track hovered state of each sphere
            sphereTitleStates: [], // Array to track hovered state of each sphere
        };
    },
    computed: { // You can compute canvasWidth based on your canvas element
        lightDirection() {
            const moonPosition = this.$refs.moon.object3D.position;
            const globePosition = this.$refs.globe2.object3D.position;
            const direction = moonPosition.clone().sub(globePosition).normalize();
            return `${direction.x} ${direction.y} ${direction.z}`;
        },
        canvasWidth() {
            const canvasElement = document.getElementById('your-canvas-id'); // Replace with your canvas element's ID
            return canvasElement ? canvasElement.width : 0;
        },
        lineProps() {
            if (this.rayEnd) {
                return {
                    start: this.rayStart,
                    end: this.rayEnd,
                    color: 'yellow',
                };
            }
            return {};
        },
        textValue() {
            if (this.rayEnd) {
                return `Intersection Lat: ${this.hoveredLatitude.toFixed(2)}, Lon: ${this.hoveredLongitude.toFixed(2)}`;
            }
            return '';
        },
    },
    mounted() {
        AFRAME.registerComponent('icosahedron-vertices-generator', {
            schema: {
                radius: { type: 'number', default: 1 },
                detail: { type: 'number', default: 8 } // Adjust detail for higher subdivision
            },

            init: function() {
                // Create an icosahedron geometry with the specified radius and detail
                const geometry = new THREE.IcosahedronGeometry(this.data.radius, this.data.detail);

                // Access vertex positions from the position attribute
                const positions = geometry.attributes.position.array;

                // Create an A-Frame entity for the dots and lines
                const dotsEntity = document.createElement('a-entity');
                const linesEntity = document.createElement('a-entity');

                // Function to create dots with a delay
                const createDotWithDelay = (i) => {
                    setTimeout(() => {
                        if (i < positions.length) {
                            const x = positions[i];
                            const y = positions[i + 1];
                            const z = positions[i + 2];

                            const positionKey = `${x.toFixed(3)}_${y.toFixed(3)}_${z.toFixed(3)}`;

                            // Check if a dot already exists at this vertex position
                            if (!usedVertices.includes(positionKey)) {
                                const dotEntity = document.createElement('a-sphere');
                                dotEntity.setAttribute('radius', 0.05); // Adjust the dot size as needed
                                dotEntity.setAttribute('color', '#ffffff'); // Adjust the dot color as needed
                                dotEntity.setAttribute('position', `${x} ${y} ${z}`);

                                dotsEntity.appendChild(dotEntity);

                                // Mark this vertex as used
                                usedVertices.push(positionKey);
                            }

                            // Create the next dot with a delay
                            createDotWithDelay(i + 3);
                        } else {
                            // After all dots are created, randomly draw lines between closest vertices
                            drawRandomLines();
                        }
                    }, 1); // Adjust the delay (in milliseconds) as needed
                };

                // Function to draw random lines between closest vertices
                const drawRandomLines = () => {
                    const vertices = geometry.vertices;
                    const lineCount = Math.min(vertices.length, 20); // Maximum number of lines

                    for (let i = 0; i < lineCount; i++) {
                        const vertexA = vertices[Math.floor(Math.random() * vertices.length)];
                        const vertexB = vertices[Math.floor(Math.random() * vertices.length)];

                        const lineEntity = document.createElement('a-entity');
                        lineEntity.setAttribute('line', {
                            start: `${vertexA.x} ${vertexA.y} ${vertexA.z}`,
                            end: `${vertexB.x} ${vertexB.y} ${vertexB.z}`,
                            color: '#ffffff' // Adjust line color as needed
                        });

                        linesEntity.appendChild(lineEntity);
                    }
                };

                // Array to keep track of used vertices
                const usedVertices = [];

                // Start creating dots with a delay
                createDotWithDelay(0);

                this.el.appendChild(dotsEntity);
                this.el.appendChild(linesEntity);
            }
        });

        AFRAME.registerShader('dayNightGlobe', {
            schema: {
                dayTexture: { type: 'map', is: 'uniform' },
                nightTexture: { type: 'map', is: 'uniform' },
                lightDirection: { type: 'vec3', is: 'uniform' }
            },
            vertexShader: `
        varying vec2 vUv;
        varying vec3 vNormal;
        void main() {
            vUv = uv;
            vNormal = normalize(normalMatrix * normal);
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
    `,
            fragmentShader: `
        uniform sampler2D dayTexture;
        uniform sampler2D nightTexture;
        uniform vec3 lightDirection;
        varying vec2 vUv;
        varying vec3 vNormal;
        void main() {
            float lightIntensity = dot(vNormal, lightDirection) * 0.5 + 0.5;
            vec4 dayColor = texture2D(dayTexture, vUv);
            vec4 nightColor = texture2D(nightTexture, vUv);
            gl_FragColor = mix(nightColor, dayColor, lightIntensity);
        }
    `
        });

        AFRAME.registerShader('atmosphere', {
            schema: {
                color: { type: 'color', is: 'uniform', default: 'pink' },
                coeficient: { type: 'number', is: 'uniform', default: 1.0 },
                power: { type: 'number', is: 'uniform', default: 2 }
            },

            vertexShader: `
    varying vec3 vVertexWorldPosition;
    varying vec3 vVertexNormal;
    void main() {
      vVertexNormal = normalize(normalMatrix * normal);
      vVertexWorldPosition = (modelMatrix * vec4(position, 1.0)).xyz;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `,

            fragmentShader: `
    uniform vec3 color;
    uniform float coeficient;
    uniform float power;
    varying vec3 vVertexNormal;
    varying vec3 vVertexWorldPosition;
    void main() {
      vec3 worldCameraToVertex = vVertexWorldPosition - cameraPosition;
      vec3 viewCameraToVertex = normalize((viewMatrix * vec4(worldCameraToVertex, 0.0)).xyz);
      float intensity = pow(coeficient + dot(vVertexNormal, viewCameraToVertex), power);
      gl_FragColor = vec4(color, intensity);
    }
  `
        });
        AFRAME.registerShader('atmosphere2', {
            schema: {
                color: { type: 'color', is: 'uniform', default: 'pink' },
                coeficient: { type: 'number', is: 'uniform', default: 1.0 },
                power: { type: 'number', is: 'uniform', default: 2 },
                glowSize: { type: 'number', is: 'uniform', default: 0.4 },
                glowIntensity: { type: 'number', is: 'uniform', default: 0.7 },
            },

            vertexShader: `
        varying vec3 vVertexWorldPosition;
        varying vec3 vVertexNormal;
        void main() {
            vVertexNormal = normalize(normalMatrix * normal);
            vVertexWorldPosition = (modelMatrix * vec4(position, 1.0)).xyz;
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
    `,

            fragmentShader: `
        uniform vec3 color;
        uniform float coeficient;
        uniform float power;
        uniform float glowSize;
        uniform float glowIntensity;
        varying vec3 vVertexNormal;
        varying vec3 vVertexWorldPosition;
        void main() {
            // Assume light is coming from the camera for simplicity
            vec3 lightDirection = normalize(cameraPosition - vVertexWorldPosition);
            float lightEffect = max(dot(vVertexNormal, lightDirection), 0.0);

            vec3 worldCameraToVertex = vVertexWorldPosition - cameraPosition;
            vec3 viewCameraToVertex = normalize((viewMatrix * vec4(worldCameraToVertex, 0.0)).xyz);
            float dotProduct = dot(vVertexNormal, viewCameraToVertex);
            float intensity = pow(coeficient + dotProduct, power) * lightEffect;
            
            // Adding the glow effect with smooth blending
            float glowEffect = smoothstep(-glowSize, glowSize, dotProduct);
            intensity = intensity * (1.0 - glowEffect) + glowIntensity * glowEffect;
            
            gl_FragColor = vec4(color * intensity, intensity);
        }
    `,
            side: THREE.BackSide,
            transparent: true,
            blending: THREE.AdditiveBlending,
        });

        AFRAME.registerShader('outerGlow', {
            schema: {
                color: { type: 'color', is: 'uniform', default: 'pink' },
                coeficient: { type: 'number', is: 'uniform', default: 0.5 }, // Adjusted
                power: { type: 'number', is: 'uniform', default: 2 }, // Adjusted
            },

            vertexShader: `
        varying vec3 vVertexWorldPosition;
        varying vec3 vVertexNormal;
        void main() {
            vVertexNormal = normalize(normalMatrix * normal);
            vVertexWorldPosition = (modelMatrix * vec4(position, 1.0)).xyz;
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
    `,

            fragmentShader: `
        uniform vec3 color;
        uniform float coeficient;
        uniform float power;
        varying vec3 vVertexNormal;
        varying vec3 vVertexWorldPosition;
        void main() {
            vec3 worldCameraToVertex = vVertexWorldPosition - cameraPosition;
            vec3 viewCameraToVertex = normalize((viewMatrix * vec4(worldCameraToVertex, 0.0)).xyz);
            float intensity = pow(coeficient + dot(vVertexNormal, viewCameraToVertex), power);
            
            // Make the black part transparent and only render the glow
            float alpha = intensity > 0.0 ? intensity : 0.0;
            gl_FragColor = vec4(color * intensity, alpha);
        }
    `,
            side: THREE.BackSide,
            blending: THREE.AdditiveBlending,
            depthWrite: true,
            transparent: true,
        });
        AFRAME.registerComponent('day-night-cycle', {
            schema: {
                duration: { type: 'number', default: 24000 }, // Default to 24h cycle in ms, adjust as needed
                timeOffset: { type: 'number', default: 0 } // Time offset in hours
            },

            tick: function() {
                const currentTime = new Date();
                const hoursUTC = currentTime.getUTCHours() + this.data.timeOffset;

                // Ensure hours are wrapped in 0-23 range
                const wrappedHours = (hoursUTC + 24) % 24;

                const minutesUTC = currentTime.getUTCMinutes();

                // Calculate the current time in minutes since midnight
                const totalMinutesUTC = wrappedHours * 60 + minutesUTC;

                // Map the current time to degrees
                // (0-1439 minutes mapped to 0-360 degrees)
                const degreesRotation = (totalMinutesUTC / 1440) * 360;

                // Apply rotation to the entity
                this.el.object3D.rotation.set(
                    THREE.MathUtils.degToRad(degreesRotation),
                    0,
                    0
                );
            }
        });


        AFRAME.registerShader('invertGlow', {
            schema: {
                texture: { type: 'map', is: 'uniform' },
                color: { type: 'color', is: 'uniform', default: 'white' },
                coeficient: { type: 'number', is: 'uniform', default: 0.01 },
                power: { type: 'number', is: 'uniform', default: 0.2 }
            },

            vertexShader: `
        varying vec2 vUv;
        void main() {
            vUv = uv;
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
    `,

            fragmentShader: `
        uniform sampler2D texture;
        uniform vec3 color;
        uniform float coeficient;
        uniform float power;
        varying vec2 vUv;
        void main() {
            vec4 texColor = texture2D(texture, vUv);
            
            // Inverting the colors of the texture
            vec3 invertedColor = vec3(1.0) - texColor.rgb;
            
            // Calculating the luminance to separate the light and dark parts
            float luminance = dot(invertedColor, vec3(0.299, 0.587, 0.114));
            
            // Adding a glow effect to the light parts
            vec3 glow = invertedColor * color * pow(max(1.0 - luminance, 0.0), power) * coeficient;
            
            // Combining the original inverted color and the glow
            vec3 finalColor = invertedColor + glow;
            
            gl_FragColor = vec4(finalColor, texColor.a);  // Keeping the original alpha
        }
    `,
            side: THREE.FrontSide,
            depthWrite: true,
            transparent: true
        });


        this.updateRotation(); // Initial update
        setInterval(this.updateRotation, 10); // Update rotation every 10 milliseconds
        setInterval(this.updateMuteStatus, 10); // Update rotation every 10 milliseconds
        const aframeportal = document.createElement("script");
        aframeportal.setAttribute(
            "src",
            "https://unpkg.com/aframe-liquid-portal-shader"
        );
        document.head.appendChild(aframeportal);
        // Initialize the sphereHoveredStates array
        this.sphereHoveredStates = this.markerPositions.map(() => false);
    },
    methods: {

        updateNightOverlayRotation() {
            const moonPosition = this.$refs.moon.object3D.position;
            const globePosition = this.$refs.globe.object3D.position;
            const directionToMoon = moonPosition.clone().sub(globePosition).normalize();

            // Calculate the rotation needed to align the night overlay with the moon
            const rotationAxis = new THREE.Vector3(0, 1, 0);
            const rotationAngle = Math.atan2(directionToMoon.x, directionToMoon.z);
            const quaternion = new THREE.Quaternion().setFromAxisAngle(rotationAxis, rotationAngle);

            // Apply the rotation to the night overlay
            this.$refs.nightOverlay.object3D.quaternion.copy(quaternion);
        },
        throttle(func, limit) {
            let inThrottle;
            return function() {
                const args = arguments;
                const context = this;
                if (!inThrottle) {
                    func.apply(context, args);
                    inThrottle = true;
                    setTimeout(() => inThrottle = false, limit);
                }
            }
        },
        isDesktop() {
            // Use a media query to check screen width
            return window.matchMedia("(min-width: 1024px)").matches;
        },

        isMobile() {
            // Use a media query to check screen width
            return window.matchMedia("(max-width: 1024px)").matches;
        },


        removeMarker(index) {
            // Play a sound or perform other actions before removing the marker
            this.playChirp(1000, 0.05, 0.1);
            this.$emit('requestMarkerRemoval', index); // Emit custom event
        },
        handleMousemove: throttle(function(event) {
            const intersectionPoint = event.detail.intersection.point;
            const globeCenter = new Vector3(0, 0, 0);

            // Apply the inverse rotation of the globe
            const globeRotation = this.$refs.globe.object3D.rotation;
            const localIntersection = new Vector3().copy(intersectionPoint).sub(globeCenter);
            localIntersection.applyEuler(new Euler(-globeRotation.x, -globeRotation.y, -globeRotation.z, 'XYZ'));

            // Convert intersection point back to latitude and longitude
            const radius = 1.00 * this.earthRadius;
            const lat = Math.asin(localIntersection.y / radius) * (180 / Math.PI);
            const lon = Math.atan2(-localIntersection.z, -localIntersection.x) * (180 / Math.PI) * -1;
            const title = null;
            const desc = null;
            const link = null;


            this.playChirp(lat * 20 + 100, 0.0, 0.05);
            this.playChirp(lon * 20 + 100, 0.01, 0.05);

            console.log(`latitude: ${lat.toFixed(2)}, longitude: ${lon.toFixed(2)}`);

            // Emit the latitude and longitude to the parent component
            this.$emit('latitudeLongitude', { lat, lon });
            this.$emit('titleDescLink', { title, desc, link });
        }, 100),

        handleRaycasterEnter: throttle(function(event) {
            const intersectionPoint = event.detail.intersection.point;
            const globeCenter = new Vector3(0, 0, 0);

            // Apply the inverse rotation of the globe
            const globeRotation = this.$refs.globe.object3D.rotation;
            const localIntersection = new Vector3().copy(intersectionPoint).sub(globeCenter);
            localIntersection.applyEuler(new Euler(-globeRotation.x, -globeRotation.y, -globeRotation.z, 'XYZ'));

            // Convert intersection point back to latitude and longitude
            const radius = 1.00 * this.earthRadius;
            const lat = Math.asin(localIntersection.y / radius) * (180 / Math.PI);
            const lon = Math.atan2(-localIntersection.z, -localIntersection.x) * (180 / Math.PI) * -1;



            this.cursorPos = `${localIntersection.x} ${localIntersection.y} ${localIntersection.z}`;

            console.log(this.cursorPos);


            console.log(`latitude: ${lat.toFixed(2)}, longitude: ${lon.toFixed(2)}`);

            // Emit the latitude and longitude to the parent component
            this.$emit('latitudeLongitude', { lat, lon });
            this.$emit('titleDescLink', { title: null, desc: null, link: null });
            this.$emit('requestMarkerRemoval', null); // Emit custom event
        }, 100),


        placeSphere(event) {
            if (this.hoveredPosition !== -1) {
                const intersectionPoint = event.detail.intersection.point;
                this.spherePosition = new Vector3().copy(intersectionPoint);

                // Apply the inverse rotation of the globe
                const globeRotation = this.$refs.globe.object3D.rotation;
                const localIntersection = new Vector3().copy(this.spherePosition).sub(new Vector3(0, 0, 0));
                localIntersection.applyEuler(new Euler(-globeRotation.x, -globeRotation.y, -globeRotation.z, 'XYZ'));

                // Convert intersection point back to latitude and longitude
                const radius = 1.00 * this.earthRadius;
                const lat = Math.asin(localIntersection.y / radius) * (180 / Math.PI);
                const lon = Math.atan2(-localIntersection.z, -localIntersection.x) * (180 / Math.PI) * -1;

                this.hoveredLatitude = lat;
                this.hoveredLongitude = lon;

                this.rayIntersectPoint = intersectionPoint;
                this.$emit('globeIntersection', {
                    lat: this.hoveredLatitude,
                    lon: this.hoveredLongitude,
                });

                const cameraPosition = this.$refs.globe.getAttribute('position');
                this.rayStart = new Vector3(cameraPosition.x, cameraPosition.y, cameraPosition.z);
                this.rayEnd = this.spherePosition;
            }
        },
        playChirp(freq, time, volume) { // added volume parameter with default value of 1
            const audioContext = new(window.AudioContext || window.webkitAudioContext)();
            if (this.muted !== true) {
                const duration = time; // Duration of the chirp in seconds
                const frequency = freq; // Frequency of the chirp (adjust as needed)

                const oscillator = audioContext.createOscillator();
                oscillator.type = 'square'; // Oscillator waveform type
                oscillator.frequency.setValueAtTime(frequency, audioContext.currentTime);

                const gainNode = audioContext.createGain(); // Create a gain node
                gainNode.gain.setValueAtTime(volume, audioContext.currentTime); // Set the volume level

                oscillator.connect(gainNode); // Connect oscillator to gain
                gainNode.connect(audioContext.destination); // Connect gain to audio context's destination

                oscillator.start();
                oscillator.stop(audioContext.currentTime + duration);
            }
        },
        updateMuteStatus() {
            this.muted = this.muteStatus;
        },
        getLineDistance(position) {
            const globeCenter = new Vector3(0, 0, 0);
            const markerPosition = new Vector3(...this.getPosition(position).split(' ').map(parseFloat));
            return globeCenter.distanceTo(markerPosition);
        },
        getLineMidpoint(position, scale) {
            const globeCenter = new Vector3(0, 0, 0);
            const markerPosition = new Vector3(...this.getPosition(position).split(' ').map(parseFloat));
            return new Vector3().addVectors(globeCenter, markerPosition).multiplyScalar(scale);
        },
        getLineRotation(position) {
            const globeCenter = new Vector3(0, 0, 0);
            const markerPosition = new Vector3(...this.getPosition(position).split(' ').map(parseFloat));
            const direction = markerPosition.clone().sub(globeCenter).normalize();
            const yAxis = new Vector3(0, 0, 0); // Assuming Y-axis is up

            // Calculate the rotation axis perpendicular to the direction and Y-axis
            const rotationAxis = new Vector3();
            rotationAxis.crossVectors(direction, yAxis).normalize();

            // Calculate the rotation angle between the direction and Y-axis
            const angle = Math.acos(direction.dot(yAxis));

            // Create the rotation quaternion
            const rotationQuaternion = new Quaternion().setFromAxisAngle(rotationAxis, angle);

            return `${rotationQuaternion.x} ${rotationQuaternion.y} ${rotationQuaternion.z} ${rotationQuaternion.w}`;
        },
        getPosition(position) {
            const radius = 1.00 * this.earthRadius;
            const phi = ((90 - position.lat) * Math.PI) / 180;
            const theta = ((position.lon + 180) * Math.PI) / 180;
            const x = radius * Math.sin(phi) * Math.cos(theta);
            const y = radius * Math.cos(phi);
            const z = -radius * Math.sin(phi) * Math.sin(theta);
            return `${x} ${y} ${z}`;
        },
        updateRotation() {
            this.timeScaleAdapted = this.timeScale;

            if (this.timeScale <= 1) {
                this.timeScaleAdapted = this.timeScale + 1;
            }

            // Calculate the rotation increment based on the time scale and elapsed time
            const rotationIncrement = (this.timeScaleAdapted / 100) * (Date.now() / 1000) % 360;

            const now = new Date();
            const start = new Date(now.getFullYear(), 0, 0);
            const diff = (now - start) + ((start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000);
            const oneDay = 1000 * 60 * 60 * 24;
            let dayOfYear = Math.floor(diff / oneDay);

            const timeOfYearOffset = 0;
            dayOfYear = (dayOfYear + timeOfYearOffset) % 365;

            // Calculate tilt based on day of the year with offset
            const maxTilt = 23.44;
            const tilt = maxTilt * Math.sin((dayOfYear / 365.25) * 2 * Math.PI);

            // Continuously increase the rotation value without resetting it
            this.rotation = `${tilt.toFixed(2)} ${-rotationIncrement} 0`;
        },



        getTextPosition() {
            return new Vector3(0, -1.2, 0);
        },
        updateHoveredPosition(index) {

            console.log(index + " hovered")
            this.hovMult = 1.025;
            this.playChirp(400, 0.05, 0.05);
            this.sphereHoveredStates[index] = true;
        },

        updateActivePosition(index) {
            console.log("selected " + index)
            console.log("Marker " + index + " Latitude: " + this.markerPositions[index].lat)
            console.log("Marker " + index + " Longitude: " + this.markerPositions[index].lon)

            this.selectedLat = this.markerPositions[index].lat;
            this.selectedLon = this.markerPositions[index].lon;

            const lat = this.selectedLat;
            const lon = this.selectedLon;

            const title = this.markerPositions[index].title;
            const desc = this.markerPositions[index].description;
            const link = this.markerPositions[index].link;

            this.hovMult = 1.05;
            this.playChirp(800, 0.05, 0.05);

            this.sphereHoveredStates[index] = true;

            this.$emit('latitudeLongitude', { lat, lon });
            this.$emit('titleDescLink', { title, desc, link });

            // Emit custom event to request marker removal
            this.removeMarker(index);

        },

        clearHoveredPosition() {

            console.log("unhovered")
            this.hovMult = 1;

            this.playChirp(200, 0.05, 0.05);
            this.sphereHoveredStates.fill(false);
        },

        updateRayEnd(event) {
            // Calculate ray end based on mouse input
            const canvas = this.$el.querySelector('.aframe-container');
            const mouse = new Vector2();
            const rect = canvas.getBoundingClientRect();

            mouse.x = ((event.clientX - rect.left) / canvas.clientWidth) * 2 - 1;
            mouse.y = -((event.clientY - rect.top) / canvas.clientHeight) * 2 + 1;

            const raycaster = this.$refs.raycaster.components.raycaster;
            raycaster.refreshObjects();

            raycaster.ray.origin.copy(this.rayStart);
            raycaster.ray.direction.set(mouse.x, mouse.y, 0.5).unproject(this.$refs.camera);
            raycaster.ray.direction.sub(raycaster.ray.origin).normalize();

            const intersects = raycaster.intersectObjects(raycaster.objects, true);
            if (intersects.length > 0) {
                this.rayEnd = intersects[0].point;
            }

            // Handle VR controller input
            const vrControllers = this.$refs.vrControllers;
            if (vrControllers) {
                vrControllers.children.forEach(controller => {
                    if (controller && controller.components['raycaster']) {
                        const controllerRaycaster = controller.components.raycaster;
                        const controllerIntersects = controllerRaycaster.intersections;

                        if (controllerIntersects.length > 0) {
                            this.rayEnd = controllerIntersects[0].point;
                        }
                    }
                });
            }
        },

    },
};
</script>

<style>
.aframe-container {
    width: 100%;
    height: 100vh;
    overflow: hidden;
}
</style>