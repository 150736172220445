<template>
    <div class="address">
      {{ beginning }}|<div class='animation-container'><span class='inner'>{{ inner }}</span></div>|{{ end }}
    </div>
  </template>
  
  <script>
  export default {
    name: "EthersAddress",
    props: {
      address: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        inner_position: 4,
      };
    },
    computed: {
      beginning() {
        return this.address.slice(0, 4);
      },
      inner() {
        return this.address.slice(4, 40);
      },
      end() {
        return this.address.slice(40, 42);
      },
    },
    mounted() {

    },
    methods: {


    }
  };
  </script>
  
  <style scoped>
  .address {
    align-items:center;
    display:flex;

  }
  .animation-container {
    width: 4rem;
    overflow: hidden;
    display:inline-block;
  }
  .inner {
    display:inline-block;
    transform: translateX(-100%);
    animation: my-animation 15s linear infinite;
    -webkit-transform: translateX(-100%);
    -webkit-animation: my-animation 15s linear infinite;
  }
  @-webkit-keyframes my-animation {
    0% { transform: translateX(-82%); }
    5% { transform: translateX(-82%); }
    45% { transform: translateX(0%); }
    55% { transform: translateX(0%); }
    5% { transform: translateX(-82%); }
    100% { transform: translateX(-82%); }
  }
  @keyframes my-animation {
    0% { transform: translateX(-82%); }
    5% { transform: translateX(-82%); }
    45% { transform: translateX(0%); }
    55% { transform: translateX(0%); }
    5% { transform: translateX(-82%); }
    100% { transform: translateX(-82%); }
  }
  
  </style>