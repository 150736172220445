<template>
    <a-entity ref="arcEntity">
    </a-entity>
</template>

<script>
import * as THREE from 'three';
import { gsap } from 'gsap';

export default {
    props: {
        diameter: Number,
        degrees: Number,
        angle: Number,
        position: Array,
        rotation: Array,
        color: String,
        thickness: {
            type: Number,
            default: 0.05,
        },
        breakAngle: {
            type: Number,
            default: 0,
        },
        segments: {
            type: Number,
            default: 1,
        },
    },
    data() {
        return {
            internalDegrees: this.degrees,
            animatedSegments: this.segments,
            arcs: [],
        };
    },
    mounted() {
        this.createArcs();
        this.updateArcVisibility();

        // GSAP animation for segments
        gsap.to(this, {
            animatedSegments: 20,
            duration: 2,
            ease: "power1.inOut",
            yoyo: true,
            repeat: -1,
            onUpdate: () => {
                this.updateArcVisibility();
            },
        });

        // GSAP animation for degrees
        gsap.to(this, {
            internalDegrees: 10,
            duration: 10,
            ease: "power1.inOut",
            yoyo: true,
            repeat: -1,
            onUpdate: () => {
                this.updateArcDegrees();
            },
        });
    },
    methods: {
        createArcs() {
            const el = this.$refs.arcEntity;
            const scene = el.object3D;
            const radius = this.diameter / 2;
            const thetaStart = THREE.MathUtils.degToRad(this.angle);
            const segmentDegrees = this.internalDegrees / this.segments;
            const thetaLength = THREE.MathUtils.degToRad(segmentDegrees - this.breakAngle);
            const breakTheta = THREE.MathUtils.degToRad(this.breakAngle);

            for (let i = 0; i < this.segments; i++) {
                // Changed to BufferGeometry
                const vertices = [];
                const segmentStart = thetaStart + i * (thetaLength + breakTheta);
                const segments = Math.max(3, Math.floor(100 * (thetaLength / (2 * Math.PI))));
                
                for (let j = 0; j <= segments; j++) {
                    const theta = segmentStart + j * (thetaLength / segments);
                    const x = Math.cos(theta) * radius;
                    const y = Math.sin(theta) * radius;
                    vertices.push(x, y, 0);
                }
                
                const geometry = new THREE.BufferGeometry();
                geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));

                const material = new THREE.LineBasicMaterial({ color: this.color });
                const arc = new THREE.Line(geometry, material);
                arc.position.set(...this.position);
                arc.rotation.set(...this.rotation.map(THREE.MathUtils.degToRad));

                scene.add(arc);
                this.arcs.push(arc);
            }
        },

        updateArcVisibility() {
            for (let i = 0; i < this.segments; i++) {
                this.arcs[i].visible = i < this.animatedSegments;
            }
        },

        updateArcDegrees() {
            const radius = this.diameter / 2;
            const segmentDegrees = this.internalDegrees / this.segments;
            const thetaLength = THREE.MathUtils.degToRad(segmentDegrees - this.breakAngle);

            this.arcs.forEach((arc, i) => {
                if (i < this.animatedSegments) {
                    const thetaStart = THREE.MathUtils.degToRad(this.angle);
                    const breakTheta = THREE.MathUtils.degToRad(this.breakAngle);
                    const segmentStart = thetaStart + i * (thetaLength + breakTheta);

                    const vertices = [];
                    const segments = Math.max(3, Math.floor(100 * (thetaLength / (2 * Math.PI))));
                    for (let j = 0; j <= segments; j++) {
                        const theta = segmentStart + j * (thetaLength / segments);
                        const x = Math.cos(theta) * radius;
                        const y = Math.sin(theta) * radius;
                        vertices.push(x, y, 0);
                    }

                    arc.geometry.dispose();
                    arc.geometry = new THREE.BufferGeometry();
                    arc.geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
                }
            });
        },
    },
};
</script>

<style scoped>
/* Add styles here if needed */
</style>
