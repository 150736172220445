import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueTyper from 'vue3-typer'
import AFRAME from 'aframe'

import { createPinia } from 'pinia'
import { TroisJSVuePlugin } from 'troisjs';
import { createNoise2D } from 'simplex-noise';
import slider from "vue3-slider"
require('aframe-physics-system');
require('aframe-environment-component');
require('aframe-effects');
require('aframe-aobake-component');
require('aframe-frame-rate');
require('aframe-geothree-component');
require('aframe-gaze-control-component');
require('aframe-raycaster-thresholds');
require('aframe-super-keyboard');
require('aframe-screen-position');
require('aframe-connecting-line');
require('aframe-globe-component');
require('aframe-web-portals');
require('aframe-text-geometry-component');
require('aframe-rounded');
require('lodash');
require('d3');
require('d3-dsv');
require('satellite.js');

let pinia = createPinia()

createApp(App).use(pinia).use(slider).use(TroisJSVuePlugin).use(createNoise2D).use(AFRAME).use(router).use(VueTyper).mount('#app')


