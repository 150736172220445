<template>
    <a-scene @componentinitialized="onAFrameComponentsInitialized" ref="scene" background="color: #005" vr-mode-ui="enabled: true; enterVRButton: #enterVR; enterARButton: #enterAR" loading-screen="dotsColor: orange; backgroundColor: black" webxr="optionalFeatures: dom-overlay,unbounded; overlayElement: #overlay;"
        cursor="rayOrigin: mouse" shadow="type: pcfsoft" renderer="antialias: true; colorManagement: true; highRefreshRate: false; toneMapping: reinhard; sortObjects: true; exposure: 1.2; precision: high;  physicallyCorrectLights: true;  maxCanvasWidth: 4096; maxCanvasHeight: 4096;">
        <a-entity postprocessing="bloom: true; threshold: 0.1; strength: w.5; radius: 0.5;">
            <a-camera camera rotation-reader ref="camera" look-controls="pointerLockEnabled: false" wasd-controls="fly: true; acceleration: 50;">
    
                <a-entity width="1" line-stack :position="calculateLeftComplication()">
                    <a-text position="0.1 0  0" width="0.5" color="orange" :value="parseFloat(this.rotationAngleX).toFixed(2)"></a-text>
                    <a-entity line="start: 0.1 0 0; end: 0.15 0 0; color: orange" :position="this.boxPositionLeft"></a-entity>
                    <a-entity line="start: 0.1 0.01 0; end: 0.15 0.01 0; color: orange" :position="this.boxPositionLeft"></a-entity>
                    <a-entity line="start: 0.1 -0.01 0; end: 0.15 -0.01 0; color: orange" :position="this.boxPositionLeft"></a-entity>
                </a-entity>
                <a-text align="right" position=" 0 0.7 -1" width="0.5" color="orange" :value="this.latReceived + this.lonReceived"></a-text>
    
                <a-entity width="1" line-stack :position="calculateRightComplication()">
                    <a-text align="right" position="-0.1 0  0" width="0.5" color="orange" :value="parseFloat(this.rotationAngleY).toFixed(2)"></a-text>
                    <a-entity line="start: -0.1 0 0; end: -0.15 0 0; color: orange" :position="this.boxPositionRight"></a-entity>
                    <a-entity line="start: -0.1 0.01 0; end: -0.15 0.01 0; color: orange" :position="this.boxPositionRight"></a-entity>
                    <a-entity line="start: -0.1 -0.01 0; end: -0.15 -0.01 0; color: orange" :position="this.boxPositionRight"></a-entity>
                </a-entity>
    
            </a-camera>
        </a-entity>
        <a-sky animation="property: rotation; to: 0 360 45; easing: linear; loop: true; dur: 10000000" rotation="0 0 45" radius="10000" src="8k_stars_milky_way.jpg"></a-sky>
    
    
        <a-entity :position="cameraPosition">
            <!--<a-icosahedron class="collidable" :radius="1 * this.earthRadius" detail="100" color="#ffffff" blending="additive" opacity="0.05" wireframe="true"></a-icosahedron>-->
    
            <a-light light="type: directional; angle: 160; castShadow:true;" target="#earth" position="0 0 1000" intensity="2" color="#fffeef"></a-light>
            <a-light light="type: directional; angle: 160; castShadow:true;" target="#earth" position="0 0 -1000" intensity="2" color="#efefff"></a-light>
            <a-light type="ambient" color="#001" intensity="0.5"></a-light>
    
            <a-entity :rotation="rotation" @click="handleRaycasterEnter" shadow="receive: true" id="globe" ref="globe" scale=".6371 .6372 .6371" globe=" show-graticules: false;
                                                                    globe-image-url:  8k_earth_daymap.jpg; atmosphere-color: #aaeeff; atmosphere-altitude: 0.055;
                                                                    bump-image-url: earth-topo-bump.jpg;">
                <a-sphere shadow="receive: true" blending="additive" transparent data-no-raycast segments-height="200" aframe-refraction-system animation="property: rotation; to: 0 360 0; easing: linear; loop: true; dur: 9000000" segments-width="200" style="pointer-events:none;"
                    :radius="1.571 * this.earthRadius" opacity="0.8d" detail="200" rotation="0 180 0" material=" reflectivity: 0.5; shininess: 0.5; " src="8k_earth_clouds.jpg">
                </a-sphere>
            </a-entity>
        </a-entity>
    
    </a-scene>
    <p style="    font-family: 'uno'; color:white;font-size:0.5rem;padding:0.5rem;z-index:100;position:fixed;top:10px;">{{ this.rotation }}</p>
    <p style="    font-family: 'uno'; color:white;font-size:0.5rem;padding:0.5rem;z-index:100;position:fixed;top:20px;">{{ this.boxPositionLeft }}</p>
    <p style="    font-family: 'uno'; color:white;font-size:0.5rem;padding:0.5rem;z-index:100;position:fixed;top:30px;">{{ lat }}</p>
    <p style="    font-family: 'uno'; color:white;font-size:0.5rem;padding:0.5rem;z-index:100;position:fixed;top:40px;">{{ lon }}</p>
    
    <p style="font-family: 'uno';color:white;font-size:0.5rem;padding:0.5rem;z-index:100;position:fixed;">Y {{ rotationAngleY }} DEGREES</p>
    <p style="font-family: 'uno';color:white;font-size:0.5rem;padding:0.5rem;z-index:100;position:fixed;top:-10px;">X {{ rotationAngleX }} DEGREES</p>
    <div id="enterAR" class="circlebutton" @mousedown="playChirp(2000, 0.1, 0.02)" @mouseenter="playChirp(9000, 0.01, 0.01)" style="position:fixed;bottom:0px;left:10px;z-index:100;"><img src="~/@/assets/ar-button.svg" style="filter:invert(1);width:32px;" alt=""></div>
    <div id="enterVR" class="circlebutton" @mousedown="playChirp(2000, 0.1, 0.02)" @mouseenter="playChirp(9000, 0.01, 0.01)" style="position:fixed;bottom:0px;left:10px;z-index:100;"><img src="~/@/assets/vr-button.svg" style="filter:invert(1);width:32px;" alt=""></div>
    <div class="data" style="display: none;
                                                                                                                                                                                                                                                                                                background: black;width:auto;height:auto;bottom:0px;right:0px;z-index:100;position:fixed;flex-flow:column;justify-content: space-between;">
    
        <p style="    font-family: 'uno';
                                                                                                                                                                                                                                                                                        color:white;font-size:0.5rem;padding:0.5rem;">{{ }}</p>
        <div class="button" @click="randomPoints()">RANDOM DOTS</div>
        <div class="button" @click="tileCountries()">SECTION TILES</div>
        <div class="button" @click="popMap()">POPULATION</div>
        <div class="button" @click="addPasses()">PASSES</div>
    </div>
</template>

<script>
import 'aframe'
import 'aframe-effects'
import 'aframe-extras'
import 'aframe-globe-component' // Import aframe-effects

import { Vector3, Vector2, Quaternion, Euler } from 'three';
import * as d3 from 'd3';
import * as THREE from 'three';
import { EffectComposer } from '../assets/threejs/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from '../assets/threejs/examples/jsm/postprocessing/RenderPass.js';
import { UnrealBloomPass } from '../assets/threejs/examples/jsm/postprocessing/UnrealBloomPass.js';
import { BokehPass } from '../assets/threejs/examples/jsm/postprocessing/BokehPass.js';
import { GlitchPass } from '../assets/threejs/examples/jsm/postprocessing/GlitchPass.js';

export default {
    name: "app",
    data() {
        return {
            muted: false,
            hovMult: 1,
            markerPositions: Array,
            muteStatus: Boolean,
            efficient: Boolean,
            selectedLat: null,
            selectedLon: null,
            selectedTitle: null,
            position: null,
            selectedDesc: null,
            selectedLink: null,
            latInput: null,
            lonInput: null,
            lat: null,
            lon: null,
            latReceived: null,
            lonReceived: null,
            titleReceived: null,
            descReceived: null,
            linkReceived: null,
            hoveredPosition: -1, // Newly added to track hovered sphere index
            rayIntersectPoint: null,
            rayStart: null, // Start point of visual ray
            rayEnd: null, // End point of visual ray
            sphereHoveredStates: [], // Array to track hovered state of each sphere
            sphereTitleStates: [], // Array to track hovered state of each sphere
            earthRadius: 63.71,
            cameraPosition: { x: 0, y: 0, z: -69.47 },
            boxPositionLeft: '1.5, 0, -1',
            boxPositionRight: '-1.5, 0, -1',
            rotation: '0 0 0', // Initial rotation
            timeScale: 1,
            rotationAngleX: 0, // Initialize with the default angle
            rotationAngleY: 0, // Initialize with the default angle
            cursorPos: '0 0 0',
            composer: null, // Store the composer instance
            cameraLookRot: { x: 0, y: 0, z: 0 }, // Initialize with default rotation

        };
    },
    mounted() {


        AFRAME.registerComponent('line-stack', {
            schema: {
                lineCount: { type: 'int', default: 11 }, // Number of lines
                lineSpacing: { type: 'number', default: 0.1 }, // Spacing between lines
                lineWidth: { type: 'number', default: 0.02 }, // Width of lines
                lineColor: { type: 'color', default: '#ffffff' }, // Line color
                rotationAngleY: { type: 'number', default: 0 }, // Rotation angle for motion
                fadeDuration: { type: 'number', default: 10 }, // Duration for fade in/out (in milliseconds)
                updateRotationY: { type: 'number', default: 0 }, // New property to track rotation based on external variable
            },

            init: function() {
                this.lines = []; // Array to store line entities
                // Create the stack of lines
                for (let i = 0; i < this.data.lineCount; i++) {
                    const yPos = (i - (this.data.lineCount - 1) / 2) * this.data.lineSpacing;
                    const line = document.createElement('a-entity');
                    line.setAttribute('line', {
                        start: `-${this.data.lineWidth / 2} ${yPos} 0`,
                        end: `${this.data.lineWidth / 2} ${yPos} 0`,
                        color: this.data.lineColor,
                        opacity: i === 0 ? 1 : 0, // Set opacity for the first line to 1, others to 0
                    });
                    this.lines.push(line);
                    this.el.appendChild(line);
                }

                // Set up an interval for cycling lines
                this.cycleInterval = setInterval(this.cycleLines.bind(this), this.data.fadeDuration);
            },

            update: function(oldData) {
                // Check if the updateRotationY property has changed
                if (this.data.updateRotationX !== oldData.updateRotationX) {
                    // Update the rotation angle based on the new value
                    this.data.rotationAngleX = this.data.updateRotationX;

                    // Call the method to update line positions
                    this.updateLinePositions();
                }
            },

            updateLinePositions: function() {
                console.log('rotation Triggered');
                // Calculate the yOffset based on the rotationAngleY
                const yOffset = this.rotationAngleX * this.data.lineSpacing * 0.5;

                // Move each line up and down based on the yOffset
                for (let i = 0; i < this.lines.length; i++) {
                    const line = this.lines[i];
                    const yPos = (i - (this.data.lineCount - 1) / 2) * this.data.lineSpacing + yOffset;
                    line.setAttribute('line', {
                        start: `-${this.data.lineWidth / 2} ${yPos} 0`,
                        end: `${this.data.lineWidth / 2} ${yPos} 0`,
                    });
                }
            },

            cycleLines: function() {
                // Fade out the bottom line and fade in the top line
                const bottomLine = this.lines[0];
                const topLine = this.lines[this.data.lineCount - 1];

                // Fade out the bottom line
                bottomLine.setAttribute('animation__fadeout', {
                    property: 'components.line.material.opacity',
                    to: 1,
                    dur: this.data.fadeDuration,
                    easing: 'linear',
                });

                // Fade in the top line
                topLine.setAttribute('animation__fadein', {
                    property: 'components.line.material.opacity',
                    to: 1,
                    dur: this.data.fadeDuration,
                    easing: 'linear',
                });

                // Move the bottom line to the top and reset its opacity
                this.lines.shift();
                this.lines.push(bottomLine);
                bottomLine.setAttribute('line', { opacity: 1 });
            },

            remove: function() {
                // Clear the cycleInterval when the component is removed
                clearInterval(this.cycleInterval);
            },
        });






        AFRAME.registerComponent('rotation-reader', {
            tick: function() {
                // `this.el` is the element.
                // `object3D` is the three.js object.

                // Get the rotation as a three.js Euler using radians.
                const rotation = this.el.object3D.rotation;

                // Calculate and log the angle in degrees based on the y-axis rotation.
                const angleInDegreesY = THREE.MathUtils.radToDeg(rotation.y);
                this.rotationAngleY = angleInDegreesY;
                window.globalRotationAngleY = angleInDegreesY;

                //console.log('Angle in degrees Y:', this.rotationAngleY);

                const angleInDegreesX = THREE.MathUtils.radToDeg(rotation.x);
                this.rotationAngleX = angleInDegreesX;
                this.el.emit('rotation-updated', { rotationAngleX: this.rotationAngleX });
                //console.log('Angle in degrees X:', this.rotationAngleX);

                // `position` is a three.js Vector3.
                //console.log('Position:', this.el.object3D.position);
                this.el.emit('rotation-updated', {
                    rotationAngleY: this.rotationAngleY,
                    rotationAngleX: this.rotationAngleX,
                });

            }
        });



        let EffectComposerBase = document.createElement('script')
        EffectComposerBase.setAttribute('src', 'EffectComposerBase.js')
        document.head.appendChild(EffectComposerBase)


        let DigitalGlitch = document.createElement('script')
        DigitalGlitch.setAttribute('src', 'DigitalGlitch.js')
        document.head.appendChild(DigitalGlitch)


        let ConvolutionShader = document.createElement('script')
        ConvolutionShader.setAttribute('src', 'ConvolutionShader.js')
        document.head.appendChild(ConvolutionShader)


        let BloomPass = document.createElement('script')
        BloomPass.setAttribute('src', 'BloomPass.js')
        document.head.appendChild(BloomPass)


        let FXAAShader = document.createElement('script')
        FXAAShader.setAttribute('src', 'FXAAShader.js')
        document.head.appendChild(FXAAShader)


        let GlitchPass = document.createElement('script')
        GlitchPass.setAttribute('src', 'GlitchPass.js')
        document.head.appendChild(GlitchPass)


        let RenderPass = document.createElement('script')
        RenderPass.setAttribute('src', 'RenderPass.js')
        document.head.appendChild(RenderPass)


        let effect = document.createElement('script')
        effect.setAttribute('src', 'effect.js')
        document.head.appendChild(effect)



        const sceneEl = document.querySelector('a-scene');
        if (sceneEl.hasLoaded) {
            this.onAFrameComponentsInitialized();
        } else {
            sceneEl.addEventListener('loaded', () => {
                this.onAFrameComponentsInitialized();
            });
        }
        sceneEl.addEventListener('render-target-loaded', () => {
            this.onAFrameComponentsInitialized();
        });

        // Initialize the rotation update
        this.updateRotation(); // Initial update
        setInterval(this.updateRotation, 10); // Update rotation every 10 milliseconds   

        setInterval(this.moveBoxLeft, 10); // Update rotation every 10 milliseconds   
        setInterval(this.moveBoxRight, 10); // Update rotation every 10 milliseconds   

        sceneEl.addEventListener('rotation-updated', (event) => {
            // Update the rotationAngleY and rotationAngleX properties when the event is received
            this.rotationAngleY = event.detail.rotationAngleY;
            this.rotationAngleX = event.detail.rotationAngleX;
        });

    },
    methods: {
        onAFrameComponentsInitialized() {
            // Initialize the EffectComposer
            this.init();
        },
        init() {
            // Access the A-Frame entity
            const sceneEl = document.querySelector('a-scene');

            // Wait for the A-Frame scene to initialize
            sceneEl.addEventListener('loaded', () => {
                // Access the A-Frame renderer via the renderTarget property
                const renderer = sceneEl.renderer;

                // Check if the renderer is available
                if (renderer) {
                    // Create a custom render target
                    const renderTarget = new THREE.WebGLRenderTarget(
                        window.innerWidth,
                        window.innerHeight
                    );

                    // Create an EffectComposer with your custom render target
                    const composer = new EffectComposer(renderer, renderTarget);

                    // Add a RenderPass
                    const renderPass = new RenderPass(sceneEl.object3D, sceneEl.camera);
                    composer.addPass(renderPass);


                    // Create and configure the Unreal Bloom pass
                    const unrealBloom = new UnrealBloomPass({
                        strength: 3,
                        radius: 2,
                        threshold: 0.5,
                    });
                    composer.addPass(unrealBloom);

                    // Create and configure the Bokeh pass
                    const bokehPass = new BokehPass(sceneEl.object3D, sceneEl.camera, {
                        focus: 3.0,
                        aperture: 0.025,
                        maxblur: 0.1,
                    });
                    composer.addPass(bokehPass);

                    // Create and configure the Glitch pass
                    const glitchPass = new GlitchPass();
                    composer.addPass(glitchPass);

                    // Store the composer instance
                    this.composer = composer;

                    // Log success message
                    console.log('EffectComposer setup complete.');

                    // Start the animation loop
                    sceneEl.addEventListener('renderstart', () => {});
                } else {
                    console.error('Renderer is not available.');
                }
            });
        },

        render() {
            // Update any scene logic here

            // Render the composer
            this.composer.render();

            // Request the next frame
            this.el.sceneEl.renderer.xr.getSession().requestAnimationFrame(this.render.bind(this));
        },


        updateRotationAngleY() {
            // Update the rotationAngleY property with the value from the global variable
            this.rotationAngleY = window.globalRotationAngleY;
        },
        getPosition2(lineIndex) {
            // Adjust the following values based on your desired sliding range
            const minX = -2; // Minimum X position (left boundary)
            const maxX = 0; // Maximum X position (left boundary)

            // Calculate the range for X positions
            const xRange = maxX - minX;

            // Calculate the current X position based on rotationAngleY
            // Assuming rotationAngleY ranges from -PI to PI (or -180 to 180 degrees)
            const rotationAngleY = this.rotationAngleY; // Replace with your actual variable
            const normalizedAngle = (rotationAngleY + Math.PI) / (2 * Math.PI); // Normalize angle to [0, 1]
            const xPosition = maxX - normalizedAngle * xRange; // Reverse the direction

            // Calculate the index-based offset to create the spacing effect
            const offset = lineIndex * 0.2; // Adjust the spacing as needed

            // Apply the offset to X position
            const finalXPosition = xPosition - offset;

            return `${finalXPosition} 1.5 -2`; // Adjust the Y and Z positions as needed
        },
        calculateLeftComplication() {
            const screenHeight = window.innerHeight;
            const screenWidth = window.innerWidth;

            // Calculate the desired x position as a proportion of screen height to screen width
            const desiredXPosition = -0.75 / (screenHeight / screenWidth);

            return { x: desiredXPosition, y: 0, z: -1 };
        },
        calculateRightComplication() {
            const screenHeight = window.innerHeight;
            const screenWidth = window.innerWidth;

            // Calculate the desired x position as a proportion of screen height to screen width
            const desiredYPosition = 0.75 / (screenHeight / screenWidth);

            return { x: desiredYPosition, y: 0, z: -1 };
        },
        moveBoxLeft() {
            const screenHeight = window.innerHeight;
            const screenWidth = window.innerWidth;

            const rotL = this.rotationAngleX * 0.01;
            this.boxPositionLeft = { x: -0.75 / (screenHeight / screenWidth), y: rotL, z: -1 };
            if (this.rotationAngleX !== this.lastRotationAngleX) {
                if (!this.chirpInProgress) {
                    // Chirp is not in progress, play the chirp sound
                    this.playChirp(
                        16000 + parseFloat(this.rotationAngleX).toFixed(2) * 10,
                        0.01,
                        0.05
                    );

                    // Set the flag to indicate that a chirp is in progress
                    this.chirpInProgress = true;

                    // Set a timeout to reset the chirp status after 50 milliseconds
                    setTimeout(() => {
                        this.chirpInProgress = false;
                    }, 100);
                }
            }

            // Update the lastRotationAngleX to the current value
            this.lastRotationAngleX = this.rotationAngleX;
        },
        moveBoxRight() {
            const screenHeight = window.innerHeight;
            const screenWidth = window.innerWidth;

            const rotR = this.rotationAngleY * 0.01;
            this.boxPositionRight = { x: 0.75 / (screenHeight / screenWidth), y: rotR, z: -1 };
            if (this.rotationAngleY !== this.lastRotationAngleY) {
                if (!this.chirpInProgress) {
                    // Chirp is not in progress, play the chirp sound
                    this.playChirp(
                        16000 + parseFloat(this.rotationAngleY).toFixed(2) * 10,
                        0.01,
                        0.05
                    );

                    // Set the flag to indicate that a chirp is in progress
                    this.chirpInProgress = true;

                    // Set a timeout to reset the chirp status after 50 milliseconds
                    setTimeout(() => {
                        this.chirpInProgress = false;
                    }, 100);
                }
            }

            // Update the lastRotationAngleY to the current value
            this.lastRotationAngleY = this.rotationAngleY;
        },
        tick(t, dt) {
            // Store timestamps for the composer.render function
            this.t = t;
            this.dt = dt;
        },
        tileCountries() {
            const TILE_MARGIN = 0.35; // degrees

            // Gen random data
            const GRID_SIZE = [60, 20];
            const COLORS = ['#ffaa55', '#ffff00', 'orange', '#ff5500'];

            const materials = COLORS.map(color => new THREE.MeshLambertMaterial({ color, opacity: 0.2, transparent: true }));
            const tileWidth = 360 / GRID_SIZE[0];
            const tileHeight = 180 / GRID_SIZE[1];
            const tilesData = [];
            [...Array(GRID_SIZE[0]).keys()].forEach(lngIdx => [...Array(GRID_SIZE[1]).keys()].forEach(latIdx =>
                tilesData.push({
                    lng: -180 + lngIdx * tileWidth,
                    lat: -90 + (latIdx + 0.5) * tileHeight,
                    material: materials[Math.floor(Math.random() * materials.length)]
                })
            ));

            const globeEntity = document.getElementById('globe');
            globeEntity.setAttribute('globe', {
                tilesData,
                tileWidth: tileWidth - TILE_MARGIN,
                tileHeight: tileHeight - TILE_MARGIN,
                tileMaterial: 'material'
            });
        },
        popMap() {
            const weightColor = d3.scaleSequentialSqrt(d3.interpolateYlOrRd)
                .domain([0, 1e7]);

            const globeEntity = document.getElementById('globe');
            globeEntity.setAttribute('globe', {
                hexBinPointWeight: 'pop',
                hexAltitude: d => d.sumWeight * 6e-8,
                hexBinResolution: 5,
                hexTopColor: d => weightColor(d.sumWeight),
                hexSideColor: d => weightColor(d.sumWeight),
                hexBinMerge: true
            });

            fetch('worldpop.csv').then(res => res.text())
                .then(csv => d3.csvParse(csv, ({ lat, lng, pop }) => ({ lat: +lat, lng: +lng, pop: +pop })))
                .then(data => globeEntity.setAttribute('globe', 'hexBinPointsData', data));
        },

        randomPoints() {
            // Gen random data
            const N = 300;
            const gData = [...Array(N).keys()].map(() => ({
                lat: (Math.random() - 0.5) * 180,
                lng: (Math.random() - 0.5) * 360,
                alt: Math.random() * 0.8 + 0.1,
                radius: Math.random() * 0.1,
                color: ['#ffaa55', '#ffff00', 'orange', '#ff5500'][Math.round(Math.random() * 3)]
            }));

            const scene = document.querySelector('a-scene');

            // Create a point light
            const light = document.createElement('a-light');
            light.setAttribute('type', 'point');
            light.setAttribute('position', '0 5 -5'); // Adjust the position of the light
            light.setAttribute('distance', '10'); // Set the light's distance
            light.setAttribute('decay', '2'); // Set the light's decay
            scene.appendChild(light);

            const globeEntity = document.getElementById('globe');
            globeEntity.setAttribute('globe', {
                customLayerData: gData,
                customThreeObject: d => new THREE.Mesh(
                    new THREE.SphereGeometry(d.radius),
                    new THREE.MeshLambertMaterial({ color: d.color })
                ),
                customThreeObjectUpdate: (obj, d) => {
                    Object.assign(obj.position, globeEntity.components.globe.getCoords(d.lat, d.lng, d.alt));
                }
            });

            (function moveSpheres() {
                gData.forEach(d => d.lat += 0.2);
                globeEntity.setAttribute('globe', 'customLayerData', gData);
                requestAnimationFrame(moveSpheres);
            })();
        },



        getCanvas() {
            document.querySelector('a-scene').components.screenshot.getCanvas('equirectangular');

        },

        capture() {
            document.querySelector('a-scene').components.screenshot.capture('perspective')

        },

        preloadMarkerRemoval(index) {
            this.selectedIndex = index;
            console.log(index + " Selected")
        },
        handleMarkerRemoval() {
            // Create a new array without the marker at the specified index
            const index2 = this.selectedIndex;
            this.markerPositions = this.markerPositions.filter((_, i) => i !== index2);
            this.playChirp(index2 * 5 + 100, 0.1, 0.05);

            // Handle any other necessary updates
            console.log(`Marker at index ${index2} removed.`);
            this.selectedIndex = null;

        },
        onMarkerMouseEnter(title, description, link, index) {
            this.markerHovered = true;
            const raycaster = this.$refs.raycaster;
            const intersection = raycaster.components.raycaster.intersections[0];

            if (intersection) {
                const endPosition = intersection.point;
                const rayLine = this.$refs.rayLine;
                rayLine.setAttribute("line", `start: 0 0 0; end: ${endPosition.x} ${endPosition.y} ${endPosition.z}; color: white`);
                rayLine.setAttribute("visible", true);
            }

            this.markerTooltip.title = title;
            this.markerTooltip.description = description;
            this.markerTooltip.link = link;
            this.markerTooltip.visible = true;
            this.selectedMarkerIndex = index; // Set the selected marker index
        },
        onMarkerMouseLeave() {
            this.markerHovered = false;
            this.selectedMarkerIndex = -1; // Reset the selected marker index

            this.$refs.rayLine.setAttribute("visible", false);
        },

        updateRtc() {
            const currentTimeUTC = new Date().toUTCString();
            this.currentTime = currentTimeUTC;
        },
        handleLatitudeLongitude({ lat, lon }) {
            console.log(`Received latitude: ${lat.toFixed(2)}, longitude: ${lon.toFixed(2)}`);
            this.latReceived = lat.toFixed(2);
            this.lonReceived = lon.toFixed(2);
            // Do whatever you need to do with the received latitude and longitude values
        },
        handleTitleDescLink({ title, desc, link }) {
            console.log(`Received Title: ${title}, Desc: ${desc}, Link: ${link}`);
            this.titleReceived = title;
            this.descReceived = desc;
            this.linkReceived = link;
            // Do whatever you need to do with the received latitude and longitude values
        },
        playChirp(freq, time, volume) { // added volume parameter with default value of 1
            if (this.muted !== true) {
                const audioContext = new(window.AudioContext || window.webkitAudioContext)();

                const duration = time; // Duration of the chirp in seconds
                const frequency = freq; // Frequency of the chirp (adjust as needed)

                const oscillator = audioContext.createOscillator();
                oscillator.type = 'square'; // Oscillator waveform type
                oscillator.frequency.setValueAtTime(frequency, audioContext.currentTime);

                const gainNode = audioContext.createGain(); // Create a gain node
                gainNode.gain.setValueAtTime(volume, audioContext.currentTime); // Set the volume level

                oscillator.connect(gainNode); // Connect oscillator to gain
                gainNode.connect(audioContext.destination); // Connect gain to audio context's destination

                oscillator.start();
                oscillator.stop(audioContext.currentTime + duration);
            }
        },
        addMarker() {
            if (this.latReceived !== null && this.lonReceived !== null) {
                const newMarker = {
                    lat: parseFloat(this.latReceived),
                    lon: parseFloat(this.lonReceived),
                    title: this.titleReceived, // Set a default title
                    description: this.descReceived,
                    link: this.linkReceived,
                };
                this.playChirp(newMarker.lat * 20 + 400, 0.05, 0.05);
                this.playChirp(newMarker.lon * 20 + 400, 0.05, 0.05);
                this.markerPositions.push(newMarker);

                // Clear the input values
                this.latInput = null;
                this.lonInput = null;
                this.latReceived = null;
                this.lonReceived = null;
                this.titleReceived = null;
                this.descReceived = null;
                this.linkReceived = null;
            }


        },
        updateCameraPosition(newZ) {
            this.cameraPosition.z = newZ; // Update the cameraPosition data property
            const camera = this.$refs.camera;
            if (camera) {
                camera.setAttribute('position', `0 0 ${newZ}`);
            }
        },
        generateStars() {
            const numStars = 0;

            for (let i = 0; i < numStars; i++) {
                const position = `${this.getRandomPosition()} ${this.getRandomPosition()} ${this.getRandomPosition()}`;
                const radius = Math.random() * 0.005;

                this.stars.push({
                    position,
                    radius,
                });
            }
        },
        updateCoordinates(intersectionData) {
            this.latInput = intersectionData.lat;
            this.lonInput = intersectionData.lon;
        },
        getRandomPosition() {
            return (Math.random() - 0.5) * 50;
        },
        screenshot() {
            document.querySelector('a-scene').components.screenshot.capture('equirectangular');
        },
        updateRotation() {
            this.timeScaleAdapted = this.timeScale;

            if (this.timeScale <= 1) {
                this.timeScaleAdapted = this.timeScale + 10;
            }
            const currentTime = Math.floor(Date.now() / 10); // Current Unix time in milliseconds


            const rotationPeriod = 90 * 60 * 100; // 90 minutes in milliseconds (10000)
            const rotationAngle = (-360 * (currentTime % rotationPeriod)) / rotationPeriod * this.timeScaleAdapted / 10 * 1;
            this.rotation = `0 ${rotationAngle} 0`;
        },

        removeMarker(index) {
            // Play a sound or perform other actions before removing the marker
            this.playChirp(1000, 0.05, 0.1);
            this.$emit('requestMarkerRemoval', index); // Emit custom event
        },
        handleMousemove(event) {
            const intersectionPoint = event.detail.intersection.point;
            const globeCenter = new Vector3(0, 0, 0);

            // Apply the inverse rotation of the globe
            const globeRotation = this.$refs.globe.object3D.rotation;
            const localIntersection = new Vector3().copy(intersectionPoint).sub(globeCenter);
            localIntersection.applyEuler(new Euler(-globeRotation.x, -globeRotation.y, -globeRotation.z, 'XYZ'));

            // Convert intersection point back to latitude and longitude
            const radius = 1.00 * this.earthRadius;
            const lat = Math.asin(localIntersection.y / radius) * (180 / Math.PI);
            const lon = Math.atan2(-localIntersection.z, -localIntersection.x) * (180 / Math.PI) * -1;
            const title = null;
            const desc = null;
            const link = null;


            this.playChirp(lat * 20 + 100, 0.0, 0.05);
            this.playChirp(lon * 20 + 100, 0.01, 0.05);

            console.log(`latitude: ${lat.toFixed(2)}, longitude: ${lon.toFixed(2)}`);

            // Emit the latitude and longitude to the parent component
            this.$emit('latitudeLongitude', { lat, lon });
            this.$emit('titleDescLink', { title, desc, link });
        },

        handleRaycasterEnter(event) {
            const intersectionPoint = event.detail.intersection.point;
            const globeCenter = new Vector3(0, 0, 0);

            // Apply the inverse rotation of the globe
            const globeRotation = this.$refs.globe.object3D.rotation;
            const localIntersection = new Vector3().copy(intersectionPoint).sub(globeCenter);
            localIntersection.applyEuler(new Euler(-globeRotation.x, -globeRotation.y, -globeRotation.z, 'XYZ'));

            // Convert intersection point back to latitude and longitude
            const radius = 1.00 * this.earthRadius;
            const lat = Math.asin(localIntersection.y / radius) * (180 / Math.PI);
            const lon = Math.atan2(-localIntersection.z, -localIntersection.x) * (180 / Math.PI) * -1;



            this.cursorPos = `${localIntersection.x} ${localIntersection.y} ${localIntersection.z}`;

            console.log(this.cursorPos);
            this.playChirp(lat * 20 + 100, 0.0, 0.05);
            this.playChirp(lon * 20 + 100, 0.01, 0.05);

            console.log(`latitude: ${lat.toFixed(2)}, longitude: ${lon.toFixed(2)}`);

            // Emit the latitude and longitude to the parent component
            this.$emit('latitudeLongitude', { lat, lon });
            this.$emit('titleDescLink', { title: null, desc: null, link: null });
            this.$emit('requestMarkerRemoval', null); // Emit custom event
        },


        placeSphere(event) {
            if (this.hoveredPosition !== -1) {
                const intersectionPoint = event.detail.intersection.point;
                this.spherePosition = new Vector3().copy(intersectionPoint);

                // Apply the inverse rotation of the globe
                const globeRotation = this.$refs.globe.object3D.rotation;
                const localIntersection = new Vector3().copy(this.spherePosition).sub(new Vector3(0, 0, 0));
                localIntersection.applyEuler(new Euler(-globeRotation.x, -globeRotation.y, -globeRotation.z, 'XYZ'));

                // Convert intersection point back to latitude and longitude
                const radius = 1.00 * this.earthRadius;
                const lat = Math.asin(localIntersection.y / radius) * (180 / Math.PI);
                const lon = Math.atan2(-localIntersection.z, -localIntersection.x) * (180 / Math.PI) * -1;

                this.hoveredLatitude = lat;
                this.hoveredLongitude = lon;

                this.rayIntersectPoint = intersectionPoint;
                this.$emit('globeIntersection', {
                    lat: this.hoveredLatitude,
                    lon: this.hoveredLongitude,
                });

                const cameraPosition = this.$refs.globe.getAttribute('position');
                this.rayStart = new Vector3(cameraPosition.x, cameraPosition.y, cameraPosition.z);
                this.rayEnd = this.spherePosition;
            }
        },

        updateMuteStatus() {
            this.muted = this.muteStatus;
        },
        getLineDistance(position) {
            const globeCenter = new Vector3(0, 0, 0);
            const markerPosition = new Vector3(...this.getPosition(position).split(' ').map(parseFloat));
            return globeCenter.distanceTo(markerPosition);
        },
        getLineMidpoint(position, scale) {
            const globeCenter = new Vector3(0, 0, 0);
            const markerPosition = new Vector3(...this.getPosition(position).split(' ').map(parseFloat));
            return new Vector3().addVectors(globeCenter, markerPosition).multiplyScalar(scale);
        },
        getLineRotation(position) {
            const globeCenter = new Vector3(0, 0, 0);
            const markerPosition = new Vector3(...this.getPosition(position).split(' ').map(parseFloat));
            const direction = markerPosition.clone().sub(globeCenter).normalize();
            const yAxis = new Vector3(0, 0, 0); // Assuming Y-axis is up

            // Calculate the rotation axis perpendicular to the direction and Y-axis
            const rotationAxis = new Vector3();
            rotationAxis.crossVectors(direction, yAxis).normalize();

            // Calculate the rotation angle between the direction and Y-axis
            const angle = Math.acos(direction.dot(yAxis));

            // Create the rotation quaternion
            const rotationQuaternion = new Quaternion().setFromAxisAngle(rotationAxis, angle);

            return `${rotationQuaternion.x} ${rotationQuaternion.y} ${rotationQuaternion.z} ${rotationQuaternion.w}`;
        },
        getPosition(position) {
            const radius = 1.00 * this.earthRadius;
            const phi = ((90 - position.lat) * Math.PI) / 180;
            const theta = ((position.lon + 180) * Math.PI) / 180;
            const x = radius * Math.sin(phi) * Math.cos(theta);
            const y = radius * Math.cos(phi);
            const z = -radius * Math.sin(phi) * Math.sin(theta);
            return `${x} ${y} ${z}`;
        },


        getTextPosition() {
            return new Vector3(0, -1.2, 0);
        },
        updateHoveredPosition(index) {

            console.log(index + " hovered")
            this.hovMult = 1.025;
            this.playChirp(400, 0.05, 0.05);
            this.sphereHoveredStates[index] = true;
        },

        updateActivePosition(index) {
            console.log("selected " + index)
            console.log("Marker " + index + " Latitude: " + this.markerPositions[index].lat)
            console.log("Marker " + index + " Longitude: " + this.markerPositions[index].lon)

            this.selectedLat = this.markerPositions[index].lat;
            this.selectedLon = this.markerPositions[index].lon;

            const lat = this.selectedLat;
            const lon = this.selectedLon;

            const title = this.markerPositions[index].title;
            const desc = this.markerPositions[index].description;
            const link = this.markerPositions[index].link;

            this.hovMult = 1.05;
            this.playChirp(800, 0.05, 0.05);

            this.sphereHoveredStates[index] = true;

            this.$emit('latitudeLongitude', { lat, lon });
            this.$emit('titleDescLink', { title, desc, link });

            // Emit custom event to request marker removal
            this.removeMarker(index);

        },

        clearHoveredPosition() {

            console.log("unhovered")
            this.hovMult = 1;

            this.playChirp(200, 0.05, 0.05);
            this.sphereHoveredStates.fill(false);
        },

        updateRayEnd(event) {
            // Calculate ray end based on mouse input
            const canvas = this.$el.querySelector('.aframe-container');
            const mouse = new Vector2();
            const rect = canvas.getBoundingClientRect();

            mouse.x = ((event.clientX - rect.left) / canvas.clientWidth) * 2 - 1;
            mouse.y = -((event.clientY - rect.top) / canvas.clientHeight) * 2 + 1;

            const raycaster = this.$refs.raycaster.components.raycaster;
            raycaster.refreshObjects();

            raycaster.ray.origin.copy(this.rayStart);
            raycaster.ray.direction.set(mouse.x, mouse.y, 0.5).unproject(this.$refs.camera);
            raycaster.ray.direction.sub(raycaster.ray.origin).normalize();

            const intersects = raycaster.intersectObjects(raycaster.objects, true);
            if (intersects.length > 0) {
                this.rayEnd = intersects[0].point;
            }

            // Handle VR controller input
            const vrControllers = this.$refs.vrControllers;
            if (vrControllers) {
                vrControllers.children.forEach(controller => {
                    if (controller && controller.components['raycaster']) {
                        const controllerRaycaster = controller.components.raycaster;
                        const controllerIntersects = controllerRaycaster.intersections;

                        if (controllerIntersects.length > 0) {
                            this.rayEnd = controllerIntersects[0].point;
                        }
                    }
                });
            }
        },

    },
    computed: {

    },
    watch: {},
    components: {},
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Oswald&family=Space+Mono:wght@400;700&display=swap');
@font-face {
    font-family: 'uno';
    src: url(../assets/unoestado.ttf);
}

* {
    font-family: 'uno';
}

.button {
    cursor: pointer;
    display: flex;
    flex-flow: row;
    justify-content: center;
    padding: 1rem;
    background: orange;
    margin: 0.5rem;
    margin-top: unset;
}

@media screen and (max-width:100vh) {}
</style>