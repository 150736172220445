import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Globe from '../views/globe.vue'
import Globe2 from '../views/Globejs.vue'
import Stream from '../views/Stream.vue'
import Test from '../views/Test.vue'
import { auth } from '../firebase'

const routes = [
  {
    path: '/home',
    name: 'Home',    
    component: Home,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/test',
    name: 'Test',
    component: Test
  },
  {
    path: '/globe2',
    name: 'Globe2',
    component: Globe2
  },
  {
    path: '/',
    name: 'Stream',
    component: Stream
  },
  {
    path: '/globe',
    name: 'Globe',
    component: Globe
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path === '/login' && auth.currentUser) {
    next('/')
    return;
  }

  if (to.matched.some(record => record.meta.requiresAuth) && !auth.currentUser) {
    next('/')
    return;
  }

  next();
})

export default router
